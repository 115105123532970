export const nodeTypes = {
	Arc: {
		typeName: 'Arc',
		pluralTypeName: 'arcs',
		attributes: ['name', 'type', 'description', 'createdAt', 'updatedAt', 'order', 'storyText'],
	},
	Chapter: {
		typeName: 'Chapter',
		pluralTypeName: 'chapters',
		attributes: ['number', 'name', 'description', 'createdAt', 'updatedAt', 'order', 'storyText'],
	},
	Character: {
		typeName: 'Character',
		pluralTypeName: 'characters',
		attributes: ['name', 'description', 'createdAt', 'updatedAt', 'order', 'storyText'],
	},
	Event: {
		typeName: 'Event',
		pluralTypeName: 'events',
		attributes: ['name', 'description', 'createdAt', 'updatedAt', 'order', 'storyText'],
	},
	Note: {
		typeName: 'Note',
		pluralTypeName: 'notes',
		attributes: ['title', 'contents', 'createdAt', 'updatedAt'],
	},
	Theme: {
		typeName: 'Theme',
		pluralTypeName: 'themes',
		attributes: ['name', 'description', 'createdAt', 'updatedAt', 'order', 'storyText'],
	},
	Beat: {
		typeName: 'Beat',
		pluralTypeName: 'beats',
		attributes: ['title', 'description', 'createdAt', 'updatedAt', 'order', 'parentId'],
	},
	User: {
		typeName: 'User',
		pluralTypeName: 'users',
		attributes: ['name', 'email'],
	},
	Relationship: {
		typeName: 'Relationship',
		pluralTypeName: 'Relationships',
		attributes: ['name', 'description', 'connectionType', 'arcStage', 'order', 'firstBeat'],
	},
	Task: {
		typeName: 'Task',
		pluralTypeName: 'Tasks',
		attributes: ['description', 'completed', 'recommendationId', 'completed','order'],
	},
}