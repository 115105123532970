import React from 'react'
import OutsideClickHandler from '../../utils/OutsideClickHandler'
import { Overlay, ModalContainer, ButtonContainer, AcceptButton, CancelButton } from './styles'
import { throttle } from '../../utils/utils'

const Modal = ({
	nodeName,
	acceptHandler,
	closeHandler,
	isShowed,
	elementType,
	isConnection,
	details,
}) => {
	const bodyHandler = async func => {
		document.body.classList['remove']('overflow')
		await func()
	}

	const closeEvent = () => bodyHandler(closeHandler)
	const acceptEvent = () =>
		new Promise((resolve, reject) => {
			bodyHandler(acceptHandler).then(resolve, reject)
		})

	return (
		<Overlay
			isShow={isShowed}
			data-testid="modal"
		>
			<OutsideClickHandler onOutsideClick={() => isShowed && closeEvent()}>
				<ModalContainer>
					<div className="title">Hey, wait!</div>
					<div className="content">
						Are you sure you want to delete this {elementType}? <br />
						{isConnection ? (
							''
						) : (
							<>
								<b>{nodeName}</b> {details}.
							</>
						)}
					</div>
					<ButtonContainer>
						<CancelButton onClick={closeEvent}>No, Keep it</CancelButton>
						<AcceptButton onClick={() => throttle(acceptEvent)}>Yes, remove it</AcceptButton>
					</ButtonContainer>
				</ModalContainer>
			</OutsideClickHandler>
		</Overlay>
	)
}

export default Modal
