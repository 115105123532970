import React, { useEffect, useCallback, useState, useContext } from 'react'
import debounce from 'lodash/debounce'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useApolloClient } from '@apollo/client'

import { Container, DeleteTaskImg, DragHandler, Quill, StatusImg, TaskDescription } from './styles'
import dragHandleIcon from '../../../images/task-dragger-icon.svg'
import inCompleteIcon from '../../../images/incomplete-task-icon.svg'
import completedIcon from '../../../images/completed-tutorial-icon.svg'
import trashIcon from '../../../images/IconDelete.svg'
import { toastHandler } from '../../../utils/backendHandler'
import { throttle } from '../../../utils/utils'
import makeDraggable from '../makeDraggable'
import { useDeleteTaskMutation, useLazyGetTasksQuery, useUpdateTaskMutation } from '../../../hooks'
import { updateTaskCache } from '../../../utils/apollo'
import useQuillAutoSave from '../../AutoSave/useQuillAutoSave'
import DescriptionField from '../../DescriptionField'

const isJsonString = str => {
	try {
		return !!JSON.parse(str)
	} catch (e) {
		return false
	}
}

const getText = content => {
	let result = ''
	if (isJsonString(content)) {
		result = JSON.parse(content)?.ops[0].insert
	} else {
		result = content
	}
	return (result?.length > 70 ? `${result.slice(0, 70)}...` : result) || ''
}

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: '0',
		padding: '3px 5px',
		fontSize: '9px',
		background: '#55534F',
		position: 'relative',
		top: '15px',
		left: '5px',
	},
}))

function UserTask({
	task,
	newTaskId,
	setNewTaskId,
	isExpanded,
	attributes,
	listeners,
	tasks,
}) {

	const [updateTask] = useUpdateTaskMutation()
	const [deleteTask] = useDeleteTaskMutation()
	const [, { refetch: refetchTasks }] = useLazyGetTasksQuery()
	const [loadedQuill, setLoadedQuill] = useState(false)
	//const [quillPlaceholder, setQuillPlaceholder] = useState('')

	const classes = useStyles()


	const [isEditMode, setIsEditMode] = useState(newTaskId === task.id)

	const client = useApolloClient()



	const handleUpdateTask = useCallback(async (id, description, text,completed) => {
		try {
			await updateTask({
				variables: {
					id,
					input: {
						description,
						completed,
					},
				},
			})
		} catch (error) {
			toastHandler('error', 'error updating the task, try again.')
			refetchTasks()
			
		}
		
	}, [tasks])

	const { quill, quillRef } = useQuillAutoSave(
		{
			modules: {
				toolbar: false,

			},
			placeholder: 'Type your task here',
		},
		handleUpdateTask,
		task.id,
		"description",
		task?.description,
	)



	// reset new task after focus
	useEffect(() => {
		if (newTaskId === task?.id) {
			setNewTaskId('')
		}
	}, [newTaskId, task])

	let quillPlaceholder =''

	if(task?.description){

		try{
			quillPlaceholder = (JSON.parse(task?.description)).ops.reduce((text,op) =>{
				return text + op.insert
				},"")
				
		} catch {
			quillPlaceholder = (task.description)
		}
		
	}

	



	useEffect(() => {
		if(isEditMode){
			setLoadedQuill(true)
		}
		if (quill && isEditMode && isExpanded) {
			
			quill.setSelection(quill.getLength())
		}
	}, [quill,isEditMode,isExpanded])


	const handleDeleteTask = async taskId => {
		try {
			await deleteTask({
				variables: {
					id: taskId,
				},
			})
			await refetchTasks()
		} catch (error) {
			console.log(error)
			toastHandler('error', 'error dismissing the task, try again.')
		}
	}

	const handleCompleteTask = async task => {
		await updateTaskCache(client, {...task, completed: !task.completed})
		await handleUpdateTask(task.id, task.description, task.description,!task.completed)
	}

	return (
		<>
			<Container
					shouldDisplay={isEditMode} 
					isEditor>
				{((loadedQuill)) ? (<Quill
					ref={quillRef}
					id={task.id}
					data-fieldname={"description"}
					data-testid="quill"
					onBlur={() => {
						setIsEditMode(false)
					}}
					/>) : 
					<Quill>
						<div className='ql-editor' >{quillPlaceholder} </div>
					</Quill>
					
					}
				
			</Container>
			<Container shouldDisplay={!isEditMode}>
				<div>
					<DragHandler
						className="hidden"
						src={dragHandleIcon}
						alt="drag handle icon"
						{...attributes}
						{...listeners}
					/>
					<StatusImg
						src={task.completed ? completedIcon : inCompleteIcon}
						alt="recs status img"
						onClick={() => handleCompleteTask(task)}
						isCompleted={task.completed}
					/>
				</div>
				<TaskDescription onClick={() => setIsEditMode(true)}>{quillPlaceholder}</TaskDescription>
				<Tooltip
					title="Delete"
					sx={{ padding: 0, margin: 0 }}
					classes={{ tooltip: classes.tooltip }}
					placement="top-start"
				>
					<DeleteTaskImg
						className="hidden"
						src={trashIcon}
						alt="Trash Icon"
						onClick={() => throttle(() => handleDeleteTask(task.id))}
					/>
				</Tooltip>
			</Container>
		</>
	)
}

export default makeDraggable(UserTask)
