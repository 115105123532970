/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useApolloClient } from '@apollo/client'

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import orderBy from 'lodash/orderBy'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	elementReordered,
	onSaveModal,
	setActiveConnections,
	setDeepModeElement,
	setDivingDeep,
	setLeftToggleTabs,
	setNoElementError,
	setRightToggleTabs,
	setServerError,
	setScrollPosition,
	setChapterViewExpanded,
	setVisualizationExpanded,
} from '@lynit/shared/src/state/actions'
import CollapsiblePanel from '@lynit/shared/src/ui/CollapsiblePanel'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import { autoSaveStateContext } from '@lynit/shared/src/state/autoSaveProvider'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import { createCacheElement, deleteCacheElement } from '@lynit/shared/src/utils/apollo'
import { getNodeIcon, createNodeId, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import { useReorderNodesMutation, useCreateNodeMutation } from '@lynit/shared/src/hooks'
import ListElement from '@lynit/layout/src/ui/ListElement'

import elementBox from '@lynit/shared/src/images/elementBox.svg'
import homeIcon from '../../images/home-icon.svg'

import {
	Tab,
	ElementContainer,
	ElementListContainer,
	CreateButton,
	TabsContainer,
	TabText,
	IconContainer,
	TypeText,
	Header,
	CountPill,
	ToggleElementList,
	CollapseDiv,
} from './styles'
import { clickD3Node, selectD3Node, selectVizSVG } from '@lynit/story-outline/src/ui/viz/vizHelpers'

const ElementList = ({
	setActive,
	types,
	dispatch,
	currentView,
	connectionModal,
	setConnectionModal,
	setIsSetModifiers,
	setStructureTagConnnectionModal,
	structureTagConnnectionModal
}) => {

	const graphData = useContext(graphDataStateContext)

	const {
		state: {
			leftToggleTabs,
			rightToggleTabs,
			activeElement,
			activeConnections,
			deleteNode,
			scrollPosition,
			isChapterViewExpanded
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const {
		currentStoryId: storyId,
		deepModeElement,
		serverError,
		noElementError,
		reorderedPosition,
	} = useContext(beatsDataStateContext)

	const { isSaving } = useContext(autoSaveStateContext)
	const { user, refetchUser } = useContext(userStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const { createLog, isMobile } = useContext(systemStateContext)

	const [expandedElementId, setExpandedElementId] = useState('')
	const [visualization, setvisualization] = useState(false)
	const [currentTab, setCurrentTab] = useState(types[1])
	const [filteringData, setFilteringData] = useState([])
	const [isNodeCreated, setNodeCreated] = useState(false)
	const [newNode, setNewNode] = useState(null)
	const [items, setItems] = useState([])

	const [reorderNodes] = useReorderNodesMutation();
	const [performCreateNodeMutation] = useCreateNodeMutation(currentTab,{ignoreResults:true})
	const client = useApolloClient()
	const history = useHistory()
	const serverErrorInterval = useRef()
	const scrollRef = useRef()
	useEffect(() => {
		if (!!activeElement.id && activeElement.type !== 'Chapter') {
			sharedDispatch(setLeftToggleTabs(true))
			setCurrentTab(activeElement.type)
			setActive(activeElement.type)
			const timeout = setTimeout(() => {
				setExpandedElementId(activeElement.id)
			}, 100)
		}
	}, [activeElement])

	useEffect(() => {
		if (activeConnections?.isActive) {
			setExpandedElementId(activeConnections?.elementId)
			sharedDispatch(setLeftToggleTabs(true))
			setCurrentTab(activeConnections?.elementType)
			setvisualization(true)
		}
	}, [activeConnections])

	useEffect(() => {

		if (!expandedElementId) {
			try{
				selectVizSVG()?.on('click')(true)
			}
			catch {

			}
			
			sharedDispatch(setActiveConnections({ isActive: false, elementId: null, elementType: null }))
		} else if (!deepModeElement && ! isChapterViewExpanded && currentView)  {
			
			
			if(newNode ){
				try{
					setTimeout(()=>{ clickD3Node(newNode.id)},500)
				}
				catch{
					
				}
			} else if (graphData.nodes[expandedElementId] ) {
				try{

					clickD3Node(expandedElementId)
				}
				catch {
					
				}

			}
			
			if(activeConnections.elementId !==expandedElementId ){
				sharedDispatch(setActiveConnections({isActive:true,elementId:expandedElementId, elementType: nodeTypeForId(expandedElementId)}))
			}

		}
	}, [expandedElementId,newNode])

	// useEffect(() => {
	// 	if (createdNode) {
	// 		setNewNode(createdNode?.[`create${currentTab}`]?.id)
	// 	}
	// }, [createdNode])

	useEffect(() => {
		setItems(types)
	}, [])

	useEffect(() => {
		setExpandedElementId('')
	}, [storyId])

	// useEffect(() => {
	// 	if (isNodeCreated) {
	// 		;(async () => {
	// 			const mutationData = []

	// 			filteringData.forEach(node => {
	// 				mutationData.push({
	// 					id: node.id,
	// 					order: String(node.order + 1),
	// 				})
	// 			})

	// 			await reorderNodes({
	// 				variables: {
	// 					nodeType: currentTab,
	// 					newNodeOrder: mutationData,
	// 				},
	// 			})
	// 				.then(res => {
	// 					if (res && serverError) {
	// 						beatsDataDispatch(setServerError(false))
	// 						clearInterval(serverErrorInterval.current)
	// 					}
	// 				})
	// 				.catch(async err => {
	// 					if (err.message.includes('Cannot update')) {
	// 						beatsDataDispatch(setNoElementError(true))
	// 					}
	// 					if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
	// 						beatsDataDispatch(setServerError(true))
	// 					}
	// 				})
	// 			setNodeCreated(false)
	// 		})()
	// 	}
	// }, [isNodeCreated])

	useEffect(() => {
		const currentElements = graphData?.nodesByType?.[currentTab] || []
		const elements = currentElements.map(element => {
			return { ...element, order: +element.order }
		})
		setFilteringData(orderBy(elements, ['order'], ['asc']))
	}, [graphData?.nodesByType, currentTab])

	// display updated position before getting response from backend while reorder elements
	useEffect(() => {
		if (
			reorderedPosition?.type === 'element' &&
			reorderedPosition.source.index !== reorderedPosition.destination.index
		) {
			setFilteringData(
				arrayMove(
					filteringData,
					reorderedPosition.source.index,
					reorderedPosition.destination.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [reorderedPosition])

	const addNodeFunc = async () => {
		const tempObj = {
			id: createNodeId(currentTab),
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: '0',
			__typename: currentTab,
			name: `New ${currentTab}`,
			description: '',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			coreElement: 'false',
			storyText:null,
			firstBeat: '',
		}
		const optimisticResponse = {}
		optimisticResponse[`create${currentTab}`] = tempObj

		// sharedDispatch(
		// 	setActiveConnections({ isActive: true, elementId: tempObj.id, elementType: tempObj.__typename }),
		// )
		setExpandedElementId(tempObj.id)
		setNewNode(tempObj)

		await performCreateNodeMutation({
			node: {
				id: tempObj.id,
				name: tempObj.name,
				description: tempObj.description,
				order: tempObj.order,
			},
			optimisticResponse,
			tempObj

		})
			.then(async res => {
				
			})
			.catch(e => {
				console.error(e)
				setNewNode(null)
				sharedDispatch(setActiveConnections({ isActive: false, elementId: null, elementType: null }))
				setExpandedElementId('')
			})
	}

	const displayNewElement = type => {
		switch (type) {
			case 'Theme':
				return `TS&M`
			default:
				return type
		}
	}

	const setCurrentView = view => {
		const possibleViews = ['summary', 'visualization', 'timeline']
		if (possibleViews.includes(view)) {
			dispatch({ key: 'currentView', value: view })
		}
	}
	useEffect(() => {
		if (!visualization) {
			beatsDataDispatch(setDeepModeElement(deepModeElement))
			setExpandedElementId('')
			sharedDispatch(setActiveConnections({ isActive: false, elementId: null, elementType: null }))
			sharedDispatch(setScrollPosition('elementList', 0))
			scrollRef.current.scrollTop = 0
		}
	}, [currentTab, visualization])

	const [isRendered, setIsRendered] = useState(false)

	useEffect(() => {
		if (!expandedElementId) {
			scrollRef.current.scrollTop = scrollPosition
		}
	}, [expandedElementId])

	const expandVisualization = () => {
		const visualization = document.getElementById('visualization')
		const chapterViewContainer = document.getElementById('chapter-view-container')

		if (visualization && chapterViewContainer) {
			chapterViewContainer.style.height = '30px'
			visualization.style.height = 'calc(100% - 40px)'
			sharedDispatch(setVisualizationExpanded(true))
			sharedDispatch(setChapterViewExpanded(false))
			sharedDispatch(setActiveConnections({ isActive: false, elementId: null, elementType: null }))
		}
	}
	
	return (
		<ElementListContainer id= "elementListContainer" className="tour-step-1">
			<TabsContainer data-public data-dd-privacy="allow" className="demo">
				<Tab className="demo1">
					<ToggleElementList
						className="demo2"
						onClick={e => {
							e.stopPropagation()
							createLog('Home Clicked', `{"location":"Element List"}`, 'Element List', null)
							if (!isSaving) {
								beatsDataDispatch(setDeepModeElement(null))
								setCurrentView('visualization')

								if (isChapterViewExpanded) {
									expandVisualization()
								}
							} else {
								sharedDispatch(onSaveModal(true))
							}
							if(isMobile && (leftToggleTabs || rightToggleTabs)){
								sharedDispatch(setLeftToggleTabs(false))
								sharedDispatch(setRightToggleTabs(false))
								
							}
						}}
						disabled={!( deepModeElement || isChapterViewExpanded || currentView !=="visualization" || (isMobile && (leftToggleTabs || rightToggleTabs )))}
					>
						<img src={homeIcon} alt="home icon" className="111" />
					</ToggleElementList>
				</Tab>

				{items.map((type, e) => (
					<div
						key={type}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setIsRendered(true)
						
							setCurrentTab(type)
							setActive(type)
							setvisualization(false)

							createLog(
								`Element Type Tab Selected`,
								`{"workflowStep":${null},"elementType":"${type}"}`,
								'ElementList',
								null,
							)

							if (items[e] === currentTab) {
								sharedDispatch(setLeftToggleTabs(!leftToggleTabs))
							} else if (!leftToggleTabs) {
								sharedDispatch(setLeftToggleTabs(true))
							}

							createLog(
								!leftToggleTabs ? 'Element List Expanded' : 'Element List Collapsed',
								`{"isDeepMode":${!!deepModeElement}, "button":"Element Type Icon"}`,
								'Element List',
								'',
							)
						}}
					>
						<Tab
							className="mytab arc_tab"
							type={type}
							isActive={items[e] === currentTab}
							color={type}
						>
							<ToolTip
								title={type !== 'Theme' ? `${type}s` : 'Themes, Settings & Motifs'}
								placement="right"
								arrow
							>
								<IconContainer
									isSelected={items[e] === currentTab}
									color={type}
									style={{
										marginTop: ' 15px',
										marginBottom: ' 25px',
									}}
								>
									<img src={getNodeIcon(type)} />
								</IconContainer>
							</ToolTip>
							{items[e] === currentTab ? (
								<TabText isActive={types[e] === currentTab}>
									<span className="chapetername">
										{currentTab !== 'Theme' ? `${currentTab}s` : 'TS&M'}
									</span>
								</TabText>
							) : (
								<></>
							)}
						</Tab>
					</div>
				))}
			</TabsContainer>

			{/* HRE */}
			<ElementContainer
				isData={filteringData?.length !== 0}
				className="draggg"
				leftToggleTabs={leftToggleTabs}
				id="element-container"
				ref={scrollRef}
			>
				<Header data-public data-dd-privacy="allow" className="draggg">
					<div>
						<TypeText>
							{currentTab !== 'Theme' ? `${currentTab}s` : 'Themes, Settings & Motifs'}
						</TypeText>
						<CountPill>{filteringData?.length}</CountPill>
					</div>
					<CreateButton
						data-testid="create-button"
						disabled={serverError || noElementError}
						onClick={async () => {
							await addNodeFunc()
							createLog(
								`Element Creation Attempted`,
								`{"workflowStep":${1},"elementType":"${currentTab}"}`,
								'ElementList',
								'Element Creation',
							)
						}}
					>
						New {displayNewElement(currentTab)}
					</CreateButton>
				</Header>
				{filteringData?.length === 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: '20%',
						}}
					>
						<img style={{ width: '70%' }} src={elementBox} alt="BoxIcon" />
						<p>There are no {currentTab}s to show</p>
					</div>
				) : (
					<div className="depp">
						{expandedElementId || activeConnections.isActive ? (
							<ListElement
								className="wewe"
								setExpandedElementId={setExpandedElementId}
								expandedElementId={expandedElementId}
								key={expandedElementId||activeConnections.elementId}
								id={expandedElementId||activeConnections.elementId}
								name={graphData.nodes[expandedElementId]?.name ?? ''}
								description={graphData.nodes[expandedElementId]?.description ?? ''}
								type={graphData.nodes[expandedElementId]?.__typename ?? currentTab}
								number={graphData.nodes[expandedElementId]?.number ?? 0}
								toastHandler={toastHandler}
								newNode={newNode}
								setNewNode={setNewNode}
								deleteNode={deleteNode}
								active={currentTab}
								filteringData={filteringData}
								setServerError={setServerError}
								serverErrorInterval={serverErrorInterval}
								setNoElementError={setNoElementError}
								setActive={setActive}
								isRendered={isRendered}
								connectionModal={connectionModal}
								setConnectionModal={setConnectionModal}
								structureTagConnnectionModal={structureTagConnnectionModal}
								setStructureTagConnnectionModal={setStructureTagConnnectionModal}
								setIsSetModifiers={setIsSetModifiers}
							/>
						) : (
							<SortableContext
								items={filteringData.map(element => element?.id)}
								strategy={verticalListSortingStrategy}
							>
								{filteringData?.map(element => (
									<ListElement
										className="wewe"
										setExpandedElementId={setExpandedElementId}
										expandedElementId={expandedElementId}
										key={element?.id}
										id={element?.id}
										name={element?.name}
										description={element?.description}
										type={element?.__typename}
										number={element?.number}
										toastHandler={toastHandler}
										newNode={newNode}
										setNewNode={setNewNode}
										deleteNode={deleteNode}
										active={currentTab}
										filteringData={filteringData}
										setServerError={setServerError}
										serverErrorInterval={serverErrorInterval}
										setNoElementError={setNoElementError}
										setActive={setActive}
										isRendered={isRendered}
										scrollRef={scrollRef}
										connectionModal={connectionModal}
										setConnectionModal={setConnectionModal}
										setIsSetModifiers={setIsSetModifiers}
									/>
								))}
							</SortableContext>
						)}
					</div>
				)}
			</ElementContainer>
			<CollapsiblePanel
				toggle={leftToggleTabs}
				handleToggle={setLeftToggleTabs}
				StyledDiv={CollapseDiv}
			/>
		</ElementListContainer>
	)
}

export default React.memo(ElementList)
