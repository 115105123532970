import { useMutation } from '@apollo/client'
import { CREATE_CONNECTION_AND_ELEMENT } from '../data'
import { createCacheConnection, createCacheElement, deleteCacheConnections, deleteCacheElement } from '../utils/apollo'
import { nodeTypeForId } from '../utils/utils'
import { toastHandler } from '../utils/backendHandler'

export const useCreateConnectionAndElementMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const [createConnectionAndElement, { data, loading, error }] = useMutation(CREATE_CONNECTION_AND_ELEMENT, options)

	const performCreateConnectionAndElement = async ({
		client,
		newConnection,
		newElement,
		user,
		optimisticResponse,
		hasOrder=false, 
		broadcast=true
	}) => {

		if (!client) {
			throw new Error('Missing client to Create Connection and Element')
		}

		if (!newConnection) {
			throw new Error('Missing newConnection to Create Connection and Element')
		}

		if (!newElement) {
			throw new Error('Missing newElement to Create Connection and Element')
		}

		if (!user) {
			throw new Error('Missing user to Create Connection and Element')
		}

		if (!optimisticResponse) {
			throw new Error('Missing optimisticResponse to Create Connection and Element')
		}

		try {
			createCacheElement(client, optimisticResponse, hasOrder, broadcast)
			createCacheConnection(client, newConnection, false, true)
			await createConnectionAndElement({
				variables: {
					newRelationship: {
						id: newConnection.id,
						destNodeId: newConnection.destNode.id,
						sourceNodeId: newConnection.sourceNode.id,
						description: newConnection.description,
						connectionType: newConnection.connectionType,
						structureTag: newConnection.structureTag,
						relName: 'RELATED',
						beatsDriven: newConnection.beatsDriven,
						sharedBeats: newConnection.sharedBeats?.map(beat => { return { id: beat.id } }) || [],
						firstBeat: ''
					},
					newElement: {
						id: newElement.id,
						name: newElement?.name,
						storyText: newElement?.storyText,
						description: newElement?.description,
						typename: newElement?.__typename,
						firstBeat: newElement?.firstBeat,
						contents:newElement?.contents
					}
				},
			})
			user?.refetchUser()
		} catch (error) {
			deleteCacheConnections(client, { id: newConnection.id, __typename: "Relationship" }, true)
			deleteCacheElement(client, { id: newConnection.destNode.id, typename: nodeTypeForId(newElement.id) })
			// const connectionCount = getConnectionCount(client)
			// dispatch(
			// 	setConnectionCount({
			// 		supportingCount: connectionCount.Supporting,
			// 		opposingCount: connectionCount.Opposing,
			// 		undefinedCount: connectionCount.Undefined,
			// 		total: connectionCount.Total
			// 	}),
			// )
			if (error.message.includes('subscription')) {
				toastHandler('error', error.message, null, "ConnectionModal", "Connection Creation")

				if (
					user?.user?.subscriptionStatus?.status === 'trialing' ||
					user?.user?.subscriptionStatus?.status === 'active'
				) {
					window.location.reload()
				}
			} else {
				toastHandler('error', 'Error while creating connection. Try again later.', null, "ConnectionModal", "Connection Creation")
			}
		}
	}
	return [performCreateConnectionAndElement, { data, loading, error }]
}
