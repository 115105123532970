import React, { useContext } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HomeIcon from '@mui/icons-material/Home'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { StyledBreadcrumb } from './styles'
import { sharedContext } from '../../state/sharedProvider'
import { setDeepModeElement, setSummaryCardVisible, setSummaryView } from '../../state/actions'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../../state/beatsProvider'
import { systemDispatchContext, systemStateContext } from '../../state/systemProvider'

function BreadCrumb() {
	const {
		state: { currentStory, flow: currentFlow },
		dispatch,
	} = useContext(sharedContext)

	const beatsRelatedData = useContext(beatsDataStateContext)

	const { isSummaryCardVisible } = useContext(systemStateContext)
	const systemDataDispatch = useContext(systemDispatchContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)

	const graphData = useContext(graphDataStateContext)

	const isSmallDevice = useMediaQuery('(max-width: 1024px)')
	const history = useHistory()

	const ellipsisText = text => {
		return isSmallDevice ? `${text.slice(0, 10)}...` : text
	}
	return (
		<Breadcrumbs aria-label="breadcrumb">
			<StyledBreadcrumb
				onClick={() => {
					if (currentFlow === 'My Stories') {
						history.push('/dashboard')
					}
				}}
			>
				<HomeIcon
					sx={{ mr: 0.5, mb: 0.3, fill: '#FFC300', width: '20px', height: '22px' }}
					fontSize="inherit"
				/>
				{!isSmallDevice && currentFlow}
			</StyledBreadcrumb>
			{currentStory && (
				<StyledBreadcrumb
					disabled={!isSummaryCardVisible}
					onClick={() => {
						beatsDataDispatch(setDeepModeElement(null))
						if (isSummaryCardVisible === false && ! beatsRelatedData?.currentElement ) {
							systemDataDispatch(setSummaryCardVisible("Premise"))
						} 
						else{
							systemDataDispatch(setSummaryCardVisible(false))
						}
					}}
				>
					{ellipsisText(currentStory.name || 'Unnamed Story')}
				</StyledBreadcrumb>
			)}
			{graphData?.nodes?.[beatsRelatedData?.currentElement]?.name && (
				<StyledBreadcrumb isActive>
					{ellipsisText(graphData?.nodes?.[beatsRelatedData?.currentElement]?.name)}
				</StyledBreadcrumb>
			)}
		</Breadcrumbs>
	)
}

export default BreadCrumb
