import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react'
import { useCreateEventlogMutation, useGetUserQuery } from '../hooks'
import LogRocket from 'logrocket'
import { useContext } from 'react'
import { sharedContext } from '.'
import { sharedReducer } from './sharedReducer'
import {
	isFirefox,
	isEdge,
	isChrome,
	isSafari,
	isMobile,
	isAndroid,
	isIOS,
	isWinPhone
} from 'react-device-detect'
import axios from 'axios'
import {initializeLogRocket} from '../utils/logRocketSetup'
import { toast } from 'react-toastify'
import { setIsMobile } from './actions'
import { useMediaQuery } from '@mui/material'



export const systemStateContext = createContext()
export const systemDispatchContext = createContext()

const systemRelatedState = {
    isLoggedIn: !!localStorage.getItem('token'),
    wasOffline: false,
	isOnline: window.navigator.onLine,
    isDifferentVersion: false,
    isNewUser: false,
    isUserSubscribed: false,
	isMobile,
	isSummaryCardVisible: isMobile? false: "Premise"
}

export const systemRelatedData = payload => {
	return {
		type: 'SYSTEM_DATA',
		payload,
	}
}

export const systemRelatedReducer = (state, action) => {
	switch (action.type) {
		case 'SYSTEM_DATA':
			return action.payload 
		default:
			return state
	}
}

export const SystemProvider = ({ children }) => {
	const [state, dispatch] = useReducer(sharedReducer, systemRelatedState)
    const [browser, setBrowser] = useState('')
	const [device, setDevice] = useState('')
	const [ipAddress, setIpAddress] = useState('')
    const story = useContext(sharedContext)
	const { data: userData } = useGetUserQuery({ fetchPolicy: 'cache-first' })

	const [createEventLog] = useCreateEventlogMutation({
		ignoreResults: true,
		fetchPolicy: 'no-cache'
	})

	let logrocketSessionURL
	LogRocket.getSessionURL(sessionURL => {
		logrocketSessionURL = sessionURL
	})
	
	const isMobileUpdated = useMediaQuery('(max-width: 600px)')
	useEffect(()=>{
		dispatch(setIsMobile(isMobileUpdated))

	},[isMobileUpdated])
	useEffect(async () => {
		try{
			initializeLogRocket()
		}
		catch(e){
			createLog(
				`Initialize Screen Recording Failed`,
				`{"errorMessage":"${e.message}"}`,
				``,
				``,
			)
		}
		
		let res
		try{
			res = await axios.get('https://geolocation-db.com/json/')
			setIpAddress(res.data.IPv4)

		} catch {
			res = await axios.get('https://geolocation-db.com/json/')
			setIpAddress(res.data.IPv4)
		}
		
	},[])

	useEffect(() => {
		//reactour isFirstLogin logic to decide if render tour or not
		if (isFirefox) {
			setBrowser('Firefox')
		}
		if (isEdge) {
			setBrowser('Edge')
		}
		if (isChrome) {
			setBrowser('Chrome')
		}
		if (isSafari) {
			setBrowser('Safari')
		}
		if (isMobile) {
			
			setDevice('Mobile')
		}
		if (isAndroid) {
			setDevice('Android')
		}
		if (isIOS) {
			setDevice('IOS')
		}
		if (isWinPhone) {
			setDevice('WindowsPhone')
		} else {
			setDevice('Computer')
		}
	}, [userData?.user?.isFirstLogin, window.innerWidth])

    const createLog = useCallback(
		async (action, actionDetail, component, workflow) => {
			const details = JSON.parse(actionDetail)
			details['logrocketSessionURL'] = logrocketSessionURL
			details['app'] = 'lynit_frontend'
			details['isDeepMode'] = !!state.deepModeElement
			details['ip_address'] = ipAddress
			createEventLog({
				variables: {
					input: {
						name: `${action}`,
						details: JSON.stringify(details),
						page: '/',
						component: `${component}`,
						workflow: `${workflow}`,
						browser,
						geo: '',
						device,
						createdAt: Date.now(),
						app_version: localStorage.version
					}
				},
				context: {
					headers: {
						ipAddress:ipAddress
					}
				}
			})
		},
		[localStorage.version,ipAddress]
	)




const toastHandler = (state, message, content, component, workflow) => {
	//Error handling
	if (typeof state !== 'string') {
		throw new Error(
			`TypeError: Expected a string as a value for the "state" parameter, instead got ${typeof state}`
		)
	}
	// Cases handling for different states of "state" parameter
	if (state === 'success') {
		toast.success(message)
	} else if (state === 'warning') {
		toast.warning(message)
	} else if (state === 'error') {
		toast.error(message)
	} else if (state === 'dark') {
		toast.dark(content, {
			onClose: props => props.close(),
			position: 'bottom-right',
			autoClose: props => props.autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else if (state === 'deepDark') {
		toast.dark(content, {
			position: 'top-left',
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else if (state === 'dark1') {
		toast.dark(content, {
			onClose: props => props.close(),
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			position: 'bottom-center'
		})
	} else if (state === 'dark2') {
		toast.dark(content, {
			onClose: props => props.close(),
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else {
		throw new Error(
			'InputError: Expected either "success", "warning" or "error" as values for the "state" parameter'
		)
	}

	createLog(
		`ToastHandler Error Message`,
		`{"errorMessage":"${message}"}`,
		`${component}`,
		`${workflow}`,
	)
}


	return (
		<systemStateContext.Provider value={{...state, createLog,ipAddress ,toastHandler}}>
			<systemDispatchContext.Provider value={dispatch}>
				{children}
			</systemDispatchContext.Provider>
		</systemStateContext.Provider>
	)
}

export default SystemProvider