import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDndContext, DragOverlay } from '@dnd-kit/core'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import BeatCard from '@lynit/shared/src/ui/BeatCard'
import ChapterCard from '@lynit/story-outline/src/ui/ChapterView/ChapterCard'
import ListElement from '@lynit/layout/src/ui/ListElement'

const DragOverlayComponent = ({ relationships, isDeepMode, response , isSetModifiers}) => {

	const graphData = useContext(graphDataStateContext)
	const { user } = useContext(userStateContext)

	const [draggingItem, setDraggingItem] = useState({})

	const dndContext = useDndContext()
	const { element } = useParams()

	useEffect(() => {
		if (dndContext.active?.id) {
			let item = {}
			if (dndContext.active?.id?.includes('rel')) {
				item = relationships?.relations.find(relation => relation.id === dndContext.active?.id)
			} else {
				item = graphData.nodes[dndContext.active?.id]
			}
			setDraggingItem(item||{})
		} else {
			setDraggingItem({})
		}
	}, [dndContext.active?.id])

	const isNotDroppable = dndContext?.over?.id?.includes("view-container") || dndContext?.over?.id?.includes("document-container")
	
	return (
		<DragOverlay dropAnimation={dndContext?.active?.data?.current?.location ==="Chapter Card"? 200: null}>
			{draggingItem?.id && Object.keys(draggingItem).length && !isSetModifiers? (
				draggingItem.id.includes('chp') && !isDeepMode ? (
					<ChapterCard
						id={draggingItem.id}
						name={draggingItem.name}
						description={draggingItem.description}
						type={draggingItem.__typename}
						number={draggingItem.number}
						isOverlay={true}
						cursor={isNotDroppable ? "not-allowed" : "grabbing"}
						response={response}
					/>
				) : draggingItem.id.includes('rel') ? (
					<BeatCard
						beatId={
							draggingItem.sourceNode?.id?.startsWith('bea')
								? draggingItem.sourceNode?.id
								: draggingItem.destNode?.id
						}
						referenceElementType={
							draggingItem.sourceNode?.id?.startsWith('bea')
								? draggingItem.destNode?.__typename
								: draggingItem.sourceNode?.__typename
						}
						isOverlay={true}
						cursor={(dndContext?.over?.id?.includes("view-container") || (dndContext?.over?.id?.includes("document-container") && !element?.startsWith("chp"))) ? "not-allowed" : "grabbing"}
					/>
				) : (
					<ListElement
						user={user}
						id={draggingItem.id}
						name={draggingItem.name}
						description={draggingItem?.description}
						type={draggingItem.__typename}
						isOverlay={true}
					/>
				)
			) : null}
		</DragOverlay>
	)
}

export default DragOverlayComponent
