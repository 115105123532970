import { useMutation } from '@apollo/client'
import { DELETE_CONNECTION } from '../data'
import { createCacheConnection, deleteCacheConnections, updateCacheOrderBeat } from '../utils/apollo';
import { toastHandler } from '../utils/backendHandler';
import { userStateContext } from '../state/userProvider';
import { useContext } from 'react';
import { systemStateContext } from '../state/systemProvider';

export const useDeleteConnectionMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const [deleteConnection,{ data, loading, error }] = useMutation(DELETE_CONNECTION, options);
	const { user, refetchUser } = useContext(userStateContext) || {};
	const systemRelatedData = useContext(systemStateContext)
	
	const performDeleteConnectionMutation = async (
		{
			id,
			client,
			elementToDelete,
			mutationData,
			isCreateCacheConnection
		}
	) => {

		if (!client) {
			throw new Error('Missing client for Delete Connection')
		}

		if (!id) {
			throw new Error('Missing Id for Delete Connection')
		}

		if (!elementToDelete) {
			throw new Error('Missing elementToDelete for Delete Connection')
		}

		if (mutationData?.length) {
			updateCacheOrderBeat(client, mutationData)
		}

		try {

			await deleteConnection({
				variables: {
					id,
				},
			})

			deleteCacheConnections(client, elementToDelete).then(() =>
				setTimeout(() => {
					if (refetchUser)
						refetchUser()
				}, 0),
			)

		} catch (e) {

			if (isCreateCacheConnection)
				createCacheConnection(client, elementToDelete)

			if (e.message.includes('subscription')) {
				toastHandler('error', e.message)
				systemRelatedData?.createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${e.message}"}`,
					'',
					'Remove Connection',
				)
				if (
					user?.subscriptionStatus?.status === 'trialing' ||
					user?.subscriptionStatus?.status === 'active'
				) {
					window.location.reload()
				}
			} else {
				toastHandler('error', 'Error while deleting connection. Try again.')
				systemRelatedData?.createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${e.message}"}`,
					'',
					'Remove Connection',
				)
			}
			throw e
		}
	}
	return [performDeleteConnectionMutation,{ data, loading, error }]

}
