import React, { createContext, useReducer } from 'react'

export const graphDataStateContext = createContext()
export const graphDataDispatchContext = createContext()

const initialGraphData = {
	nodes: {},
	nodesByType: {},
	loading: false,
	error: null,
	allNodes: {
		nodes: [],
	},
}

export const setGraphData = payload => {
	return {
		type: 'SET_GRAPH_DATA',
		payload,
	}
}

export const resetGraphData = () => {
	return {
		type: 'RESET_GRAPH_DATA',
	}
}

export const graphDataReducer = (state, action) => {
	switch (action.type) {
		case 'SET_GRAPH_DATA':
			return action.payload 
		case 'RESET_GRAPH_DATA':
			return initialGraphData
		default:
			return state
	}
}

export const GraphDataProvider = ({ children }) => {
	const [state, dispatch] = useReducer(graphDataReducer, initialGraphData)

	return (
		<graphDataStateContext.Provider value={state}>
			<graphDataDispatchContext.Provider value={dispatch}>
				{children}
			</graphDataDispatchContext.Provider>
		</graphDataStateContext.Provider>
	)
}

export default GraphDataProvider
