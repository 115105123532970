/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import { useApolloClient } from '@apollo/client'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import {
	useDraggable,
	useReorderBeatsMutation,
	useReorderNodesMutation,
} from '@lynit/shared/src/hooks'
import {
	setCurrentElement,
	setDeepModeElement,
	setDivingDeep,
	setLeftToggleTabs,
	setNoElementError,
	setOpenToolTip,
	setRightToggleTabs,
	setServerError,
} from '@lynit/shared/src/state/actions'

import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import ChapterBeatsContainer from '@lynit/shared/src/ui/ChapterBeatsContainer'
import TextField from '@lynit/shared/src/ui/Textfield'
import dragger from '@lynit/shared/src/images/dragger.svg'
import trashIcon from '@lynit/shared/src/images/IconDelete.svg'
import documentIconDark from '@lynit/shared/src/images/documentIconDark.svg'

import {
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheBeatsDriven,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'

import { TextFieldsContainer, Element, Card, ChapterDescription } from './styles'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: '0 5px',
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
}))

const ChapterCard = React.memo(
	({
		id,
		name,
		description,
		type,
		number,
		isRightPanel,
		attributes,
		listeners,
		beatConnections,
		isOverlay,
		cursor,
		response
	}) => {
		const client = useApolloClient()
		const history = useHistory()

		const [elementName, setElementName] = useState(name)
		const [elementNumber, setElementNumber] = useState(number)
		//const [elementDescription, setElementDescription] = useState(description)
		const [isEdit, setEdit] = useState(false)
		const [currentNode, setCurrentNode] = useState(null)

		const [reorderNodes] = useReorderNodesMutation()
		const [reorderBeats] = useReorderBeatsMutation()

		const {
			state: {
				deleteNode,
			},
			dispatch,
		} = useContext(sharedContext)

		const beatsRelatedData = useContext(beatsDataStateContext)
		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const systemRelatedData = useContext(systemStateContext)
		const user = useContext(userStateContext)
		const graphData = useContext(graphDataStateContext)

		const classes = useStyles({ isTooltip: beatsRelatedData?.deepModeElement?.id === id })

		const deleteBeatConnectionFromCache = async beatConnection => {
			await deleteCacheConnections(client, beatConnection)

			const connectedChapterId = beatConnection.destNode?.id
			const connectedChapter = graphData.nodes[connectedChapterId]

			const mutationData = []

			connectedChapter?.beatConnections?.forEach(connection => {
				if (+connection.order > +beatConnection.order) {
					mutationData.push({
						id: connection.id,
						order: String(+connection.order - 1),
					})
				}
			})

			if (mutationData.length) {
				await updateCacheOrderBeat(client, mutationData)
			}
		}

		const reorderBeatsAfterDelete = async deletedBeatId => {
			const beat = graphData.nodes[deletedBeatId]
			

			const mutationData = []
			

			//remove childConnection from element(Arc/Character)
			
			//remove beatConnection from Chapter and update order of beats in Chapter
			if (beat?.beatConnections.length) {
				deleteBeatConnectionFromCache(beat?.beatConnections[0])
			}

			beat.driverConnections.map((connToDelete)=> {
				const driverId = connToDelete.sourceNode.id === beat.id ? connToDelete.destNode.id : connToDelete.sourceNode.id
				const driver = graphData.nodes[driverId]
				driver.driverConnections.forEach(connection => {
					if (+connection?.order > +connToDelete?.order) {
						mutationData.push({
							id: connection.id,
							order: String(+connection.order - 1),
						})
					}
					
				})
				deleteCacheConnections(client, connToDelete)
				

			}
			)

			if (mutationData.length) {
				await reorderBeats({
					variables: {
						
						newNodeOrder: mutationData,
					
					},
				}).catch(async error => {
					console.log(error)
				})
				updateCacheOrderBeat(client, mutationData)
			}
		}

		const removeElement = async id => {
			try {
				await deleteNode({
					variables: {
						id,
					},
				})
				await deleteCacheElement(client, currentNode, true).then(() => {
					setTimeout(() => user?.refetchUser(), 0)
				})
				const mutationData = []
				const deletedNode = graphData.nodes[id]
				const descreaseBeatsDriven = []
				if (deletedNode.__typename === 'Beat') {
					reorderBeatsAfterDelete(id)
					const beatDrivers = deletedNode.driverConnections?.map(conn =>{ 
						const id = conn.sourceNode.__typename === 'Beat' ? conn.destNode.id : conn.sourceNode.id
						
						return {id}
					}
					)
					const setUniqueStaticConnections = new Set()
			
					beatDrivers.map( (driver_A) => {
				
						beatDrivers.map((driver_B) => {
							const driverPair = [driver_A.id,driver_B.id].sort().toString()
							if (setUniqueStaticConnections.has(driverPair) || driver_A.id === driver_B.id ) {
								return
							}
							setUniqueStaticConnections.add(driverPair)
							const existStaticConnection = graphData.nodes[driver_A.id]?.staticConnections.find(conn => {
								return [conn.sourceNode.id,conn.destNode.id].includes(driver_B.id)
							})
							if(existStaticConnection){
								descreaseBeatsDriven.push(existStaticConnection.id)
							} 

				})
			})
					if (descreaseBeatsDriven?.length) {
						await updateCacheBeatsDriven(client, descreaseBeatsDriven, true, deletedNode)
					}
				} else if (deletedNode.__typename === 'Chapter') {
					// remove beat connections of the chapter
					deletedNode?.beatConnections.forEach(beatConnection => {
						deleteCacheConnections(client, beatConnection)
					})
					const chapters =
						graphData.allNodes?.nodes?.filter(node => node.__typename === 'Chapter') || []
					chapters.forEach(chapter => {
						if (+chapter.order > +deletedNode.order) {
							mutationData.push({
								id: chapter?.id,
								order: String(+chapter.order - 1),
							})
						}
					})
					if (mutationData.length) {
						const res = await reorderNodes({
							variables: {
								nodeType: 'Chapter',
								newNodeOrder: mutationData,
							},
						})
						if (res && beatsRelatedData?.serverError) {
							beatsDataDispatch(setServerError(false))
						}
					}

					if (beatsRelatedData?.deepModeElement) {
						beatsDataDispatch(setDeepModeElement(null))
						//history.push(`/story/${beatsRelatedData?.currentStoryId}`)
					}
				}
			} catch (err) {
				console.error(err)
				if (err.message.includes('subscription')) {
					toastHandler('error', err.message)
					systemRelatedData?.createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${err.message}"}`,
						'ChapterCard',
						'Element Deletion',
					)
					if (
						user?.user?.subscriptionStatus?.status === 'trialing' ||
						user?.user?.subscriptionStatus?.status === 'active'
					) {
						window.location.reload()
					}
				} else if (err.message.includes('Cannot update')) {
					beatsDataDispatch(setNoElementError(true))
				} else if (
					err.message.includes('Failed to fetch') ||
					err.message.includes('NetworkError')
				) {
					beatsDataDispatch(setServerError(true))
				} else {
					toastHandler('error', `${beatsRelatedData?.currentElement?.name} could not be deleted.`)
					systemRelatedData?.createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${err.message}"}`,
						'ChapterCard',
						'Element Deletion',
					)
				}
			}
		}

		return (
			<>
				{!beatsRelatedData?.deepModeElement && (
					<Element
						id={`${id}-chapter-header`}
						isRightPanel={isRightPanel}
						style={{ cursor: isOverlay ? cursor : 'default' }}
					>
						{!isRightPanel && (
							<img
								src={dragger}
								style={{ marginRight: '5px', cursor: isOverlay ? 'grabbing' : 'grab' }}
								alt="Dragger Icon"
								width={7}
								height={12}
								{...attributes}
								{...listeners}
							/>
						)}
						<div id={`${id}-chapter-name`} className="chapter-name">
							<TextFieldsContainer hoverStyle={beatsRelatedData?.deepModeElement?.id !== id}>
								<p onClick={()=>{
										document.getElementById(`${id}-number`)?.firstChild?.click();
									}}>Ch:</p>
								<div 
									id={`${id}-number`}
									className="chapter-number-container"
									>
									<TextField
										id={`${id}`}
										size={'1.5rem'}
										type={type ?? 'Chapter'}
										content={number}
										elementContent={elementNumber}
										setContent={setElementNumber}
										placeholder={'0'}
										isNumber={true}
										isCollapsed={false}
										createLog={systemRelatedData?.createLog}
									/>
								</div>
								<div className="chapter-name-container">
									<TextField
										id={id}
										size={'90%'}
										type={type ?? 'Chapter'}
										content={name}
										elementContent={elementName}
										setContent={setElementName}
										placeholder={`New ${type}`}
										isNumber={false}
										isCollapsed={false}
										createLog={systemRelatedData?.createLog}
									/>
								</div>
							</TextFieldsContainer>
						</div>
						<div
							disabled={beatsRelatedData?.serverError || beatsRelatedData?.noElementError}
							onClick={() => {
								if (beatsRelatedData?.deepModeElement?.id !== id) {
									if (beatsRelatedData?.deepModeElement) {
										beatsDataDispatch(setDeepModeElement(null))
										dispatch(setCurrentElement(''))
									}
									if (!beatsRelatedData?.deepModeElement && type === 'Chapter') {
										dispatch(setLeftToggleTabs(false))
									}
									if (!systemRelatedData?.isMobile) {
										dispatch(setRightToggleTabs(true))
									}
									beatsDataDispatch(setDeepModeElement({ id, nodeType: type }))
									dispatch(setCurrentElement(id))
									dispatch(setOpenToolTip(false))
									systemRelatedData?.createLog(
										'Dive Deep Entered',
										`{"elementType":"${type}", "action":"Name Clicked"}`,
										'Chapter View',
										null,
									)
									//history.push(`/story/${beatsRelatedData?.currentStoryId}/${id}`)
								}
							}}
						>
							<Tooltip title="Open Chapter" classes={{ tooltip: classes.tooltip }} placement="left">
								<img
									src={documentIconDark}
									alt="document icon"
									className="document-img"
									width={16}
									height={16}
								/>
							</Tooltip>
						</div>
					</Element>
				)}

				<ChapterDescription>
					<DescriptionField
						id={id}
						fieldName={"description"}
						type={type ?? 'Chapter'}
						isEdit={isEdit}
						setEdit={setEdit}
						createLog={systemRelatedData?.createLog}
						description={description ?? ''}
						elementDescription={description ?? ''}
						isArcConnection={false}
						isElement={true}
						placeholder={`Describe your ${type} here`}
					/>
					<div className="delete-icon">
						<img
							src={trashIcon}
							alt="Trash Icon"
							disabled={beatsRelatedData?.serverError || beatsRelatedData?.noElementError}
							onClick={() => {
								setCurrentNode({ name, id, type })
							}}
						/>
					</div>
				</ChapterDescription>
				<ChapterBeatsContainer
					id={id}
					type={type}
					beatConnections={beatConnections}
					setCurrentNode={setCurrentNode}
					response={response}
				/>
				{!!currentNode && (
					<DeleteModal
						elementType={currentNode?.type?.toLowerCase()}
						nodeName={`${currentNode?.name} and its connections`}
						isShowed={currentNode}
						details="will be removed too"
						isConnection={currentNode?.type !== 'Chapter'}
						acceptHandler={() => {
							if (currentNode?.type === 'Chapter' || currentNode?.type === 'Beat') {
								removeElement(currentNode?.id)
							} else if (currentNode?.type === 'Relationship') {
								beatsRelatedData?.removeBeatConnection(currentNode.beatConnection, currentNode.referenceElement)
							}
							setCurrentNode('')
							systemRelatedData?.createLog(
								`Element Deletion Confirmed`,
								`{"workflowStep":${2},"elementType":"${currentNode?.type}"}`,
								'ElementList',
								'Element Deletion',
							)
						}}
						closeHandler={() => {
							systemRelatedData?.createLog(
								'Element Deletion Cancelled',
								`{"workflowStep":${2},"elementType":"${currentNode?.type}"}`,
								'ElementList',
								'Element Deletion',
							)
							setCurrentNode('')
						}}
					/>
				)}
			</>
		)
	},
)

const Draggable = props => {
	const { attributes, isDragging, listeners, setNodeRef, transition, transform, active, over } =
		useSortable({
			id: props?.id,
			data: {
				type: 'chapter',
				id: props?.id,
			},
		})

	const graphData = useContext(graphDataStateContext)

	const connections = graphData.nodes[props?.id]?.beatConnections.map(connection => {
		return { ...connection, order: +connection.order }
	})

	const orderedConnections = orderBy(connections, ['order'], ['asc'])
	const beatConnections = useDraggable(props?.id, active, over, orderedConnections)

	return (
		<Card
			isRightPanel={props.isRightPanel}
			id={`${props?.id}-chapter-card`}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
				opacity: isDragging ? '0.5' : 'unset',
			}}
		>
			<ChapterCard
				{...props}
				attributes={attributes}
				listeners={listeners}
				beatConnections={beatConnections}
				response={props?.response}
			/>
		</Card>
	)
}

export default React.memo(Draggable)
