import { useMediaQuery } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { sharedContext } from '../state/sharedProvider'
import { v4 as uuid } from 'uuid'

import CharIcon from '../images/IconCharacter.svg'
import ChapIcon from '../images/IconChapter.svg'
import ArcIcon from '../images/IconArc.svg'
import EventIcon from '../images/IconEvent.svg'
import ThemeIcon from '../images/IconMotif.svg'
import NoteIcon from '../images/notesIcon.svg'
import DriverArcIcon from '../images/driver-arc-icon.svg'
import DriverThemeIcon from '../images/driver-theme-icon.svg'
import DriverEventIcon from '../images/driver-event-icon.svg'
import DriverCharacterIcon from '../images/driver-character-icon.svg'
import supportingIcon from '../images/supporting-coonection.svg'
import oppossingIcon from '../images/opposing-connection.svg'
import ArtistIcon from '../images/artist-icon.svg'

let flag = true
export const throttle = async callback => {
	if (flag) {
		try {
			flag = false
			await callback()
		} catch (error) {
			console.error(error)
		}
		flag = true
	}
}

export const userNameLengthFormat = name =>
	name
		? name?.length > 20
			? window.innerWidth <= 500
				? `${name.slice(0, 10)}...`
				: `${name.slice(0, 20)}...`
			: name
		: name

export const sortNodesByName = (a, b) => {
	if (a.name < b.name) {
		return -1
	}
	if (a.name > b.name) {
		return 1
	}
	return 0
}

export const sortNodesByNumber = (a, b) => {
	if (Number(a.number) < Number(b.number)) {
		return -1
	}
	if (Number(a.number) > Number(b.number)) {
		return 1
	}
	return 0
}

export const sortNodesByCreatedAt = (a, b) => {
	const a_createdAt = Date.parse(a.createdAt)
	const b_createdAt = Date.parse(b.createdAt)
	if (a_createdAt < b_createdAt) {
		return 1
	}
	if (a_createdAt > b_createdAt) {
		return -1
	}
	return 0
}

// check if Image url ends with below extensions or not
export const imgRegEx = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i

export const useStoryOutlineWidth = () => {
	const isMobile = useMediaQuery('(max-width: 600px)')
	const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')
	const isLaptop = useMediaQuery('(min-width: 769px) and (max-width: 1199px)')

	const {
		state: { leftToggleTabs, rightToggleTabs },
	} = useContext(sharedContext)

	const [isWider, setIsWider] = useState(true)

	useEffect(() => {
		const isNarrow =
			isMobile ||
			(isTablet && (leftToggleTabs || rightToggleTabs)) ||
			(isLaptop && leftToggleTabs && rightToggleTabs)
		setIsWider(!isNarrow)
	}, [isMobile, isTablet, isLaptop, leftToggleTabs, rightToggleTabs])

	return { isNarrow: !isWider }
}

export const getNodeIcon = nodeType => {
	switch (nodeType) {
		case 'Chapter':
			return ChapIcon

		case 'Character':
			return CharIcon

		case 'Event':
			return EventIcon

		case 'Arc':
			return ArcIcon

		case 'Theme':
			return ThemeIcon

		case 'TS&M*':
			return ThemeIcon

		case 'Note':
			return NoteIcon

		case 'Supporting':
			return supportingIcon

		case 'Opposing':
			return oppossingIcon

		case 'Driver-Character':
			return DriverCharacterIcon

		case 'Driver-Arc':
			return DriverArcIcon

		case 'Driver-Theme':
			return DriverThemeIcon

		case 'Driver-Event':
			return DriverEventIcon

		case 'Driver':
			return DriverEventIcon
			
		case 'Artist':
			return ArtistIcon

		default:
			return 'Icon'
	}
}

export const createNodeId = nodeType => {
	return `${nodeTypeAbbrevs[nodeType]}-${uuid()}`
}

export const nodeTypeAbbrevs = {
	Arc: 'arc',
	Chapter: 'chp',
	Character: 'chr',
	Note: 'nte',
	Event: 'evt',
	Theme: 'thm',
	Story: 'sty',
	User: 'usr',
	Relationship: 'rel',
	Beat: 'bea',
	Task: 'tsk',
}

export const nodeTypeForId = nodeId => {
	const typeEntries = Object.entries(nodeTypeAbbrevs)
	for (let i = 0; i < typeEntries.length; i += 1) {
		const [nodeType, abbrev] = typeEntries[i]
		if (nodeId && nodeId.startsWith(abbrev)) {
			return nodeType
		}
	}
	throw new Error(`Unknown ID format: ${nodeId}`)
}

export const jsonParseWithTest = str => {
	try {
		return JSON.parse(str)
	} catch (e) {
		return {}
	}
}
// New

export const arcStages = [
	'Exposition',
	'Inciting Incident',
	'Rising Action',
	'Climax',
	'Falling Action',
	'Resolution',
]

export const isArcComplete = arc => {
	const stages = arc?.connections?.map(c => c?.arcStage)
	const isSubset = arcStages?.every(val => stages?.indexOf(val) >= 0)
	return arc?.__typename === 'Arc' ? isSubset : true
}

export const titleLengthFormat = (title, type, amount) => {
	if (type === 'Chapter') {
		return title
			? title?.length > 15
				? `${title.slice(0, amount || 15)}...`
				: title
			: `New ${type}`
	}
	return title ? (title?.length > 25 ? `${title.slice(0, amount || 24)}...` : title) : `New ${type}`
}

export const storyTitleLengthFormat = title => {
	return title ? (title?.length > 25 ? `${title.slice(0, 24)}...` : title) : `...`
}

export const descriptionLengthFormat = description =>
	description
		? description?.length > 55
			? window.innerWidth <= 500
				? `${description.slice(0, 35)}...`
				: `${description.slice(0, 55)}...`
			: description
		: 'No Description'

export const vizTitleLengthFormat = (title,length=15) =>
	title ? (title?.length > length ? `${title.slice(0, length)}...` : title) : 'Untitled'

export const placeHolderInput = (type, input) => {
	switch (input) {
		case 'number':
			return `${type} number*`
		case 'name':
			return `${input.charAt(0)?.toUpperCase() + input.slice(1)} of your ${
				type.charAt(0)?.toUpperCase() + type.slice(1)
			}.`
		case 'description':
			return `You can add the description or details of the ${
				type.charAt(0)?.toUpperCase() + type.slice(1)
			} here.`
		default:
			return 'Input Placeholder Info'
	}
}

export const trimName = (length, name) => {
	// eslint-disable-next-line no-unused-expressions
	if (name?.length > length) {
		return `${name.slice(0, length)}...`
	}
	if (name?.length <= length) {
		return name
	}
}

// check if Video url ends with below extensions or not
export const videoRegEx = /\.(mp4|webm|ogg|mov)$/i


export const Fonts = [
	'Arial',
	
	//'Gill-Sans',
	
	//'Helvetica-Narrow',
	//'sans-serif',
	'Times',
	//'Times-New-Roman',
	'Palatino',
	//'Bookman',
	//'New-Century-Schoolbook',
	//'serif',
	//'Andale-Mono',
	//'Courier-New',
	'Courier',
	//'Lucidatypewriter',
	//'Fixed',
	'monospace',
	//'Comic-Sans',
	//'Zapf-Chancery',
	//'Coronetscript',
	//'Florence',
	//'Parkavenue',
	'cursive',
	'Impact',
	//'Arnoldboecklin',
	//'Oldtown',
	//'Blippo',
	//'Brushstroke',
	//'fantasy',
]

export const Sizes = [8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 32, 42, 54, 68, 84, 98]


export const isJsonString = str => {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export const setsAreEqual = (xs, ys) =>
	xs.size === ys.size &&
	[...xs].every((x) => ys.has(x));

export const structureTagMatcher = (firstElementType,secondElementType,isSupporting) => {

			
	if(firstElementType === "Arc" && secondElementType ==="Character" || firstElementType === "Character" && secondElementType ==="Arc" ) {

		return isSupporting ? "Protagonist" : "Antagonist"
	}

	else if(firstElementType === "Character" && secondElementType ==="Character" ) {

		return isSupporting ? "Ally" : "Opponent"
	}

	else {

		return isSupporting ? "Supporting" : "Opposing"
	}



}
