import {
	DELETE_STORY,
	IS_EXPORTING,
	IS_SAVING,
	LOGIN,
	LOGOUT,
	OPEN_RECOMMENDATION,
	OPEN_TUTORIAL,
	RECOMMENDATION_OPENED,
	SET_BEATS_IN_CHAPTERS,
	SET_CONNECTION_DATA,
	SET_CURRENT_ELEMENT,
	SET_CURRENT_FLOW,
	SET_CURRENT_RECOMMENDATION,
	SET_CURRENT_STORY,
	SET_CURRENT_STORY_ID,
	SET_DEEP_MODE_ELEMENT,
	SET_DIFFERENT_VERSION,
	SET_GRAPH_DATA,
	SET_IS_ONLINE,
	SET_IS_TOUR_OPEN,
	SET_NO_ELEMENT_ERROR,
	SET_RECOMMENDATION_SHOWN,
	SET_SERVER_ERROR,
	SET_STORY,
	SET_SUMMARY_VIEW,
	SET_UNSEEN_RECOMMENDATION_COUNT,
	SET_USER,
	SET_WAS_OFFLINE,
	TUTORIAL_SET_EXPANDED,
	TUTORIAL_OPENED,
	SET_CLICKED_VALUE_PROP,
	SET_LEFT_TOGGLE_TABS,
	SET_RIGHT_TOGGLE_TABS,
	EXPAND_TUTORIAL_SET,
	SET_OPEN_TOOL_TIP,
	SET_IS_NEW_USER,
	SET_CACHE_CONN_DESC,
	SET_CACHE_CONN_SELECTION,
	SET_NEW_CONN_ID,
	SET_DIVING_DEEP,
	EDITOR_TEXT_CHANGE,
	MOVE_BEAT,
	REORDER_ELEMENT,
	SET_USER_SUBSCRIBED,
	SET_IS_MOBILE,
	RESET_ACTIVE_ELEMENT,
	SET_ACTIVE_ELEMENT,
	ON_SAVE_MODAL,
	SET_CONNECTION_COUNT,
	SET_ACTIVE_CONNECTIONS,
	SET_SCROLL_POSITION,
	SET_CHAPTER_VIEW_EXPANDED,
	SET_VISULIZATION_EXPANDED,
	SET_SUMMARY_CARD_VISIBLE,
	SET_CONTEXT_DEEP_MODE_CONNS,
	SET_FIELD_ERROR,
} from './actionTypes'

export const sharedReducer = (state, action) => {
	switch (action.type) {
		case SET_GRAPH_DATA:
			return { ...state, graphData: action.payload }
		case SET_SUMMARY_VIEW:
			return { ...state, isSummaryView: action.payload }
		case SET_SUMMARY_CARD_VISIBLE:
			return { ...state, isSummaryCardVisible: action.payload }
		case SET_STORY:
			return { ...state, story: action.payload }
		case SET_UNSEEN_RECOMMENDATION_COUNT:
			return { ...state, unseenRecommendationCount: action.payload }
		case IS_SAVING:
			return { ...state, isSaving: action.payload }
		case SET_DEEP_MODE_ELEMENT:
			return { ...state, deepModeElement: action.payload }
		case OPEN_RECOMMENDATION:
			return { ...state, recommendationId: action.payload }
		case RECOMMENDATION_OPENED:
			return { ...state, recommendationId: '' }
		case SET_SERVER_ERROR:
			return { ...state, serverError: action.payload }
		case SET_NO_ELEMENT_ERROR:
			return { ...state, noElementError: action.payload }
		case SET_WAS_OFFLINE:
			return { ...state, wasOffline: action.payload }
		case SET_IS_ONLINE:
			return { ...state, isOnline: action.payload }
		case SET_CURRENT_RECOMMENDATION:
			return { ...state, currentRecommendation: action.payload }
		case SET_RECOMMENDATION_SHOWN:
			return { ...state, isRecommendationShown: action.payload }
		case SET_DIFFERENT_VERSION:
			return { ...state, isDifferentVersion: action.payload }
		case SET_CURRENT_STORY:
			return { ...state, currentStory: action.payload }
		case DELETE_STORY:
			return { ...state, deleteStory: action.payload }
		case SET_CURRENT_STORY_ID:
			return { ...state, currentStoryId: action.payload }
		case SET_CURRENT_ELEMENT:
			return { ...state, currentElement: action.payload }
		case SET_IS_TOUR_OPEN:
			return { ...state, isTourOpen: action.payload }
		case IS_EXPORTING:
			return { ...state, isExporting: action.payload }
		case SET_BEATS_IN_CHAPTERS:
			return { ...state, beatsInChapters: action.payload }
		case SET_CONNECTION_DATA:
			return { ...state, connectionData: action.payload }
		case SET_USER:
			return { ...state, user: action.payload }
		case LOGIN:
			return { ...state, isLoggedIn: true }
		case SET_CURRENT_FLOW:
			return { ...state, flow: action.payload }
		case LOGOUT:
			return { ...state, isLoggedIn: false }
		case OPEN_TUTORIAL:
			return { ...state, tutorialId: action.payload }
		case EXPAND_TUTORIAL_SET:
			return { ...state, tutorialSetId: action.payload }
		case TUTORIAL_SET_EXPANDED:
			return { ...state, tutorialSetId: '' }
		case TUTORIAL_OPENED:
			return { ...state, tutorialId: '' }
		case SET_CLICKED_VALUE_PROP:
			return { ...state, clickedValueProp: action.payload }
		case SET_LEFT_TOGGLE_TABS:
			return { ...state, leftToggleTabs: action.payload }
		case SET_RIGHT_TOGGLE_TABS:
			return { ...state, rightToggleTabs: action.payload }
		case SET_OPEN_TOOL_TIP:
			return { ...state, openToolTip: action.payload }
		case SET_IS_NEW_USER:
			return { ...state, isNewUser: action.payload }
		case SET_CACHE_CONN_DESC:
			return {
				...state,
				cacheConnData: {
					...state.cacheConnData,
					description: action.payload,
				},
			}
		case SET_CACHE_CONN_SELECTION:
			return {
				...state,
				cacheConnData: {
					...state.cacheConnData,
					selection: action.payload,
				},
			}
		case SET_NEW_CONN_ID:
			return {
				...state,
				cacheConnData: {
					...state.cacheConnData,
					newConnId: action.payload,
				},
			}
		case SET_DIVING_DEEP:
			return { ...state, isDivingDeep: action.payload }
		case EDITOR_TEXT_CHANGE:
			return { ...state, editorData: action.payload }
		case SET_ACTIVE_ELEMENT:
			return { ...state, activeElement: action.payload }
		case RESET_ACTIVE_ELEMENT:
			return {
				...state,
				activeElement: {
					id: null,
					type: null,
				},
			}
		case MOVE_BEAT:
			return { ...state, movedPosition: action.payload }
		case REORDER_ELEMENT:
			return { ...state, reorderedPosition: action.payload }
		case SET_USER_SUBSCRIBED:
			return { ...state, isUserSubscribed: action.payload }
		case SET_IS_MOBILE:
			return { ...state, isMobile: action.payload }
		case ON_SAVE_MODAL:
			return { ...state, isModalOpen: action.payload }
		case SET_CONNECTION_COUNT:
			return { ...state, connectionCount: action.payload }
		case SET_ACTIVE_CONNECTIONS:
			return {
				...state,
				activeConnections: {
					isActive: action.payload.isActive,
					elementId: action.payload.elementId,
					elementType: action.payload.elementType,
				},
			}
		case SET_SCROLL_POSITION:
			return {
				...state,
				scrollPosition: {
					...state.scrollPosition,
					[action.payload.container]: action.payload.position,
				},
			}
		case SET_CHAPTER_VIEW_EXPANDED:
			return {
				...state,
				isChapterViewExpanded: action.payload,
			}

		case SET_VISULIZATION_EXPANDED:
			return {
				...state,
				isVisualizationExpanded: action.payload,
			}
		case SET_CONTEXT_DEEP_MODE_CONNS:
			return {
				...state,
				contextDeepModeConns: action.payload,
			}
		case SET_FIELD_ERROR:
			return { ...state, fieldError: action.payload }
		default:
			return state
	}
}
