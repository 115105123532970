import React, { useContext } from 'react'
import { sharedContext } from '../../state/sharedProvider'
import right_arrow from '../../images/blueCheveron.svg'
import { systemStateContext } from '../../state/systemProvider'
import { beatsDataStateContext } from '../../state/beatsProvider'

const CollapsiblePanel = ({ toggle, handleToggle, StyledDiv }) => {
	const {
		dispatch,
	} = useContext(sharedContext)

	const {
		deepModeElement,
	
	} = useContext(beatsDataStateContext)
	
	const systemRelatedData = useContext(systemStateContext)

	return (
		<StyledDiv
			toggle={toggle}
			onClick={() => {
				systemRelatedData?.createLog(
					!toggle ? 'Element List Expanded' : 'Element List Collasped',
					`{"isDeepMode":${!!deepModeElement},"button":"Arrows"}`,
					'Element List',
					'',
				)
				dispatch(handleToggle(!toggle))
			}}
		>
			<img src={right_arrow} alt="collapse arrow" />
		</StyledDiv>
	)
}

export default CollapsiblePanel
