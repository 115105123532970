import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import SerializingLink from 'apollo-link-serialize';
import { ApolloLink } from 'apollo-link';

import introspectionQueryResultData from './fragmentTypes.json'
import { nodeTypes } from '../utils/nodeTypes'
import axios from 'axios'
const defaults = {
	characterFilter: [],
}

const resolvers = {}

const cache = new InMemoryCache({
	possibleTypes: introspectionQueryResultData.possibleTypes,
	nodeTypes,
	typePolicies: {
		Query: {
			fields: {
				relations: {
					merge(existing, incoming) {
						return incoming
					},
				},
			},
		},
		
		Node: {
			fields: {
				beatConnections: {
					merge(existing, incoming) {
						return incoming
					},
				},
				childConnections: {
					merge(existing, incoming) {
						return incoming
					},
				},
				noteConnections: {
					merge(existing, incoming) {
						return incoming
					},
				},
				driverConnections: {
					merge(existing, incoming) {
						return incoming
					},
				},
				staticConnections: {
					merge(existing, incoming) {
						return incoming
					},
				},
			},
		},
		
	},
})

const headersLink = setContext(async (_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('token')
	const story = sessionStorage.getItem('story')
	const su = localStorage.getItem('su')
	//const ipAddress = (await axios.get('https://geolocation-db.com/json/')).data.IPv4
	
		
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			story: story ? `Story ${story}` : `Story `,
			su: su ? `Su ${su}` : `Su `,
			apikey: process.env.REACT_APP_LYNIT_API_KEY,
			//ipAddress: ipAddress,
		},
	}
})

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4001/graphql',
})

const serializingLink = new SerializingLink();

// TODO: Try configuring query batching: https://blog.apollographql.com/query-batching-in-apollo-63acfd859862
const client = new ApolloClient({
	//link: headersLink.concat(httpLink),
	link: ApolloLink.from([serializingLink,headersLink.concat(httpLink)]),
	clientState: {
		defaults,
		resolvers,
	},
	cache,
})

export const ApolloClientProvider = ({ children }) => {
	return <ApolloProvider client={client}>{children}</ApolloProvider>
}
