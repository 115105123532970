import { useContext, useEffect, useState } from 'react'
import orderBy from 'lodash/orderBy'
import { sharedContext } from '../state'
import { beatMoved, elementReordered } from '../state/actions'
import { useGetRelationshipsQuery } from './getRelationshipsData'
import { arrayMove } from '@dnd-kit/sortable'
import { graphDataStateContext } from '../state/graphDataProvider'
import { systemStateContext } from '../state/systemProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../state/beatsProvider'

export const useDraggable = (id, active, over, existingBeatConnections = []) => {
	const [beatConnections, setBeatConnections] = useState(existingBeatConnections)
	const {
		state: { movedPosition },
		dispatch,
	} = useContext(sharedContext)

	const beatsRelatedData = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemRelatedData = useContext(systemStateContext)
	const graphData = useContext(graphDataStateContext)

	const { data: relationships } = useGetRelationshipsQuery()

	useEffect(() => {
		const connections = graphData.nodes[id]?.beatConnections.map(connection => {
			return { ...connection, order: +connection.order }
		})
		const orderedConnections = orderBy(connections, ['order'], ['asc'])
		if (orderedConnections !== beatConnections){
			setBeatConnections(orderedConnections)
		}
		
	}, [graphData])

	useEffect(() => {
	
		if (active ) {
			if(over && (over?.data?.current?.location ==="Chapter Card")  
				&& (active?.data?.current?.location !=="Chapter Card") 
				&& (beatConnections?.length !== graphData?.nodes[over?.data?.current?.referenceElement?.id]?.beatConnections?.length)
				&& (over?.data?.current?.id !== active?.data?.current?.id) 
				&& (active?.data?.current?.id) 
				&&  (over?.data?.current?.referenceElement?.id === id) ) //manage dragging beat over temp self in chapter before dropping there.
			{
				
				var currentSet = new Set(graphData?.nodes[over?.data?.current?.referenceElement?.id]?.beatConnections.map(conn => conn?.id));
				const tempBeatIndex = beatConnections?.findIndex(x => !currentSet.has(x?.id))
				
				if(tempBeatIndex>=0){
					const orderedConnections = arrayMove(
						beatConnections,
						+tempBeatIndex ,
						+over?.data?.current?.sortable?.index ,
					)
					if (orderedConnections !== beatConnections){
						setBeatConnections(orderedConnections)

					}

				

				}
				
				return
				

			}
			const activeRelation = relationships?.relations.find(relation => relation?.id === active?.id)
			const overRelation = relationships?.relations.find(relation => relation?.id === over?.data?.current?.id)
			const sourceRelation = beatConnections.find(connection => connection?.id === active?.id)
			const destRelation = beatConnections.find(connections => connections?.id === over?.data?.current?.id)
			let referenceElement
			let beatId
			let beat
			let oldChapterId
			

			if (activeRelation?.connectionType === 'Driver' && systemRelatedData?.isUserSubscribed) {
				return
			}
			if (active?.id?.startsWith('rel')) {
				referenceElement = !activeRelation?.sourceNode?.id?.startsWith('bea')
					? activeRelation?.sourceNode
					: activeRelation?.destNode
				if (activeRelation?.connectionType === 'Beat') {
					beatId = activeRelation?.destNode?.id?.startsWith('bea')
						? activeRelation?.destNode?.id
						: activeRelation?.sourceNode?.id
					beat = graphData.nodes[beatId]
					oldChapterId = beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
						? beat?.beatConnections?.[0]?.sourceNode?.id
						: beat?.beatConnections?.[0]?.destNode?.id
				}
			}
		
			if (
				active?.id?.startsWith('rel') &&
				!over?.data?.current?.referenceElement?.id &&
				(!over || (active?.id !== over?.data?.current?.id && overRelation?.connectionType !== 'Beat'))
				) 
			{
				if (sourceRelation) {
					const newConnections =
						graphData.nodes[id]?.beatConnections?.map(connection => ({
							...connection,
							order: +connection.order,
						})) || []

						const orderedConnections = orderBy(newConnections, ['order'], ['asc'])
						if (orderedConnections.length !== beatConnections.length){
							setBeatConnections(orderedConnections)
	
						}

				}
				const referenceElement = !activeRelation?.sourceNode?.id?.startsWith('bea')
					? activeRelation?.sourceNode
					: activeRelation?.destNode
				if (id === referenceElement?.id) {
					const connections =
						graphData.nodes[referenceElement?.id]?.beatConnections?.map(connection => ({
							...connection,
							order: +connection.order,
						})) || []

						const orderedConnections = orderBy(connections, ['order'], ['asc'])
						if (orderedConnections.length !== beatConnections.length){

							setBeatConnections(orderedConnections)
	
						}
						
				}
			} else if (active?.id?.startsWith('rel') && over?.data?.current?.id?.startsWith('rel')) {
				// if we drag beat between chapters
				if ((sourceRelation || destRelation) && !(sourceRelation && destRelation)) {
					if (sourceRelation) {
						//delete connection from the source chapter
						let newConnections = beatConnections?.filter(
							connection => connection?.id !== sourceRelation?.id,
						)
						newConnections =
							newConnections?.map(connection => {
								if (connection.order > +sourceRelation.order) {
									return { ...connection, order: connection.order - 1 }
								}
								return connection
							}) || []
						
						const orderedConnections = orderBy(newConnections, ['order'], ['asc'])
						if (orderedConnections.length !== beatConnections.length){
							setBeatConnections(orderedConnections)
	
						}
						
					}
					if (destRelation) {
						// create connection to the destination chapter
						if (activeRelation?.connectionType === 'Driver') {
							const beatId = activeRelation?.destNode?.id?.startsWith('bea')
								? activeRelation?.destNode?.id
								: activeRelation?.sourceNode?.id
							const beat = graphData.nodes[beatId]
							const oldChapterId = beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
								? beat?.beatConnections?.[0]?.sourceNode?.id
								: beat?.beatConnections?.[0]?.destNode?.id

							// if beat already exist then return
							if (oldChapterId === id) {
								return
							}
						}
						if (id !== referenceElement?.id) {
							const newConnection = {
								__typename: 'Relationship',
								id: activeRelation?.id,
								connectionType: 'Beat',
								arcStage: null,
								description: activeRelation?.description,
								order: destRelation.order,
								relName: null,
								arc: null,
								sourceNode: activeRelation?.sourceNode?.id?.startsWith('bea')
									? activeRelation?.sourceNode
									: activeRelation?.destNode,
								destNode: destRelation.destNode,
							}

							let newBeatConnections =
								beatConnections?.map(beatConnection => {
									if (beatConnection.order >= destRelation.order) {
										return { ...beatConnection, order: beatConnection.order + 1 }
									}
									return beatConnection
								}) || []

							if(over?.data?.current?.id !== over?.id && over?.data?.current?.id?.startsWith('rel') && over?.data?.current.location==="Chapter Card"){
								const test = true
							}
							newBeatConnections = [...newBeatConnections, newConnection]

							const orderedConnections = orderBy(newBeatConnections, ['order'], ['asc'])
							if (orderedConnections.length !== beatConnections.length){
								setBeatConnections(orderedConnections)
		
							}
							
						} else {
							const connections =
								graphData.nodes[referenceElement?.id]?.beatConnections?.map(connection => {
									return { ...connection, order: +connection.order }
								}) || []
							const orderedConnections = orderBy(connections, ['order'], ['asc'])
							if (orderedConnections.length !== beatConnections.length){
								setBeatConnections(orderedConnections)
		
							}
								
						}
					}
				}
				
			// drag the beat to the chapter that does not have beat connections
				if (over?.data?.current?.referenceElement?.id === id && graphData.nodes[over?.data?.current?.referenceElement?.id]?.beatConnections?.length === 0) {
					const newConnection = {
						__typename: 'Relationship',
						id: activeRelation?.id,
						connectionType: 'Beat',
						arcStage: null,
						description: activeRelation?.description,
						order: 0,
						relName: null,
						arc: null,
						sourceNode: activeRelation?.sourceNode?.id?.startsWith('bea')
							? activeRelation?.sourceNode
							: activeRelation?.destNode,
						destNode: {
							id: over?.data?.current?.referenceElement?.id,
							__typename: 'Chapter',
						},
					}
					
					const orderedConnections = [newConnection]
					if (orderedConnections.length !== beatConnections.length){
						setBeatConnections(orderedConnections)

					}

					
				}


			} else if (
				active?.id?.startsWith('rel') &&
				over?.data?.current?.id?.startsWith('container') &&
				graphData.nodes[over?.data?.current?.referenceElement?.id]?.beatConnections?.length !== 0
			) {
				const sourceElement = beatConnections?.find(connection => connection?.id === active?.id)
				const destElement = id === over?.data?.current?.referenceElement?.id

				if (sourceElement && destElement) {
					let newConnections = beatConnections?.filter(
						connection => connection?.id !== sourceRelation?.id,
					)
					newConnections =
						newConnections?.map(connection => {
							if (connection.order > sourceRelation?.order) {
								return { ...connection, order: connection.order - 1 }
							}
							return connection
						}) || []
					const newConnection = {
						__typename: 'Relationship',
						id: activeRelation?.id,
						connectionType: 'Beat',
						arcStage: null,
						description: activeRelation?.description,
						order: beatConnections[beatConnections.length - 1].order,
						relName: null,
						arc: null,
						sourceNode: activeRelation?.sourceNode?.id?.startsWith('bea')
							? activeRelation?.sourceNode
							: activeRelation?.destNode,
						destNode: {
							id: over?.data?.current?.referenceElement?.id,
							__typename: 'Chapter',
						},
					}

					const orderedConnections = orderBy([...newConnections, newConnection], ['order'], ['asc'])
					if (orderedConnections.length !== beatConnections.length){
				
						setBeatConnections(orderedConnections)

					}

				} else if (sourceElement || destElement) {
					if (sourceElement) {
						let newConnections = beatConnections.filter(
							connection => connection?.id !== sourceRelation?.id,
						)
						newConnections = newConnections.map(connection => {
							if (connection.order > sourceRelation?.order) {
								return { ...connection, order: connection.order - 1 }
							}
							return connection
						})

						const orderedConnections = orderBy(newConnections, ['order'], ['asc'])
						if (orderedConnections !== beatConnections){
							setBeatConnections(orderedConnections)
	
						}

						
					}
					if (destElement) {
						if (activeRelation?.connectionType === 'Driver') {
							// if beat already exist then return
							if (oldChapterId === id) {
								return
							}
						}

						const newConnection = {
							__typename: 'Relationship',
							id: activeRelation?.id,
							connectionType: 'Beat',
							arcStage: null,
							description: activeRelation?.description,
							order: beatConnections?.length
								? beatConnections[beatConnections.length - 1].order + 1
								: 0,
							relName: null,
							arc: null,
							sourceNode: activeRelation?.sourceNode?.id?.startsWith('bea')
								? activeRelation?.sourceNode
								: activeRelation?.destNode,
							destNode: {
								id: over?.data?.current?.referenceElement?.id,
								__typename: 'Chapter',
							},
						}

						const orderedConnections = orderBy([...beatConnections, newConnection], ['order'], ['asc'])
						if (orderedConnections !== beatConnections){
							setBeatConnections(orderedConnections)
	
						}

						
					}
				}
			}
		}
	}, [over])

	// update order while reordering beat to display updated position before getting response from an api
	useEffect(() => {
		if (
			beatsRelatedData?.reorderedPosition?.referenceElement?.id === id &&
			beatsRelatedData?.reorderedPosition?.source?.index !== beatsRelatedData?.reorderedPosition?.destination?.index
		) {
			setBeatConnections(
				arrayMove(
					beatConnections,
					beatsRelatedData?.reorderedPosition?.source?.index,
					beatsRelatedData?.reorderedPosition?.destination?.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [beatsRelatedData?.reorderedPosition])

	useEffect(() => {
		if (
			movedPosition?.sourceNode?.id === id &&
			beatConnections?.find(connection => connection?.id === movedPosition?.sourceRelation?.id)
		) {
			setBeatConnections(
				orderBy(
					beatConnections?.filter(
						connection => connection?.id !== movedPosition?.sourceRelation?.id,
					),
					['order'],
					['asc'],
				),
			)
			dispatch(beatMoved())
		}
	}, [movedPosition])

	return beatConnections
}
