import { useMutation } from '@apollo/client'
import {
	createCacheConnection,
	deleteCacheConnections,
	updateCacheField,
} from '@lynit/shared/src/utils/apollo'
import { ADD_DRIVERS_TO_BEATS } from '../data'
import { toastHandler } from '../utils/backendHandler'

export const useAddDriversToBeatsMutation = (options = {}) => {
	const [addDriversToBeats, { data, loading, error }] = useMutation(ADD_DRIVERS_TO_BEATS, options)
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const performAddDriversToBeatsMutation = async ({
		removedDriverConnections,
		addedDriverConnections,
		newStaticConnections,
		existingStaticConnections,
		client,
		sourceId,
		beat,
		driversToMove,
	}) => {
		const removedDriverConnectionsList = [...removedDriverConnections]

		if (!client) {
			throw new Error('Missing client for AddDrivers')
		}

		if (!sourceId) {
			throw new Error('Missing sourceId for AddDrivers')
		}

		if (!beat) {
			throw new Error('Missing beat for AddDrivers')
		}

		if (!driversToMove) {
			throw new Error('Missing driversToMove for AddDrivers')
		}

		if (
			!removedDriverConnections ||
			!addedDriverConnections ||
			!newStaticConnections ||
			!existingStaticConnections
		) {
			throw new Error('Something went wrong')
		}

		if (addedDriverConnections?.length) {
			await createCacheConnection(
				client,
				addedDriverConnections.concat(newStaticConnections),
				true,
				true,
			)
		}

		if (driversToMove.length && beat?.driverConnections) {
			beat.driverConnections.forEach(async connection => {
				if (
					driversToMove.find(driver =>
						[connection?.sourceNode?.id, connection?.destNode?.id].includes(driver.id),
					)
				) {
					removedDriverConnectionsList.push(connection)
					await deleteCacheConnections(client, connection)
				}
			})
		}

		

		existingStaticConnections.map(conn => {
			updateCacheField(client, conn, {
				beatsDriven: conn.beatsDriven,
				sharedBeats: conn.sharedBeats,
			})
		})

		await addDriversToBeats({
			variables: {
				beatId: sourceId,
				addedDriverConnections:
					addedDriverConnections.map(conn => {
						const output = {
							id: conn.id,
							sourceNodeId: conn.sourceNode.id,
							destNodeId: conn.destNode.id,
							order: conn.order,
						}

						return output
					}) || [],
				driversToDelete:
					removedDriverConnectionsList.map(conn => {
						const output = {
							id: conn.id,
							sourceNodeId: conn.sourceNode.id,
							destNodeId: conn.destNode.id,
							order: conn.order,
						}

						return output
					}) || [],
				newStaticConnections: newStaticConnections.map(conn => {
					const output = {
						...conn,
						sourceNodeId: conn.sourceNode.id,
						destNodeId: conn.destNode.id,
						sharedBeats: conn.sharedBeats.map(beat => {
							return { id: beat.id }
						}),
					}
					delete output.sourceNode
					delete output.destNode
					delete output.__typename
					delete output.arc

					return output
				}),
			},
		}).catch(async error => {
			toastHandler(
				'error',
				'Error adding driver to beats, try again later.',
				null,
				'DriverModal',
				'Connection Creation',
			)

			if (removedDriverConnectionsList.length) {
				await createCacheConnection(client, removedDriverConnectionsList, true)
			}

			addedDriverConnections?.forEach(async connection => {
				await deleteCacheConnections(client, connection)
			})

			newStaticConnections.forEach(async conn => {
				await deleteCacheConnections(client, conn)
			})


			existingStaticConnections.map( conn =>{
				const sharedBeats = conn.sharedBeats.filter(sharedBeat => sharedBeat.id !== sourceId)
				let beatsDriven = sharedBeats.length
				if(sharedBeats.length === conn.sharedBeats.length){
					beatsDriven = beatsDriven + 1
				}
				updateCacheField(client, conn, {beatsDriven:String(beatsDriven), sharedBeats})
			})

		
		})
	}
	return [performAddDriversToBeatsMutation, { data, loading, error }]
}
