import React, { useLayoutEffect, useState, useEffect, useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { sharedContext } from '../../state/sharedProvider'
import {
	CollapsedTutorialSet,
	IconContainer,
	RequestCourseButton,
	TutorialSetContainer,
	TutorialSetsContainer,
	TutorialTab,
	TutorialTitle,
} from './styles'

import LoadingSpinner from '../../shared-ui/LoadingSpinner'
import TutorialSet from './TutorialSet'
import { useStoryOutlineWidth } from '../../utils/utils'
import infoIcon from '../../images/info-icon.svg'
import requestCourseIcon from '../../images/i-icon.svg'
import { useLazyGetTutorialsQuery } from '../../hooks'

const Tutorials = ({ currentRecommendation, isDashboard, createLog }) => {
	const [getTutorials, { data, loading }] = useLazyGetTutorialsQuery({ fetchPolicy: 'cache-first' })

	const [tutorialSets, setTutorialSets] = useState([])
	const [previousRecommendation, setPreviousRecommendation] = useState()

	const { isNarrow } = useStoryOutlineWidth()

	const {
		state: { tutorialId, leftToggleTabs, rightToggleTabs },
	} = useContext(sharedContext)

	// loading state from graphql is not updating properly so used lazyQuery and called refetch on component mount
	useEffect(() => {
		getTutorials()
	}, [])

	useEffect(() => {
		if (data?.tutorials) {
			const orderedTutorialSets = cloneDeep(data?.tutorials).sort(
				(current, next) => Number(current.order) - Number(next.order),
			)
			setTutorialSets(orderedTutorialSets)
		}
	}, [data])

	// open tutorial if use clicks on tutorial link
	useLayoutEffect(() => {
		if (tutorialId) {
			const allTutorials = tutorialSets?.reduce((acc, tutorialSet) => {
				return [...acc, ...tutorialSet?.tutorials]
			}, [])
			const tutorial = allTutorials?.find(tutorial => tutorial.id === tutorialId)
			if (tutorial) {
				if (currentRecommendation?.data) {
					setPreviousRecommendation(currentRecommendation?.data)
				}
			}
		}
	}, [tutorialId, tutorialSets])

	if (loading) {
		return <LoadingSpinner />
	}

	return (
		<TutorialTab isNarrow={!isDashboard && isNarrow} isDashboard={isDashboard} data-testid="tutorial-tab">
			<TutorialSetsContainer
				isDashboard={isDashboard}
				leftToggleTabs={leftToggleTabs}
				rightToggleTabs={rightToggleTabs}
			>
				{tutorialSets?.map((tutorialSet, index) => (
					<TutorialSet
						key={tutorialSet.id}
						tutorialSet={tutorialSet}
						isExpanded={false}
						createLog={createLog}
						previousRecommendation={previousRecommendation}
					/>
				))}
				<TutorialSetContainer isCollapsed={true} onClick={() => {
								window.open("https://community.lynit.app") 
							}}>
					<CollapsedTutorialSet notClickable={false}>
						<TutorialTitle>
							<IconContainer>
								<img src={infoIcon} alt="information icon" />
							</IconContainer>
							<span className="title">Join the Lynit Community</span>
						</TutorialTitle>
						<RequestCourseButton
							// onClick={() => {
							// 	window.open("https://www.lynit.app") 
							// }}
						>
							<img src={requestCourseIcon} alt="request course icon" />
							<span className="req-course">Go to Community</span>
						</RequestCourseButton>
					</CollapsedTutorialSet>
				</TutorialSetContainer>
			</TutorialSetsContainer>
		</TutorialTab>
	)
}

export default Tutorials
