/* eslint-disable */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable no-console */
/* eslint-disable import/named */
/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuill } from 'react-quilljs'
import { useFullScreenHandle } from 'react-full-screen'
import { useApolloClient } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import { withStyles, makeStyles } from '@mui/styles'
import { Box, Popover, Tooltip } from '@mui/material'
import { arrayMove } from '@dnd-kit/sortable'
import cloneDeep from 'lodash.clonedeep'
import hash from 'object-hash'
import { debounce, orderBy } from 'lodash'
import SuggestionTooltip from '@lynit/shared/src/ui/ToolTip/SuggestionTooltip'
import { getConnectionCount, updateCacheField } from '@lynit/shared/src/utils/apollo'
import BorderLinearProgress from '@lynit/shared/src/shared-ui/BorderLinearProgress'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import OutsideClickHandler from '@lynit/shared/src/utils/OutsideClickHandler'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { autoSaveDispatchContext, setSavingData } from '@lynit/shared/src/state/autoSaveProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { createNodeId, throttle } from '@lynit/shared/src/utils/utils'
import { progressCircle } from '@lynit/shared/src/utils/commonStyles'
import { deleteCurrentStory } from '@lynit/shared/src/state/actions'
import {updateNodeMutations} from '@lynit/shared/src/data'
import { systemStateContext, systemDispatchContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataStateContext,
	beatsDataDispatchContext,
} from '@lynit/shared/src/state/beatsProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	elementReordered,
	setConnectionCount,
	setIsExporting,
	setLeftToggleTabs,
	setRightToggleTabs,
	setActiveConnections,
	setSummaryCardVisible,
} from '@lynit/shared/src/state/actions'

import {
	useCreateNodeMutation,
	useGetRelationshipsQuery,
	useUpdateNodeMutation,
	useResetVizCordinates,
	useEditStoryMutation,
	useLazyGetUserStoryQuery,
} from '@lynit/shared/src/hooks'
import {
	createCacheElement,
	updateCacheFieldAllNodes,
	deleteCacheElement,
} from '@lynit/shared/src/utils/apollo'

import buildStoryQuill from '../../utils/ExportData/buildStoryQuill'
import exportData from '../../utils/ExportData/exportData'
import buildStoryTextQuill from '../../utils/ExportData/buildStoryTextQuill'

import ResetIcon from '../../images/reset.svg'
import visIcon from '../../images/vis-icon.svg'
import supportingIcon from '../../images/structure-tag-no-color.svg'
import opposingIcon from '../../images/friction.svg'
import curveArrowIcon from '../../images/curve-arrow.svg'
import closeIcon from '../../images/close.svg'
import editIcon from '../../images/edit-icon.svg'
import premiseRingTooltipIcon from '../../images/premise-ring-tooltip.svg'
import missingConnectionIcon from '../../images/missing-connections.svg'
import missingBeatIcon from '../../images/missing-beat.svg'
import downIcon from '../../images/down-summary-card.svg'
import upIcon from '../../images/up-summary-card.svg'
import missingConnSuggestion1 from '../../images/missing-conn-sugg1.svg'
import missingConnSuggestion2 from '../../images/missing-conn-sugg2.svg'
import missingConnSuggestion3 from '../../images/missing-conn-sugg3.svg'
import missingBeatSuggestion1 from '../../images/missing-beat-sugg1.svg'
import missingBeatSuggestion2 from '../../images/missing-beat-sugg2.svg'
import missingBeatSuggestion3 from '../../images/missing-beat-sugg3.svg'
import missingBeatSuggestion4 from '../../images/missing-beat-sugg4.svg'
import trashIcon from '../../images/delete.svg'
import exportIcon from '../../images/export-icon.svg'
import missingElementIcon from '../../images/missing-element.svg'
import missingElementSuggestion1 from '../../images/missing-ele-1.svg'
import missingElementSuggestion2 from '../../images/missing-ele-2.svg'
import missingElementSuggestion3 from '../../images/missing-ele-3.svg'
import suggestionIcon from '@lynit/shared/src/images/suggestion-icon.svg'
import missingStructureTagIcon from '@lynit/shared/src/images/missingStructureTag.svg'

import { vizNodeHover } from '../../state/actions'
import renderGraph from './vizHelpers'

import {
	VizContainer,
	VizContent,
	DeepModeContainer,
	ConnectionListContainer,
	ConnectionListTitle,
	ConnectionListPanel,
	ConnectionListCurveArrow,
	ConnectionListItem,
	SummaryCardContainer,
	CloseSummaryCard,
	StoryNameListItem,
	StorySummaryListItem,
	StorySummaryBox,
	PremiseRingData,
	MissingElementContainer,
	MissingConnectionsContainer,
	MissingBeatConnectionContainer,
	Title,
	SummaryCardSuggestion,
	StyledFullscreen,
	MissingConnectionSuggestion,
	IconContainer,
	SummaryCardMissingBeatSuggestion,
	ListIcon,
	ResetBtn,
} from './styles'
import { DescriptionField } from '../Summary/styles'
import MissingPremiseDriver from '@lynit/layout/src/ui/ListElement/MissingPremiseDriver'
import CreateConnectionModal from '@lynit/shared/src/ui/CreateConnectionModal'
import MissingStructureTagDriver from '@lynit/layout/src/ui/ListElement/MissingStructureTagDriver'
import ContentDialog from '../../../../unused-code-package/src/ui/reusable/ContentDialog'
import NewConnectionCard from '@lynit/layout/src/ui/ListElement/NewConnectionCard'
import {
	TooltipDescription,
	TooltipLabel,
	TooltipWrapper,
} from '@lynit/layout/src/ui/ListElement/styles'
import getConnections from '@lynit/shared/src/utils/getConnections'

const muiStyles = {
	progressCircle,
}

// Parse the graph map {id: node} into lists of nodes and edges like D3 wants
const parseGraphDataForD3 = (gm, deepModeElement, connectionType) => {
	// Must clone the graph data before parsing out the node object and edges list
	// because D3 mutates the node objects
	const graphData = cloneDeep(gm)

	let nodes = []
	nodes = Object.values(graphData)?.filter(node => {
		node.connections = [
			//...(node?.beatConnections || []),
			...(node?.driverConnections || []),
			...(node?.staticConnections || []),
		]

		return (
			// (node?.__typename === 'Chapter' &&
			// 	node?.connections?.filter(c => c?.connectionType?.includes(connectionType))?.length > 0) ||
			// (node?.__typename === 'Note' &&
			// 	node?.connections?.filter(c => c?.connectionType?.includes(connectionType))?.length > 0) ||
			!['Note', 'Beat', 'Chapter'].includes(node?.__typename) ||
			(node?.__typename === 'Chapter' && node?.id === deepModeElement)
		)
	})
	const edges = []
	const connectionIdSet = new Set()
	const premiseNodes = []
	const connectionsGroups = {Premise:{connections:[],order:0, nodes:[]}, Character:{connections:[],order:1, nodes:[]},Theme:{connections:[],order:2, nodes:[]},Event:{connections:[],order:3, nodes:[]}, Arc:{connections:[],order:4, nodes:[]}}
	const checkConnectionGroup = (conn, connectionsGroups) => {
	
		if(graphData[conn.destNode?.id]?.coreElement==="true" || graphData[conn.sourceNode?.id]?.coreElement ==="true"){
			return "Premise"
		}
		if(connectionsGroups[conn.destNode?.__typename]?.order <connectionsGroups[conn.sourceNode?.__typename]?.order ){
			return conn.destNode?.__typename
		} else {
			return conn.sourceNode?.__typename
		}


	}
	nodes?.forEach(node => {
		// if (node.coreElement === 'true') {
		// 	premiseNodes.push(graphData[node?.id])
			
		// }
		node.ring = node.coreElement ==="true"? "Premise" : node.__typename
		connectionsGroups[node.ring].nodes.push(graphData[node?.id])
		node?.connections?.forEach(conn => {
			if (connectionIdSet.has(conn.id)) {
				return
			}
			connectionIdSet.add(conn.id)
			const { destNode, sourceNode } = conn
			
			
			
			if (destNode?.id?.startsWith('chp') || sourceNode?.id?.startsWith('chp')) {
				return
			}
			
			if (
				conn?.connectionType?.includes(connectionType) &&
				graphData?.hasOwnProperty(destNode?.id) &&
				graphData?.hasOwnProperty(sourceNode?.id)
			) {
				if (conn?.connectionType === 'Static') {
						const ringName = checkConnectionGroup(conn, connectionsGroups)
						edges?.push({
						source: graphData[node?.id],
						target: destNode?.id === node.id ? graphData[sourceNode?.id] : graphData[destNode?.id],
						type: conn?.connectionType,
						structureTag: conn.structureTag,
						id: conn.id,
						beatsDriven: conn.beatsDriven,
						ringName
					})
					connectionsGroups[ringName]?.connections?.push({
						source: graphData[node?.id],
						target: destNode?.id === node.id ? graphData[sourceNode?.id] : graphData[destNode?.id],
						type: conn?.connectionType,
						structureTag: conn.structureTag,
						id: conn.id,
						beatsDriven: conn.beatsDriven,
						ringName,
					})
				}
			}
		})
	})

	const possiblePremiseConnections = []
	connectionsGroups.Premise.nodes.map((node, index) => {
		connectionsGroups.Premise.nodes.slice(index)?.forEach(n => {
			if (node.id !== n.id) {
				possiblePremiseConnections.push({
					source: node,
					target: n,

				})
			}
		})
	})
	const existingConnections =
		possiblePremiseConnections.map(conn => {
			const foundEdge = edges.find(edge => {
				return (
					(edge.source.id === conn.source.id && edge.target.id === conn.target.id) ||
					(edge.source.id === conn.target.id && edge.target.id === conn.source.id)
				)
			})
			
			return foundEdge
		}).filter(conn => conn) || []

	const missingConnections =
		possiblePremiseConnections.filter(conn => {
			const foundEdge = edges.find(edge => {
				return (
					(edge.source.id === conn.source.id && edge.target.id === conn.target.id) ||
					(edge.source.id === conn.target.id && edge.target.id === conn.source.id)
				)
			})
			
			return !foundEdge
		})|| []

		// const missingConnections =
	// 	(missingConnectionResults?.length &&
	// 		missingConnectionResults.filter(conn => graphData[conn?.sourceNodeId] && graphData[conn?.destNodeId] ).map(val => {

	// 				return {
	// 					...val,
	// 					source: graphData[val?.sourceNodeId],
	// 					target: graphData[val?.destNodeId],
	// 				}

	// 		})) ||
	// 	[]

	return [nodes, edges, missingConnections,existingConnections,connectionsGroups]
}

const VizDataWrapper = props => {
	const {
		graphData: { loading, errors },
	} = props

	if (loading || errors) {
		// TODO handle errors
		return <CircularProgress className={props.classes.progressCircle} color="primary" />
	}
	return <Viz {...props} />
}

// Allows us to check if the contents of the graph has changed. So we can
// manually memoize and not re-render (and interrupt the d3 force simulation animation)
// for non-graph data state/prop changes (e.g. hoveredElem change).
const equivalentGraph = (prevProps, nextProps) => {
	// hash the data to compare it in order to compare the data change.
	const prevGraphNodesLength = (Object.values(prevProps.graphData.nodes)
		.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
		?.map(node => {
			return node.name
		})).length
	const nextGraphNodesLength = (Object.values(nextProps.graphData.nodes)
		.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
		?.map(node => node.name)).length
	
	const prevGraphNodesNameHash = hash(
		new Set(
			Object.values(prevProps.graphData.nodes)
				.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node.name),
		),
	)
	const nextGraphNodesNameHash = hash(
		new Set(
			Object.values(nextProps.graphData.nodes)
				.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node.name),
		),
	)
	const prevGraphDriverConnectionsHash = hash(
		new Set(
			Object.values(prevProps.graphData.nodes)
				.filter(node => ['Beat'].includes(node.__typename))
				?.map(node => node.driverConnections)
				.flat()
				.map(conn => conn?.id),
		),
	)
	const nextGraphDriverConnectionsHash = hash(
		new Set(
			Object.values(nextProps.graphData.nodes)
				.filter(node => ['Beat'].includes(node.__typename))
				?.map(node => node.driverConnections)
				.flat()
				.map(conn => conn?.id),
		),
	)
	const prevGraphStaticConnectionsHash = hash(
		new Set(
			Object.values(prevProps.graphData.nodes)
				.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node.staticConnections)
				.flat()
				.map(conn => conn?.id + conn?.structureTag),
		),
	)
	const nextGraphStaticConnectionsHash = hash(
		new Set(
			Object.values(nextProps.graphData.nodes)
				.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node.staticConnections)
				.flat()
				.map(conn => conn?.id + conn?.structureTag),
		),
	)
	const prevGraphVizLocSaved = new Set(
		Object.values(prevProps.graphData.nodes)
			?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
			?.map(node => node.vizLocSaved) // node.id + node.yCoordinate + node.xCoordinate
			.flat(),
	)
	const nextGraphVizLocSaved = new Set(
		Object.values(nextProps.graphData.nodes)
			?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
			?.map(node => node.vizLocSaved)
			.flat(),
	)

	const prevGraphVizCoreElementHash = hash(
		new Set(
			Object.values(prevProps.graphData.nodes)
				?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node?.id + node.coreElement) // node.id + node.yCoordinate + node.xCoordinate
				.flat(),
		),
	)
	const nextGraphVizCoreElementHash = hash(
		new Set(
			Object.values(nextProps.graphData.nodes)
				?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node?.id + node.coreElement)
				.flat(),
		),
	)

	const prevGraphVizCoordsHash = hash(
		new Set(
			Object.values(prevProps.graphData.nodes)
				?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node?.id + node.yCoordinate + node.xCoordinate) // node.id + node.yCoordinate + node.xCoordinate
				.flat(),
		),
	)
	const nextGraphVizCoordsHash = hash(
		new Set(
			Object.values(nextProps.graphData.nodes)
				?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
				?.map(node => node?.id + node.yCoordinate + node.xCoordinate)
				.flat(),
		),
	)

	return (
		prevGraphStaticConnectionsHash === nextGraphStaticConnectionsHash &&
		prevGraphNodesNameHash === nextGraphNodesNameHash &&
		//prevGraphBeatConnectionsHash === nextGraphBeatConnectionsHash &&
		prevGraphDriverConnectionsHash === nextGraphDriverConnectionsHash &&
		prevGraphNodesLength === nextGraphNodesLength &&
		prevProps.connectionType === nextProps.connectionType &&
		prevGraphVizCoordsHash === nextGraphVizCoordsHash &&
		prevGraphVizCoreElementHash === nextGraphVizCoreElementHash &&
		prevGraphVizLocSaved.size === nextGraphVizLocSaved.size
	)
}

const useStyles = makeStyles(() => ({
	root: {
		boxShadow: 'none',
	},
	tooltip: {
		margin: '0',
		fontSize: '9px',
		background: '#55534F',
		textTransform: 'capitalize',
		borderRadius: '20px',
	},
	backDrop: {
		backdropFilter: 'blur(5px)',
		backgroundColor: 'rgba(243, 243, 246, 0.6)',
	},
}))

const Viz = React.memo(
	({
		//graphData: { nodes, nodesByType },
		// vizNodeHover,
		createLog,
		isEmpty,
		// isRightSidePanel,
		connectionType
	}) => {
		const types = ['Arc', 'Character', 'Event', 'Theme']
		const client = useApolloClient()
		const fullscreenHandle2 = useFullScreenHandle()
		const [width, setWidth] = useState(0)
		const [height, setHeight] = useState(0)
		const [isFullscreen, setIsFullscreen] = useState(false)
		const [connectionModal, setConnectionModal] = useState(false)
		const [connectionModalType, setConnectionModalType] = useState(false)
		const [resetGraph, setResetGraph] = useState(false)
		const [missingConnections, setMissingConnections] = useState([])
		const [existingConnections, setExistingConnections] = useState([])
		const [missingElementSuggestion, setMissingElementSuggestion] = useState(false)
		const [missingConnSuggestion, setMissingConnSuggestion] = useState(false)
		const [missingBeatConnection, setMissingBeatConnection] = useState(false)
		const [connectionsGroups, setConnectionsGroups] = useState({})
		const [currentElementPremise, setCurrentElementPremise] = useState(types[1])
		const [filteringData, setFilteringData] = useState([])
		const [isSupporting, setIsSupporting] = useState(false)
		const [isOpposing, setIsOpposing] = useState(false)
		const [isUndefined, setIsUndefined] = useState(false)
		const [currentOpenedDriver, setCurrentOpenedDriver] = useState(null)
		const [isOpenDialogOverlay, setIsOpenDialogOverlay] = useState(false)
		const [openedConnectionCard, setOpenedConnectionCard] = useState(false)
		const [currentElementConnection, setCurrentElementConnection] = useState(false)
		const [visualizationToolitip, setVisualizationToolitip] = useState({
			isOpen: false,
			type: null,
			anchorEl: '',
		})
		const [connectionsArr, setConnectionsArr] = useState([])
		const {
			state: { connectionCount, currentStory, isChapterViewExpanded, rightToggleTabs, activeConnections },
			dispatch: sharedDispatch,
		} = useContext(sharedContext)
		const { reorderedPosition } = useContext(beatsDataStateContext)
		const { user } = useContext(userStateContext)
		const { isSummaryCardVisible } = useContext(systemStateContext)
		const systemDataDispatch = useContext(systemDispatchContext)
		const graphData = useContext(graphDataStateContext)
		const autoSaveDispatch = useContext(autoSaveDispatchContext)
		const {nodes } = graphData
		const beatsDataDispatch = useContext(beatsDataDispatchContext)

		const [editStory] = useEditStoryMutation()
		//const [createNode] = useCreateNodeMutation(currentElementPremise, { ignoreResults: true })
		const [resetVizCoordinates] = useResetVizCordinates({ ignoreResults: true })
		const { data: relationships } = useGetRelationshipsQuery()
		const updateMap = {}
		Object.keys(updateNodeMutations).forEach(key => {
			updateMap[key] = useUpdateNodeMutation(key, {ignoreResults:true})[0]
		})
		const createNodeMap = {}
		Object.keys(updateNodeMutations).forEach(key => {
			createNodeMap[key] = useCreateNodeMutation(key, {ignoreResults:true})[0]
		})

		const classes = useStyles()
		const history = useHistory()
		const currentElement = undefined

		// const allData = nodesByType
		// delete allData['Relationship']
		// delete allData['User']
		const totalProgressSummaryCard = useRef()
		const totalProgressElement = useRef()
		const totalProgressBeats = useRef()
		const totalProgressConn = useRef()
		const missingElements = useRef()
		const totalConnectionCount = useRef()
		const missingConnectionCount  = useRef()
		const missingBeatsConnections = useRef()
		const globalDrag = useRef(false)
		const selectedElementRef = useRef(null)
		const overlayDialogRef = useRef(null)

		// Render the graph with D3 after React has finished mounting the SVG
		// element on the DOM


		useEffect(()=>{
			

			if(activeConnections.isActive && activeConnections?.elementId !== selectedElementRef.current){
				selectedElementRef.current = nodes[activeConnections.elementId]||null
			} else{
				selectedElementRef.current = null
			}
			
		}
		,[activeConnections.elementId])

		useEffect(()=>{
			setIsOpposing(false)
			setIsSupporting(false)
			setIsUndefined(false)
		
		}
		,[selectedElementRef.current])

		const handleDriverConnections = (type = '', id, connection) => {
			setCurrentOpenedDriver({
				id,
				type,
				connection,
			})
			setIsOpenDialogOverlay(true)
		}

		useEffect(() => {
			let driverConnectionData = connectionsGroups[currentOpenedDriver?.connection]?.connections.find((d)=>d.id === currentOpenedDriver?.id);
			if (driverConnectionData?.source?.id) {
					setConnectionsArr(
						getConnections(driverConnectionData?.source.id, relationships?.relations, graphData?.nodes)
					)
			}
			
		}, [relationships?.relations, activeConnections,currentOpenedDriver,graphData?.nodes])

		useEffect(() => {
			if (true) {
				const [d3nodes, d3edges, newMissingConnections,newExistingConnections,newConnectionsGroups] = parseGraphDataForD3(
					nodes,
					currentElement,
					connectionType,
					
				)
				setMissingConnections(newMissingConnections)
				setExistingConnections(newExistingConnections)
				setConnectionsGroups(newConnectionsGroups)

			// const [d3nodes, d3edges, missingConnections, existingConnections] =
			// 	parseGraphDataForD3(nodes, currentElement, connectionType)
			
			

			const characterNode =
			!!newConnectionsGroups.Premise.nodes.find(
					element => element.__typename === 'Character'
				) 

			const arcNode =
			!!newConnectionsGroups.Premise.nodes.find(
					element => element.__typename === 'Arc'
				)

			const themeNode =
			!!newConnectionsGroups.Premise.nodes.find(
					element => element.__typename === 'Theme'
				) 

			const oneElementPremise = characterNode === true || arcNode === true || themeNode === true

			const allElementPremise = characterNode === true && arcNode === true && themeNode === true

			const twoElementPremise =
				(themeNode === true && characterNode === true) ||
				(characterNode === true && arcNode === true) ||
				(arcNode === true && themeNode === true)

			missingElements.current =
				allElementPremise === true
					? 3
					: twoElementPremise === true
					? 2
					: oneElementPremise === true
					? 1
					: 0

			totalProgressElement.current = (missingElements.current / 3) * 100 || 0

			const getMissingConnetions =(currentMissingConnections,missingElements,premiseNodesCount) => {
				let output = currentMissingConnections
				for (let i = 0; i < missingElements; i++) {

					output += premiseNodesCount + i
				}
				return output
			}

			missingConnectionCount.current = getMissingConnetions(newMissingConnections.length,3-missingElements.current,newConnectionsGroups.Premise.nodes.length)

			totalConnectionCount.current = newExistingConnections.length + missingConnectionCount.current

			const totalProgress =
				((totalConnectionCount.current - missingConnectionCount.current) / totalConnectionCount.current) * 100

			totalProgressConn.current = !Number.isNaN(totalProgress) ? Math.round(totalProgress) : 0

			const resultBeatsConnections = newExistingConnections.filter(data => {
				return !(data.beatsDriven > 0)
			})
		
			missingBeatsConnections.current = resultBeatsConnections.length + missingConnectionCount.current

			const totalProgressOfMissingBeats =
				((totalConnectionCount.current - missingBeatsConnections.current) / totalConnectionCount.current) * 100

			totalProgressBeats.current = totalProgressOfMissingBeats || 0



			totalProgressSummaryCard.current = totalProgressElement.current / 3 + totalProgressBeats.current / 3 + totalProgressConn.current / 3

			const summaryCardProgressData ={
				totalProgressSummaryCard,
				totalProgressElement,
				totalProgressBeats,
				totalProgressConn,

			}

			if (!isChapterViewExpanded) {
				// const [d3nodes, d3edges, newMissingConnections, newExistingConnections] =
				// parseGraphDataForD3(nodes, currentElement, connectionType)
				//setMissingConnections(newMissingConnections)
				//setExistingConnections(newExistingConnections)

				const tempEdges = d3edges.filter(tempData => tempData.source && tempData.target)
				// If graph contents changed (nodes or edges) re-render
				if (width && height) {
					renderGraph(
						d3nodes,
						tempEdges,
						width,
						height,
						vizNodeHover,
						history,
						createLog,
						updateMap,
						client,
						sharedDispatch,
						currentStory,
						isSupporting,
						isOpposing,
						isUndefined,
						newMissingConnections,
						newExistingConnections,
						summaryCardProgressData,
						setCurrentElementPremise,
						addNodeFunc,
						isSummaryCardVisible,
						systemDataDispatch,
						newConnectionsGroups,
						isChapterViewExpanded,
						globalDrag,
						selectedElementRef,
						beatsDataDispatch,
						handleDriverConnections,
						setVisualizationToolitip,
					)
				}
			}

	
	
	
	
	// hash(
	// 	new Set(
	// 		Object.values(nodes)
	// 			.filter(node => ['Beat'].includes(node.__typename))
	// 			?.map(node => node.driverConnections)
	// 			.flat()
	// 			.map(conn => conn?.id),
	// 	),
	// )
	
	
	

	
	

			// resetGraph is added to the dependency array to render graph after reseting position
			}
		}, [
			width,
			height,
			//nodes,
			hash(
				new Set(
					Object.values(nodes)
						.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
						?.map(node => node.staticConnections)
						.flat()
						.map(conn => conn?.id + conn?.structureTag + conn?.beatsDriven),
				),
			),
			hash(
				new Set(
					Object.values(nodes)
						.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
						?.map(node => node.name),
				),
			),
			(Object.values(nodes)
			.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
			?.map(node => {
				return node.name
			})).length,
			hash(
				new Set(
					Object.values(nodes)
						?.filter(node => !['Beat', 'Note', 'Chapter'].includes(node.__typename))
						?.map(node => node?.id + node.coreElement) // node.id + node.yCoordinate + node.xCoordinate
						.flat(),
				)
				),
			//client?.cache?.data?.data,
			connectionType,
			resetGraph,
			isSupporting,
			isOpposing,
			isUndefined,
			isSummaryCardVisible,
			currentElementPremise,
			isChapterViewExpanded,
			openedConnectionCard,
			//selectedElementRef.current?.id !==activeConnections?.elementId
		])

		// useEffect(() => {
		// 	if (!isChapterViewExpanded) setResetGraph(!resetGraph)
		// }, [isChapterViewExpanded])

		// useEffect(() => {
		// 	if (deletedNode?.deleteNode === currentElement) {
		// 		setConnectionModal(false)
		// 	}
		// }, [deletedNode, filteredConnections])

		//sets isFullscreen to false when clicking "Escape" on FullScreen mode
		const escFunction = () => {
			if (isFullscreen) {
				setIsFullscreen(false)
			}
		}

		useEffect(() => {
			document?.addEventListener('fullscreenchange', escFunction)
			document?.addEventListener('webkitfullscreenchange', escFunction)
			document?.addEventListener('mozfullscreenchange', escFunction)
			document?.addEventListener('MSFullscreenChange', escFunction)
			return () => {
				document.removeEventListener('fullscreenchange', escFunction)
				document.removeEventListener('webkitfullscreenchange', escFunction)
				document.removeEventListener('mozfullscreenchange', escFunction)
				document.removeEventListener('MSFullscreenChange', escFunction)
			}
		}, [])
		
		useEffect(() => {
			if(isSummaryCardVisible){
				document.getElementById('editableStoryName')?.focus();
			}
			
		}, [isSummaryCardVisible])

		// Measure the browser-rendered dimensions of a DOM element
		const getVizDimensions = elem => {
			if (elem) {
				const vizBoundingRect = elem.getBoundingClientRect()
				setWidth(vizBoundingRect.width)
				setHeight(vizBoundingRect.height)
				
			}
		}

		const resetButtonVisible = Object.values(nodes)
			.filter(element => element.__typename !== 'Beat' && element.__typename !== 'Chapter')
			.some(element => element.vizLocSaved === true)

		const resetViz = async () => {
			await updateCacheFieldAllNodes(client, Object.values(nodes), {
				xCoordinate: { value: null },
				yCoordinate: { value: null },
				vizLocSaved: { value: false },
			})
			// render graph after reset position
			setResetGraph(!resetGraph)

			try {
				await resetVizCoordinates()
			} catch (error) {
				console.log(error)
			}
		}

		useEffect(() => {
			const newConnectionCount = getConnectionCount(client)
			sharedDispatch(
				setConnectionCount({
					supportingCount: newConnectionCount.Supporting,
					opposingCount: newConnectionCount.Opposing,
					undefinedCount: newConnectionCount.Undefined,
					total: newConnectionCount.Total,
				}),
			)
		}, [relationships])

		const handleConnectionList = connectionType => {
			if (connectionType === 'Supporting') {
				setIsSupporting(!isSupporting)
				setIsOpposing(isOpposing && false)
				setIsUndefined(isUndefined && false)
			}
			if (connectionType === 'Opposing') {
				setIsOpposing(!isOpposing)
				setIsSupporting(isSupporting && false)
				setIsUndefined(isUndefined && false)
			}
			if (connectionType === 'Undefined') {
				setIsUndefined(!isUndefined)
				setIsSupporting(isSupporting && false)
				setIsOpposing(isOpposing && false)
			}
			selectedElementRef.current = null
		}

		useEffect(() => {
			const currentElements = graphData?.nodesByType?.[currentElementPremise] || []
			const elements = currentElements.map(element => {
				return { ...element, order: +element.order }
			})
			setFilteringData(orderBy(elements, ['order'], ['asc']))
		}, [graphData?.nodesByType, currentElementPremise])

		// display updated position before getting response from backend while reorder elements
		useEffect(() => {
			if (
				reorderedPosition?.type === 'element' &&
				reorderedPosition.source.index !== reorderedPosition.destination.index
			) {
				setFilteringData(
					arrayMove(
						filteringData,
						reorderedPosition.source.index,
						reorderedPosition.destination.index,
					),
				)
				beatsDataDispatch(elementReordered())
			}
		}, [reorderedPosition])

		// useEffect(()=>{

		// 	return (() =>{ 
		// 		if(window.location.href.includes("/story")){
		// 			console.log("Is this is")
		// 			client.refetchQueries({
		// 				include: "active",
		// 			  });

		// 		}
				
		// 	})

		// },[])

		// useEffect(() => {
		// 	if (isNodeCreated) {
		// 		;(async () => {
		// 			const mutationData = []

		// 			filteringData.forEach(node => {
		// 				mutationData.push({
		// 					id: node.id,
		// 					order: String(node.order + 1),
		// 				})
		// 			})

		// 			await reorderNodes({
		// 				variables: {
		// 					nodeType: currentElementPremise,
		// 					newNodeOrder: mutationData,
		// 				},
		// 			})
		// 				.then(res => {
		// 					if (res && serverError) {
		// 						beatsDataDispatch(setServerError(false))
		// 						clearInterval(serverErrorInterval.current)
		// 					}
		// 				})
		// 				.catch(async err => {
		// 					if (err.message.includes('Cannot update')) {
		// 						beatsDataDispatch(setNoElementError(true))
		// 					}
		// 					if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
		// 						beatsDataDispatch(setServerError(true))
		// 					}
		// 				})
		// 			setNodeCreated(false)
		// 		})()
		// 	}
		// }, [isNodeCreated])

		const addNodeFunc = newNode => {
			throttle(() => {

				// const elementName = {
				// 	Character: 'Main Character',
				// 	Arc: 'Main Plot',
				// 	Theme: 'Central Theme',
				// }
				const tempObj = {
					id: createNodeId(newNode.elementType),
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					order: '0',
					__typename: newNode.elementType,
					name: newNode.name,
					description: '',
					beatConnections: [],
					driverConnections: [],
					childConnections: [],
					staticConnections: [],
					noteConnections: [],
					xCoordinate: null,
					yCoordinate: null,
					vizLocSaved: null,
					firstBeat: '',
					coreElement: newNode.coreElement,
					storyText: null,
				}
				const optimisticResponse = {}
				optimisticResponse[`create${newNode.elementType}`] = tempObj

				sharedDispatch(
					setActiveConnections({ isActive: true, elementId: tempObj.id, elementType: tempObj.__typename }),
				)

				createNodeMap[newNode.elementType]({
					node: {
						id: tempObj.id,
						name: newNode.name,
						description: '',
						order: '0',
						coreElement: newNode.coreElement,
					},
					optimisticResponse,
					tempObj

				})					
			})
		}

		const handleStoryNameChange = debounce(e => {
			if (e.target.value !== currentStory?.name) {
				autoSaveDispatch(setSavingData(true))
				updateStory({name: e.target.value})
			}
		}, 1000)

		const handleStoryDescriptionChange = debounce(e => {
			if (e.target.value !== currentStory?.description) {
				autoSaveDispatch(setSavingData(true))
				updateStory({description: e.target.value})
			}
		}, 1000)

		const { quill, quillRef } = useQuill({
			modules: {
				toolbar: false,
				history: {
					delay: 1000,
					maxStack: 500,
					userOnly: true,
				},
				
			},
			formats: [
				'font',
				'header',
				'size',
				'color',
				'align',
				'background',
				'bold',
				'italic',
				'underline',
				'strike',
				'blockquote',
				'list',
				'indent',
				'link',
			],
		})

		const handleDeleteStory = () => {
			createLog('Story Deletion Attempted', `{"workflowStep":${1}}`, 'Navbar', 'Story Deletion')
			sharedDispatch(deleteCurrentStory(true))
		}

		const exportStoryHandler = async () => {
			sharedDispatch(setIsExporting(true))
			createLog('Story export Attempted', `{"workflowStep":${1}}`, 'Summary', 'Story Export')
			await buildStoryQuill(
				quill,
				graphData,
				currentStory?.name ? currentStory?.name : 'My story',
			)
				.then(() => {
					createLog('Story export success', `{"workflowStep":${1}}`, 'Summary', 'Story Export')
					setTimeout(() => sharedDispatch(setIsExporting(false)), 2000)
					exportData(quill.getContents(), `Outline - ${currentStory?.name}`).catch(err => {
						createLog(
							'Story export failed',
							`{"workflowStep":${1}, "errorMessage":"${err.message}"}`,
							'Summary',
							'Story Export',
						)
						setTimeout(() => sharedDispatch(setIsExporting(false)), 1000)
						toastHandler('error', 'Export failed')
						createLog(`Export Story`, `{"errorMessage":"Export failed"}`, 'Summary', 'Export Story')
					})
				})
				.catch(e => {
					createLog(
						'Story export failed',
						`{"workflowStep":${1},"errorMessage":"${e.message}"}`,
						'Summary',
						'Story Export',
					)
					setTimeout(() => sharedDispatch(setIsExporting(false)), 1000)
					toastHandler('error', 'Export failed')
					createLog(`Export Story`, `{"errorMessage":"Export failed"}`, 'Summary', 'Export Story')
				})
			await buildStoryTextQuill(quill, graphData, 'Chapter')
				.then(() => {
					createLog('Story export success', `{"workflowStep":${1}}`, 'Summary', 'Story Export')
					setTimeout(() => sharedDispatch(setIsExporting(false)), 2000)
					exportData(quill.getContents(), 'Story Text').catch(e => {
						createLog(
							'Story export failed',
							`{"workflowStep":${1},"errorMessage":"${e.message}"}`,
							'Summary',
							'Story Export',
						)
						setTimeout(() => sharedDispatch(setIsExporting(false)), 1000)
						toastHandler('error', 'Export failed')
						createLog(
							`ToastHandler Error Message`,
							`{"errorMessage":"${e.message}"}`,
							'Summary',
							'Export Story',
						)
					})
				})
				.catch(e => {
					createLog(
						'Story export failed',
						`{"workflowStep":${1},"errorMessage":"${e.message}"}`,
						'Summary',
						'Story Export',
					)
					setTimeout(() => sharedDispatch(setIsExporting(false)), 1000)
					toastHandler('error', 'Export failed')
					createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${e.message}"}`,
						'Summary',
						'Export Story',
					)
				})
		}

		const { quill: descriptionQuill, quillRef: descriptionQuillRef } = useQuill({
			modules: {
				toolbar: false,
				history: {
					delay: 1000,
					maxStack: 500,
					userOnly: true,
				},
			},
			// placeholder: 'Write your story summary',
		})

		const updateStory = async (input) => {
			await editStory({
				variables: {
					id: currentStory?.id,
					input
				},
			})
				.then(() => {
					autoSaveDispatch(setSavingData(false))
				})
				.catch(e => {
					autoSaveDispatch(setSavingData(false))
					if (e.message.includes('subscription')) {
						toastHandler('error', e.message)
						createLog(
							`ToastHandler Error Message`,
							`{"errorMessage":"${e.message}"}`,
							'Summary',
							'Story Update',
						)
						if (
							user?.subscriptionStatus?.status === 'trialing' ||
							user?.subscriptionStatus?.status === 'active'
						) {
							window.location.reload()
						}
					} else {
						toastHandler('error', e.message)
						createLog(
							`ToastHandler Error Message`,
							`{"errorMessage":"${e.message}"}`,
							'Summary',
							'Story Update',
						)
					}
				})
		}

		useEffect(() => {
			if (descriptionQuill && descriptionQuill?.getText() !== currentStory?.description) {
				const tempQuill = descriptionQuill.getSelection()
				if (currentStory?.description) {
					descriptionQuill.setText(currentStory?.description)
					descriptionQuill.setSelection(tempQuill)
				} else {
					descriptionQuill.setText('')
				}
			}
		}, [descriptionQuill, currentStory?.description])

		
		
		// const toggleFullscreen = async () => {
		// 	if (!fullscreenHandle2.active) {
		// 		setIsFullscreen(true)
		// 		await fullscreenHandle2?.enter()
		// 	}
		// 	if (fullscreenHandle2.active) {
		// 		setIsFullscreen(false)
		// 		await fullscreenHandle2?.exit()
		// 	}
		// }

		const handleSummaryCardClose = (ring) => {
			//document.querySelector('.currentStoryName').innerHTML = `${currentStory?.name}`
			// document.querySelector(`.${ringName}ProgressPercentage`).innerHTML = `${
			// 	!Number.isNaN(totalProgressSummaryCard) ? Math.round(totalProgressSummaryCard) : 0
			// }% Complete`
			systemDataDispatch(setSummaryCardVisible(false))
			createLog('Visualization Summary Card Closed', '{}', 'StoryViz', 'Summary Card Closed')
		}

		const renderDriverComponent = ()=> {
			if (!currentOpenedDriver?.type) return null;
		
			let driverConnectionData = connectionsGroups[currentOpenedDriver?.connection]?.connections.find((d)=>d.id === currentOpenedDriver?.id);
		
			switch (currentOpenedDriver?.type) {
			  case 'missingPremiseConnection':
			  	let missingDriverConnectionData = {...currentOpenedDriver?.id}
				return (
				  <MissingPremiseDriver
					key={missingDriverConnectionData?.target?.id}
					id={missingDriverConnectionData?.target?.id}
					text={missingDriverConnectionData?.target?.name}
					handleClick={(event, connectionModalType = { type: 'Opposing' }) => {
					  setConnectionModal(event);
					  setConnectionModalType(connectionModalType);
					  setCurrentElementConnection({ ...missingDriverConnectionData?.source });
					}}
					connectionModalType={{
					  type: missingDriverConnectionData?.target?.__typename,
					  recommendedDriverId: missingDriverConnectionData?.target?.id,
					  isRecommendedDriver: true,
					}}
					referenceElement={missingDriverConnectionData?.source}
					type={'visualization'}
				  />
				);
			  case 'missingStructureTagConnection':
				return (
				  <MissingStructureTagDriver
					key={driverConnectionData?.target?.id}
					id={driverConnectionData?.target?.id}
					type={driverConnectionData?.target?.__typename}
					text={driverConnectionData?.target?.name}
					handleClick={(event, connectionModalType = { type: 'Opposing' }) => {
					  setConnectionModal(event);
					  setConnectionModalType(connectionModalType);
					  setCurrentElementConnection({ ...driverConnectionData?.source });
					}}
					connectionsArr={connectionsArr}
					handleClose={()=>{
						setIsOpenDialogOverlay(false);
						setCurrentOpenedDriver(null);
						setOpenedConnectionCard(false);
					}}
					connectionModalType={{
					  type: driverConnectionData?.target?.__typename,
					  recommendedDriverId: driverConnectionData?.target?.id,
					}}
					recommendedDriver={driverConnectionData}
					referenceElement={driverConnectionData?.source}
				  />
				);
			  case 'connectionCard':
				if(connectionsArr?.all){
					let [driverData] = connectionsArr?.all.filter((connection)=>connection?.destNode.id===driverConnectionData?.target?.id)
	
					return (
						driverData ? <NewConnectionCard
						key={driverData?.id}
						isDefaultOpened={true}
						id={driverData?.id}
						name={driverConnectionData?.target?.name}
						type={driverConnectionData?.target?.__typename}
						structureTag={driverData?.structureTag}
						beatsDriven={driverData?.beatsDriven}
						sourceNode={driverConnectionData?.sourceNode}
						description={driverData?.description}
						firstBeat={driverData?.firstBeat}
						disableHoverListener={true}
						createLog={createLog}
						handleClose={() => {
						  setIsOpenDialogOverlay(false);
							setCurrentOpenedDriver(null);
							setOpenedConnectionCard(false);
						}}
						sharedBeats={driverData?.sharedBeats}
						referenceElement={driverConnectionData?.source}
						destNode={driverConnectionData?.target}
						isNewStructureTag={false}
						setOpenedConnectionCard={setOpenedConnectionCard}
						openedConnectionCard={openedConnectionCard}
					  /> :<></>
					);
				}
				
			  default:
				return null;
			}
		}
		  
		

		const MissingPremiseRelatioshipTooltip = () => {
			return (
				<TooltipWrapper dashed>
					<TooltipLabel>This Premise relationship is missing</TooltipLabel>
					<TooltipDescription>
						Click <img src={suggestionIcon} width={'14px'} height={'14px'} /> to create the
						relationship{' '}
					</TooltipDescription>
				</TooltipWrapper>
			)
		}

		const MissingStructureTagTooltip = () => {
			return (
				<TooltipWrapper dashed>
					<TooltipLabel>Missing relationship type</TooltipLabel>
					<TooltipDescription>
						Click <img src={missingStructureTagIcon} width={'14px'} height={'14px'} /> to select the
						relationship type{' '}
					</TooltipDescription>
				</TooltipWrapper>
			)
		}

		return (
			<StyledFullscreen
				handle={fullscreenHandle2}
				isFullscreen={fullscreenHandle2.active}
				className="v1"
			>
				<OutsideClickHandler
					className="dskd"
					onOutsideClick={() => {
						// setConnectionModal(false)
					}}
					width={'100%'}
					height={'100%'}
				>
					<VizContainer
						className="tour-step-3"
						isFullscreen={fullscreenHandle2.active}
						ref={overlayDialogRef}
					>
						<ConnectionListContainer>
							<ConnectionListTitle>
								<img src={visIcon} />
								<div>
									Relationships:  
									<div className="total-connection-count">{connectionCount.total}</div>
								</div>
							</ConnectionListTitle>
							<ConnectionListPanel>
								<ConnectionListCurveArrow>
									<img src={curveArrowIcon} />
								</ConnectionListCurveArrow>
								<ConnectionListItem
									isSupporting={isSupporting}
									onClick={() => {
										handleConnectionList('Supporting')
									}}
								>
									<img src={supportingIcon} />
									<div>
										Supporting:
										<div className="connetion-count">{connectionCount.supportingCount}</div>
									</div>
								</ConnectionListItem>
							</ConnectionListPanel>
							<ConnectionListPanel>
								<ConnectionListCurveArrow>
									<img src={curveArrowIcon} />
								</ConnectionListCurveArrow>
								<ConnectionListItem
									isOpposing={isOpposing}
									onClick={() => {
										handleConnectionList('Opposing')
									}}
								>
									<img src={opposingIcon} />
									<div>
										Opposing:
										<div className="connetion-count">{connectionCount.opposingCount}</div>
									</div>
								</ConnectionListItem>
							</ConnectionListPanel>
							{connectionCount.undefinedCount > 0 ? (
								<ConnectionListPanel>
									<ConnectionListCurveArrow>
										<img src={curveArrowIcon} />
									</ConnectionListCurveArrow>
									<ConnectionListItem
										isUndefined={isUndefined}
										onClick={() => {
											handleConnectionList('Undefined')
										}}
									>
										<img src={missingStructureTagIcon} />
										<div>
											Undefined:
											<div className="connetion-count">{connectionCount.undefinedCount}</div>
										</div>
									</ConnectionListItem>
								</ConnectionListPanel>
							) : (
								<div></div>
							)}
						</ConnectionListContainer>
						{isSummaryCardVisible ? (
							<SummaryCardContainer
								missingElementSuggestion={missingElementSuggestion === true}
								missingConnSuggestion={missingConnSuggestion === true}
								missingBeatConnection={missingBeatConnection === true}
								width={width}
								summaryCardVisible={isSummaryCardVisible}
								id="summaryCard"
							>
								<div ref={quillRef} style={{ display: 'none' }} />
								<CloseSummaryCard onClick={() => handleSummaryCardClose()}>
									<img src={closeIcon} alt="close-summary-card" />
								</CloseSummaryCard>

								<StoryNameListItem>
									<input
										id={'editableStoryName'}
										className="summaryCardStoryName"
										defaultValue={currentStory?.name || ''}
										onChange={handleStoryNameChange}
										onBlur={async e => {
											//await updateStory(e.target.value, currentStory.description)
											if (e.target.value !== currentStory?.name) {
												updateCacheField(
													client,
													{ id: currentStory.id, __typename: 'Story' },
													{ name: e.target.value },
												)
											}
										}}
									/>
									<img
										src={editIcon}
										alt="edit-icon"
										width="8px"
										height="7px"
										onClick={() => {
											document.getElementById('editableStoryName')?.focus()
										}}
									/>
								</StoryNameListItem>

								<StorySummaryListItem>
									<div className="summaryCardSummary">Summary</div>
									{/* <img src={editIcon} alt="edit-icon" width="8px" height="7px" /> */}
								</StorySummaryListItem>

								<StorySummaryBox>
									{/* <div ref={descriptionQuillRef} style={{ display: 'none' }} /> */}
									<textarea
										rows={5}
										defaultValue={currentStory?.description || ''}
										onChange={handleStoryDescriptionChange}
										onBlur={async e => {
											if (e.target.value !== currentStory?.description) {
												updateCacheField(
													client,
													{ id: currentStory.id, __typename: 'Story' },
													{ description: e.target.value },
												)
											}
										}}
										placeholder="Write your story summary"
									/>
								</StorySummaryBox>

								<ListIcon>
									<Tooltip
										title={'Export'}
										classes={{ tooltip: classes.tooltip }}
										placement="top"
										PopperProps={{
											modifiers: [
												{
													name: 'offset',
													options: {
														offset: [0, -5],
													},
												},
											],
										}}
									>
										<IconContainer onClick={exportStoryHandler}>
											<img src={exportIcon} alt="Export icon" />
										</IconContainer>
									</Tooltip>
									<Tooltip
										title={'Delete'}
										classes={{ tooltip: classes.tooltip }}
										placement="top"
										PopperProps={{
											modifiers: [
												{
													name: 'offset',
													options: {
														offset: [0, -5],
													},
												},
											],
										}}
									>
										<IconContainer onClick={handleDeleteStory}>
											<img src={trashIcon} alt="Delete icon" />
										</IconContainer>
									</Tooltip>
								</ListIcon>

								<PremiseRingData>
									<div className="premiseTooltip">{String(isSummaryCardVisible).toUpperCase()}</div>
									<SuggestionTooltip
										title="Premise elements should drive each others' beats."
										placement="bottom"
										arrow
									>
										<img
											src={premiseRingTooltipIcon}
											alt="premise-tooltip"
											width="12px"
											height="12px"
										/>
									</SuggestionTooltip>
								</PremiseRingData>

								<MissingElementContainer>
									<div className="missingElementProgress">
										<img src={missingElementIcon} alt="missingElement" width="17px" />
										<div className="missingElementProgressItem">
											<Title>
												<div className="left">
													<span className="title">Add Premise Elements:</span>
												</div>

												{missingElements.current === 3 ? (
													<div></div>
												) : (
													<span className="status">
														<b>{missingElements.current}/3</b> &nbsp;Completed
													</span>
												)}
											</Title>

											{
												<BorderLinearProgress
													variant="determinate"
													value={totalProgressElement.current || 0}
													barcolor="#FFC300"
													primarycolor="#F1F1F1"
													height={6}
												/>
											}
										</div>
									</div>
									<div className="missingElementText">
										A story's Premise should have at least a Character, Arc, and TSM.
									</div>
									<SummaryCardSuggestion
										onClick={() => {
											setMissingBeatConnection(false)
											setMissingConnSuggestion(false)
											setMissingElementSuggestion(!missingElementSuggestion)
										}}
									>
										<div className="suggestionText">How to create an element</div>
										{missingElementSuggestion === true ? (
											<img src={downIcon} alt="down" width="10px" height="10px" />
										) : (
											<img src={upIcon} alt="down" width="10px" height="10px" />
										)}
									</SummaryCardSuggestion>

									{missingElementSuggestion === true ? (
										<MissingConnectionSuggestion
											missingElementSuggestion={missingElementSuggestion === true}
											missingConnSuggestion={missingConnSuggestion === true}
											missingBeatConnection={missingBeatConnection === true}
										>
											<img src={missingElementSuggestion1} alt="missing-ele-step1" />
											<img src={missingElementSuggestion2} alt="missing-ele-step2" />
											<img src={missingElementSuggestion3} alt="missing-ele-step3" />
											{/* <SummaryCardWatchVideos
												missingElementSuggestion={missingElementSuggestion === true}
												missingConnSuggestion={missingConnSuggestion === true}
											>
												<img src={watchVideosIcon} alt="watch-videos" width="49px" height="18px" />
											</SummaryCardWatchVideos> */}
										</MissingConnectionSuggestion>
									) : (
										<div></div>
									)}
								</MissingElementContainer>

								<MissingConnectionsContainer
									missingElementSuggestion={missingElementSuggestion === true}
								>
									<div className="missingConneProgress">
										<img src={missingConnectionIcon} alt="missingConnections" width="17px" />
										<div className="missingConnProgressItem">
											<Title>
												<div className="left">
													<span className="title">Add Drivers:</span>
												</div>
												{missingConnectionCount.current === 0 ? (
													<div></div>
												) : (
													<span className="status">
														<b>
															{totalConnectionCount.current - missingConnectionCount.current}/
															{totalConnectionCount.current}
														</b>{' '}
														&nbsp;Completed
													</span>
												)}
											</Title>
											{totalConnectionCount.current === 0 ? (
												<div></div>
											) : (
												<BorderLinearProgress
													variant="determinate"
													value={totalProgressConn.current || 0}
													barcolor="#FFC300"
													primarycolor="#F1F1F1"
													height={6}
												/>
											)}
										</div>
									</div>
									<div className="missingConnectionText">
										Premise elements should be drive every other Premise element
									</div>
									<SummaryCardSuggestion
										onClick={() => {
											setMissingBeatConnection(false)
											setMissingConnSuggestion(!missingConnSuggestion)
											setMissingElementSuggestion(false)
										}}
									>
										<div className="suggestionText">How to add a Driver</div>
										{missingConnSuggestion === true ? (
											<img src={downIcon} alt="down" width="10px" height="10px" />
										) : (
											<img src={upIcon} alt="down" width="10px" height="10px" />
										)}
									</SummaryCardSuggestion>

									{missingConnSuggestion === true ? (
										<MissingConnectionSuggestion
											missingElementSuggestion={missingElementSuggestion === true}
											missingConnSuggestion={missingConnSuggestion === true}
										>
											<img src={missingConnSuggestion1} alt="missing-conn-step1" />
											<img src={missingConnSuggestion2} alt="missing-conn-step2" />
											<img src={missingConnSuggestion3} alt="missing-conn-step3" />
											{/* <SummaryCardWatchVideos
												missingElementSuggestion={missingElementSuggestion === true}
											>
												<img src={watchVideosIcon} alt="watch-videos" width="49px" height="18px" />
											</SummaryCardWatchVideos> */}
										</MissingConnectionSuggestion>
									) : (
										<div></div>
									)}
								</MissingConnectionsContainer>

								<MissingBeatConnectionContainer
									missingElementSuggestion={missingElementSuggestion === true}
									missingConnSuggestion={missingConnSuggestion === true}
								>
									<div className="missingConneProgress">
										<img src={missingBeatIcon} alt="missingBeat" width="17px" />
										<div className="missingConnProgressItem">
											<Title>
												<div className="left">
													<span className="title">Drive the Same Beats:</span>
												</div>
												{totalProgressBeats.current === 100 ? (
													<div></div>
												) : (
													<span className="status">
														<b>
															{totalConnectionCount.current - missingBeatsConnections.current}/
															{totalConnectionCount.current}
														</b>{' '}
														&nbsp;Completed
													</span>
												)}
											</Title>
											{totalConnectionCount.current === 0 ? (
												<div></div>
											) : (
												<BorderLinearProgress
													variant="determinate"
													value={totalProgressBeats.current || 0}
													barcolor="#FFC300"
													primarycolor="#F1F1F1"
													height={6}
												/>
											)}
										</div>
									</div>
									<div className="missingConnectionText">
										Add boths elements as drivers to a beat
									</div>
									<SummaryCardMissingBeatSuggestion
										onClick={() => {
											setMissingBeatConnection(!missingBeatConnection)
											setMissingConnSuggestion(false)
											setMissingElementSuggestion(false)
										}}
									>
										<div className="suggestionText">How to drive a beat</div>
										{missingBeatConnection === true ? (
											<img src={downIcon} alt="down" width="10px" height="10px" />
										) : (
											<img src={upIcon} alt="down" width="10px" height="10px" />
										)}
									</SummaryCardMissingBeatSuggestion>

									{missingBeatConnection === true ? (
										<MissingConnectionSuggestion
											missingBeatConnection={missingBeatConnection === true}
										>
											<img src={missingBeatSuggestion1} alt="missing-beat-step1" />
											<img src={missingBeatSuggestion2} alt="missing-beat-step2" />
											<img src={missingBeatSuggestion3} alt="missing-beat-step3" />
											<img src={missingBeatSuggestion4} alt="missing-beat-step4" />
											{/* <SummaryCardWatchVideos
												missingBeatConnection={missingBeatConnection === true}
											>
												<img src={watchVideosIcon} alt="watch-videos" width="49px" height="18px" />
											</SummaryCardWatchVideos> */}
										</MissingConnectionSuggestion>
									) : (
										<div></div>
									)}
								</MissingBeatConnectionContainer>
							</SummaryCardContainer>
						) : (
							<div></div>
						)}
						{connectionModal && connectionModalType && (
							<CreateConnectionModal
								mainElementId={currentElementConnection?.id}
								mainElementType={currentElementConnection?.__typename}
								isOpenStructureTagModal={connectionModal}
								setStructureTagConnnectionModal={setConnectionModal}
								setConnectionModalType={setConnectionModalType}
								connectionModalType={connectionModalType}
								handleClose={({id}) => {
									setConnectionModal(false)
									if(id){
										setCurrentOpenedDriver((props)=>{											
											return {
												...props,
												id: id,
												type: 'connectionCard',
											}
										})
									}
								}}
								type={'visualization'}
								setIsNewStructureTag={() => {}}
							/>
						)}
						<Box>
							{overlayDialogRef.current && (
								<ContentDialog
									overlayDialogRef={overlayDialogRef}
									open={isOpenDialogOverlay}
									handleClose={() => {
										setIsOpenDialogOverlay(false);
										setCurrentOpenedDriver(null);
										setOpenedConnectionCard(false);
									}}
								>
									{renderDriverComponent()}
								</ContentDialog>
							)}
						</Box>
						{false ? (
							<DeepModeContainer
								ClassName="patent"
								isFullscreen={fullscreenHandle2.active}
								isStaticViz={true}
								onClick={() => {
									setConnectionModal(false)
									createLog(
										'Connection Creation Cancelled',
										`{"workflowStep":${2},"connectionType":"Static"}`,
										'StoryViz',
										'Connection Creation',
									)
								}}
							>
								<VizContent
									ref={getVizDimensions}
									id="graph-viz"
									className="graph-viz"
									isFullscreen={fullscreenHandle2.active}
								>
									{/* <g className="premiseRing" />
										<g className="missinglinks" />
										<g className="premiseLinks" /> */}
									<g>
										{Object.keys(connectionsGroups)
											.reverse()
											.map(ringName => {
												return (
													<g key={`${ringName}RingGroup`} className={`${ringName}RingGroup`}>
														<g key={`${ringName}Ring`} className={`${ringName}Ring`} />
														<g
															key={`${ringName}MissingLinks`}
															className={`${ringName}MissingLinks`}
														/>
														<g key={`${ringName}Links`} className={`${ringName}Links`} />
													</g>
												)
											})}
										<g className="nodes" />
									</g>
								</VizContent>
							</DeepModeContainer>
						) : (
							<VizContent
								ref={getVizDimensions}
								id="graph-viz"
								className="graph-viz"
								isFullscreen={fullscreenHandle2.active}
							>
								<g>
									{Object.keys(connectionsGroups)
										.reverse()
										.map(ringName => {
											return (
												<g key={`${ringName}RingGroup`} className={`${ringName}RingGroup`}>
													<g key={`${ringName}Ring`} className={`${ringName}Ring`} />
													<g
														key={`${ringName}MissingLinks`}
														className={`${ringName}MissingLinks`}
													/>
													<g key={`${ringName}Links`} className={`${ringName}Links`} />
												</g>
											)
										})}
									<g className="nodes" />
								</g>
							</VizContent>
						)}
						<Popover
							anchorEl={visualizationToolitip.anchorEl}
							open={!!visualizationToolitip.isOpen}
							disableAutoFocus
							onClose={() => {
								setVisualizationToolitip()
							}}
							disableRestoreFocus
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							sx={{
								pointerEvents: 'none',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
							classes={{ paper: classes.root }}
						>
							{visualizationToolitip?.type === 'premise' ? (
								<MissingPremiseRelatioshipTooltip />
							) : (
								<MissingStructureTagTooltip />
							)}
						</Popover>
						{resetButtonVisible === true ? (
							<Tooltip
								title={'Reset Visualization'}
								classes={{ tooltip: classes.tooltip }}
								placement="top"
								PopperProps={{
									modifiers: [
										{
											name: 'offset',
											options: {
												offset: [0, -10],
											},
										},
									],
								}}
							>
								<ResetBtn onClick={resetViz}>
									<img src={ResetIcon} alt="reset icon" width="16px" />
									<div>Reset</div>
								</ResetBtn>
							</Tooltip>
						) : (
							<></>
						)}
						{/* <Bar isBottom isModalOpen={connectionModal} isFullscreen={fullscreenHandle2.active} /> */}
					</VizContainer>
				</OutsideClickHandler>
			</StyledFullscreen>
		)
	},
	equivalentGraph,
)

export default withStyles(muiStyles)(VizDataWrapper)
