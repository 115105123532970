import styled from 'styled-components'
import { getNodeIcon, vizTitleLengthFormat } from '@lynit/shared/src/utils/utils'
import {
	Connection,
	ElementCardContainer,
	ElementName,
	ElementNameLable,
	IconContainer,
	TooltipDescription,
	TooltipLabel,
	TooltipWrapper,
} from './styles'
import missingStructureTagIcon from '@lynit/shared/src/images/missingStructureTag.svg'
import missingStructureTagConnectionIcon from '@lynit/shared/src/images/missing-structuretag-icon.svg'
import { makeStyles } from '@mui/styles'
import HtmlTooltip from '@lynit/shared/src/ui/ToolTip/HtmlTooltip'
import { useContext, useEffect, useRef } from 'react'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { useDeleteConnectionMutation, useGetRelationshipsQuery } from '@lynit/shared/src/hooks'
import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import deleteIcon from '@lynit/shared/src/images/deleteIcon.svg'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import getConnections from '@lynit/shared/src/utils/getConnections'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'

const MissingStructureTagDriver = ({
	text,
	handleClick,
	connectionModalType,
	referenceElement,
	id,
	connectionsArr,
	recommendedDriver,
	handleClose=()=>{}
}) => {
	const client = useApolloClient()
	const { createLog } = useContext(systemStateContext)
	const getShortName = name => {
		return vizTitleLengthFormat(name, 11)
	}
	const modalRef = useRef()
	const graphData = useContext(graphDataStateContext)
	const { data: relationships } = useGetRelationshipsQuery()
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
	const [currentElement, setCurrentElement] = useState()


	const useStyles = makeStyles(() => ({
		tooltip: {
			marginLeft: 0,
			background: 'transparent',
		},
		tooltipSm: {
			margin: '0 auto',
			background: 'transparent',
			width: '100%',
			padding: 0,
			marginTop: '6px !important',
			margin: '6px 0',
		},
	}))

	useEffect(() => {
		if (modalRef) {
			if (!recommendedDriver) {
				// let recommendedDriver = {
				// 	id: connectionsArr?.all.find(connection => connection.destNode.id === id)?.id,
				// }
				// handleClick(
				// 	modalRef.current,
				// 	(connectionModalType = {
				// 		...connectionModalType,
				// 		recommendedDriver,
				// 	}),
				// )
			} else {

				handleClick(
					modalRef.current,
					(connectionModalType = {
						...connectionModalType,
						recommendedDriver,
					}),
				)
			}
		}
	}, [modalRef])

	const removeConnection = async connToDelete => {
		let elementToDelete

		elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
		await performDeleteConnectionMutation({
			id: connToDelete?.__typename !== 'Note' ? connToDelete?.id : connToDelete?.id,
			client,
			elementToDelete,
			isCreateCacheConnection: true,
		})
		handleClose()
	}
	const classes = useStyles()
	return (
		<HtmlTooltip
			title={
				<img
					src={deleteIcon}
					onClick={event => {
						event.stopPropagation()
						if (!JSON.stringify(id)?.includes('temp')) {
							
							
							setCurrentElement({ id: id, type: 'Relationship' })
							createLog(
								'Connection Deletion Attempted',
								`{"workflowStep":${1},"connectionType":"Static"}`,
								'StoryViz',
								'Connection Deletion',
							)
						}
					}}
				/>
			}
			placement="left"
		>
			<ConnectionContainer
				id={`connectionCard`}
				onMouseEnter={() =>
					createLog(
						'Relationship Hover',
						`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}"}`,
						'Missing Structure Tag Driver',
						null,
					)
				}
				
			>
				<ElementCardContainer>
					<div>
						<HtmlTooltip
							title={
								<TooltipWrapper>
									<TooltipLabel>Missing relationship type</TooltipLabel>
									<TooltipDescription>
										Click <img src={missingStructureTagIcon} width={'14px'} height={'14px'} /> to
										select the relationship type{' '}
									</TooltipDescription>
								</TooltipWrapper>
							}
							placement={'bottom'}
							classes={{	
								tooltip: classes.tooltipSm,
							}}
							ref={modalRef}
							onClick={e => {
								if (!recommendedDriver) {
									let recommendedDriver = {
										id: connectionsArr?.all.find(connection => connection.destNode.id === id)?.id,
									}
									handleClick(
										e.currentTarget,
										(connectionModalType = {
											...connectionModalType,
											recommendedDriver,
										}),
									)
								} else {

									handleClick(
										e.currentTarget,
										(connectionModalType = {
											...connectionModalType,
											recommendedDriver,
										}),
									)
								}
			
								createLog(
									'Relationship Clicked',
									`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}"}`,
									'Missing Structure Tag Driver',
									null,
								)
							}}
						>
							<ConnectionCardContainer>
								<ElementName>
									<IconContainer isSelected={true} color={referenceElement.__typename}>
										<img src={getNodeIcon(referenceElement?.__typename)} />
									</IconContainer>{' '}
									<ElementNameLable>{getShortName(referenceElement?.name)}</ElementNameLable>
								</ElementName>
								<Connection>
									<img src={missingStructureTagConnectionIcon} width={'100%'} height={'36px'} />
								</Connection>
								<ElementName>
									<IconContainer isSelected={true} color={connectionModalType?.type}>
										<img src={getNodeIcon(connectionModalType?.type)} />
									</IconContainer>{' '}
									<ElementNameLable>{getShortName(text)}</ElementNameLable>
								</ElementName>
							</ConnectionCardContainer>
						</HtmlTooltip>
					</div>
				</ElementCardContainer>
				{currentElement?.id && (
					<DeleteModal
						elementType={currentElement?.type?.toLowerCase()}
						nodeName={`${currentElement?.name}`}
						isShowed={currentElement}
						details=" and its beats that don't have another driver will be removed too"
						isConnection={currentElement?.type === 'Beat' || currentElement?.type === 'Relationship'}
						acceptHandler={async () => {
							if (currentElement.type === 'Relationship') {
								removeConnection(currentElement)

								createLog(
									'Connection Deletion Confirmed',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							}
							setCurrentElement()
						}}
						closeHandler={() => {
							if (currentElement.type === 'Relationship') {
								createLog(
									'Connection Deletion Cancelled',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							} else {
								createLog(
									'Element Deletion Cancelled',
									`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
									'ElementList',
									'Element Deletion',
								)
							}
							setCurrentElement()
						}}
					/>
				)}
			</ConnectionContainer>
		</HtmlTooltip>
	)
}

export default MissingStructureTagDriver

const ConnectionContainer = styled.div`
	border-radius: 4px;
	cursor: pointer;
`

const ConnectionCardContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
