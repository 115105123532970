import styled from 'styled-components'

export const StyledBreadcrumb = styled.div`
	font-size: 12px;
	color: #666666;
	font-weight: 600;
	${({ isActive }) =>
		!isActive
			? `
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	`
			: ''}
	display: flex;
	align-items: center;
`
