import styled from 'styled-components'

const Overlay = styled.div`
	display: ${props => (props.isShow ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2) !important;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999999;
`

const ModalContainer = styled.div`
	background: #fcfcfc;
	border: 1px solid #e3e3e3;
	box-sizing: border-box;
	border-radius: 5px;
	height: auto;
	position: relative;
	padding: 20px 40px 20px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	& .title {
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 25px;
		color: #555555;
		margin-bottom: 12px;
	}

	& .content {
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		color: #4a4a4a;
		margin-bottom: 20px;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`

const CloseButton = styled.button`
	position: absolute;
	height: 11px;
	width: 11px;
	left: 454px;
	top: 30px;
	border-radius: 0px;
	appearance: none;
	border: none;
	background: none;
	background: url(./close_icon.svg);
	cursor: pointer;
	outline: none;
`

const Button = styled.button`
	box-sizing: border-box;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.0777287);
	border-radius: 5px;
	height: 50px;
	width: 170px;
	border: 1px solid #5c5c5c;
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
	margin-top: 10px;
`

const CancelButton = styled(Button)`
	background: #fcfcfc;
	color: #5c5c5c;
`

const AcceptButton = styled(Button)`
	background: #5c5c5c;
	color: #ffffff;
`

const ButtonContainer = styled.div`
	display: flex;
	width: 105%;
	height: auto;
	justify-content: space-evenly;
	flex-direction: row;
	align-items: center;
	@media (max-width: 500px) {
		width: 110%;
		flex-direction: column;
	}
`

export { Overlay, ModalContainer, ButtonContainer, AcceptButton, CancelButton, CloseButton }
