/* eslint-disable prettier/prettier */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext, memo } from 'react'
import { useApolloClient } from '@apollo/client'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import LinkNoteModal from './LinkNoteModal'
import LinkElement from '@lynit/shared/src/ui/LinkElement/LinkElement'
import {
	useCreateConnectionMutation,
	useDeleteConnectionMutation,
	useDeleteNodeMutation,
	useGetRelationshipsQuery,
} from '@lynit/shared/src/hooks'

import {
	deleteCacheElement,
	deleteCacheConnections,
	createCacheConnection,
	updateCacheNoteContents,
} from '@lynit/shared/src/utils/apollo'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import { createNodeId, throttle } from '@lynit/shared/src/utils/utils'

import { layoutContext } from '../../state/layoutProvider'
import { setStaticNoteMutation } from '../../state/actions'
import {
	Card,
	TagView,
	CardContent,
	ButtonContainer,
	DateContainer,
	TopContainer,
	ConnectionButton,
} from './styles'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'

export const createNoteConnection = () => {
	const client = useApolloClient()
	const [performCreateConnectionMutation] = useCreateConnectionMutation()
	const { data: relationships } = useGetRelationshipsQuery()

	const user = useContext(userStateContext)
	const graphData = useContext(graphDataStateContext)

	return async (destNodeId, id) => {
		const hasConnectionBeenMade = relationships?.relations?.some(
			// eslint-disable-next-line array-callback-return
			el => {
				return (
					(el?.connectionType === 'Note' &&
						el?.sourceNode?.id === id &&
						el?.destNode?.id === destNodeId) ||
					(el?.connectionType === 'Note' &&
						el?.destNode?.id === id &&
						el?.sourceNode?.id === destNodeId)
				)
			},
		)
		if (!hasConnectionBeenMade) {
			const relId = createNodeId("Relationship")

			const newConnection = {
				id: relId,
				description: '',
				arcStage: null,
				relName: 'RELATED',
				order: null,
				connectionType: 'Note',
				arc: null,
				structureTag:null,
				beatsDriven:null,
				sourceNode: {
					id,
					__typename: graphData?.nodes[id]?.__typename,
				},
				destNode: {
					id: destNodeId,
					__typename: graphData?.nodes[destNodeId]?.__typename,
				},
				name: '',
				__typename: 'Relationship',
				sharedBeats:[],
				firstBeat: '',
				createdAt: new Date().toISOString()
			}

			await performCreateConnectionMutation({
				client,
				newConnection,
				user
			}
			)
			
			// .finally(() => setTimeout(() => user?.refetchUser(), 0))
		}
		if (hasConnectionBeenMade) {
			toastHandler('warning', 'Duplicated Connection')
		}
	}
}

const NoteCard = ({ note }) => {
	const { id, contents, noteConnections, createdAt } = note

	const client = useApolloClient()
	const createNoteMutation = createNoteConnection()

	const [isModalDisplay, setModalDisplay] = useState(false)
	const [noteContent, setNoteContent] = useState(client?.cache?.data?.data[`Note:${id}`]?.contents)

	const [deleteNode] = useDeleteNodeMutation()
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()


	const systemRelatedData = useContext(systemStateContext)
	const user = useContext(userStateContext)
	const graphData = useContext(graphDataStateContext)

	const refetch = graphData?.refetch

	const nodesObj = graphData?.nodes || {}
	const nodes = Object.values(nodesObj)

	const nodesArr = Object.entries(nodes).map(e => e[1])

	const connectedNodes = new Set()

	noteConnections?.forEach(connection => {
		connectedNodes.add(connection.sourceNode.id)
		connectedNodes.add(connection.destNode.id)
	})


	const conNodes =
		noteConnections &&
		noteConnections?.map(connection => {
			const {
				destNode: { id: connDestId },
				sourceNode: { id: connSourceId }
			
			} = connection
			const destId = connDestId === id ? connSourceId : connDestId
			return {node: nodesObj[destId], connection}
		})

	const deleteNote = () => {
		systemRelatedData?.createLog(
			'Element Deletion Attempted',
			`{"workflowStep":${1},"elementType":"Note"}`,
			'NoteCard',
			'Element Deletion',
		)
		return new Promise((resolve, reject) => {
			deleteCacheElement(client, {
				id,
				__typename: 'Note',
			})
			deleteNode({
				variables: { id },
			})
				.then(async () => {
					resolve()
				})
				.catch(e => {
					if (e.message.includes('subscription')) {
						toastHandler('error', e.message)
						if (
							user?.user?.subscriptionStatus?.status === 'trialing' ||
							user?.user?.subscriptionStatus?.status === 'active'
						) {
							window.location.reload()
						}
					} else {
						toastHandler('error', `There was an error deleting the Note, try again.`)
					}
					reject()
				})
				// .finally(() => setTimeout(() => user?.refetchUser(), 0))
		})
	}

	const createConnec = destNodeId => {
		createNoteMutation(destNodeId, id, refetch)
	}

	const removeConnection = async connection => {
		
		systemRelatedData?.createLog(
			'Connection Deletion Attempted',
			`{"workflowStep":${1},"connectionType":"Note"}`,
			'NotesDrawer',
			'Connection Deletion',
		)

		performDeleteConnectionMutation({
			id: connection.id,
			client,
			elementToDelete:connection,
			})
			
	}

	return (
		<div>
			<Card>
				<CardContent>
					<TopContainer>
						<TagView>
							{conNodes &&
								conNodes?.length > 0 &&
								conNodes.map((conNode, i) => (
									<LinkElement
										key={conNode.connection?.id ?? i}
										node={conNode.node}
										title={'Delink'}
										id={`Note-connection-${conNode.connection?.id}`}
										removeConnection={removeConnection}
										connection={conNode.connection}
									/>
								))}
						</TagView>
						<DateContainer>
							{createdAt?.slice(0, 10) || new Date()?.toLocaleDateString()?.split('/')?.join('-')}
						</DateContainer>
					</TopContainer>
					<DescriptionField
						id={id}
						fieldName={"contents"}
						elementDescription={noteContent ?? ''}
						setElementDescription={setNoteContent}
						type="Note"
						connectionType="Static"
						placeholder="Note content"
					/>
				</CardContent>
				<ButtonContainer>
					<ConnectionButton
						aria-controls="connection-modal"
						aria-haspopup="true"
						onClick={event => !id.includes('temp') && setModalDisplay(event.currentTarget)}
					>
						Link Note
					</ConnectionButton>

					<p onClick={() => throttle(deleteNote)}>Remove note</p>
				</ButtonContainer>
			</Card>
			<div style={{ position: 'relative' }}>
				{isModalDisplay && (
					<LinkNoteModal
						isNote={true}
						isActive={isModalDisplay}
						currentId={''}
						objectList={nodesArr.filter(node => !connectedNodes.has(node.id))}
						setConnectionModal={setModalDisplay}
						sourceId={id}
						sourceNode={note}
						createLog={systemRelatedData?.createLog}
						createConnection={createConnec}
					/>
				)}
			</div>
		</div>
	)
}

export default memo(NoteCard)
