import styled from 'styled-components'

const BeatsContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	user-select: none;

	.beats-wrapper {
		border: 2px dashed #78c091;
		border-radius: 10px;
		background: #e5ece8;
		padding: 5px;
		padding-bottom: 30px;
	}

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const EmptyBeatContainer = styled.div`
	margin-top: 5px;
	height: 100%;
	overflow: hidden;
	.drop-placeholder {
		box-sizing: border-box;
		height: 100%;
		background-color: rgba(225, 244, 231, 0.4);
		border: 2px dashed #609d68;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-family: 'Source Sans Pro';
			font-size: 24px;
			line-height: 30px;
			color: #0d5d56;
		}
	}
	.empty-chapter-image {
		width: 120px;
		margin-top: 20px;
	}
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const PillWrapper = styled.div`
	display: flex;
	gap: 5px;
`

const BeatsTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	.beats-title {
		font-size: 10px;
		font-weight: bold;
		margin: 0;
		white-space: nowrap;
	}
`

export { EmptyBeatContainer, BeatsContainer, PillWrapper, BeatsTitle }
