import React, { memo, useContext, useEffect, useMemo, useState } from 'react'

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineContent from '@mui/lab/TimelineContent'
import Tooltip from '@mui/material/Tooltip'
import { useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import isEqual from 'lodash/isEqual'

import { nodeColors } from '../../utils/commonStyles'
import emptyChapterImage from '../../images/empty-chapter.svg'
import helpIcon from '../../images/helpIcon.svg'
import { BeatsContainer, EmptyBeatContainer, BeatsTitle, PillWrapper } from './styles'

import { sharedContext } from '../../state/sharedProvider'
import SuggestionTooltip from '../ToolTip/SuggestionTooltip'
import BeatCard from '../BeatCard'
import PillComponent from '../PillComponent'
import DraggableBeats from './DraggableBeats'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { beatsDataStateContext } from '../../state/beatsProvider'

const useStyles = makeStyles(() => ({
	initialsTooltip: {
		background: '#55534F',
	},
	emptyStateTooltip: {
		maxWidth: '220px',
		fontSize: '10px',
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '0',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineElement: {
		margin: 0,
		width: '10px',
		height: '10px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
}))

const getTrimmedNodeName = node => {
	if (node?.name) {
		return node?.name?.length > 26 ? `${node.name.substring(0, 26)} ...` : node?.name
	}
	return ''
}




const ChapterBeatsContainer = memo(
	({ type, beatConnections, setCurrentNode, referenceElement, droppable, beatCount, response }) => {
		const isNarrow = useMediaQuery('(max-width: 1024px)')
		const classes = useStyles()

		const {
			state: { editorData },
		} = useContext(sharedContext)

		const graphData = useContext(graphDataStateContext)

		return (
			<>
				<BeatsTitle className="beats-pill-wrapper">
					{!isNarrow && <p className="beats-title">{type} Development</p>}
					{!isNarrow && (
						<SuggestionTooltip
							title="Your Main Arc should have at least one Beat in every Chapter."
							placement="right"
							arrow
						>
							<img src={helpIcon} alt="help Icon" style={{ width: '8px', height: '8px' }} />
						</SuggestionTooltip>
					)}
					<PillWrapper>
						<PillComponent
							type="beat"
							className="beats-heading"
							beatCount={beatCount}
							shouldHover={true}
						/>
						<PillComponent
							type="wordCount"
							className="beats-heading"
							count={
								editorData?.id === referenceElement?.id
									? editorData?.count
									: referenceElement?.wordCount || 0
							}
							shouldHover={true}
						/>
					</PillWrapper>
				</BeatsTitle>
				<SortableContext
					//items={beatConnections?.map(({ beatConnection: connection }) => connection?.id )}
					items={beatConnections?.map(({ beatConnection: connection }) => {
						
						const beatId=
						connection?.sourceNode?.id?.startsWith('bea')
							? connection?.sourceNode?.id
							: connection?.destNode?.id
						const beat = graphData?.nodes[beatId]
						return !beat?.beatConnections[0]? connection?.id +"-" + "Chapter Card" : connection?.id
					})}

					strategy={verticalListSortingStrategy}
				>
					{!beatConnections?.length ? (
						<Tooltip
							title="Create a Beat in the Arc/Character Development area of an Element, then drag it here"
							followCursor
							classes={{ tooltip: classes.emptyStateTooltip }}
						>
							<EmptyBeatContainer ref={droppable}>
								<div className="drop-placeholder">
									<img src={emptyChapterImage} className="empty-chapter-image" alt='empty chapter'/>
									<p>No Beats</p>
								</div>
							</EmptyBeatContainer>
						</Tooltip>
					) : (
						<BeatsContainer ref={droppable}>
							<div className="beats-wrapper">
								<Timeline className={classes.timeline}>
									{beatConnections?.map(({ isActive, beatConnection }, index) => {
										const beatId=
										beatConnection?.sourceNode?.id?.startsWith('bea')
											? beatConnection?.sourceNode?.id
											: beatConnection?.destNode?.id
										const beat = graphData?.nodes[beatId]
										
										return (
											<TimelineItem key={beatId} className={classes.timelineItem}>
												<DraggableBeats
													key={beatId}
													beatId={beatId}
													setCurrentElement={setCurrentNode}
													referenceElementType={type}
													referenceElement={referenceElement}
													beatConnection={beat?.beatConnections?.[0]}
													connectionId={beatConnection?.id}
													isActive={isActive}
													index={index}
													beatConnections={beatConnections}
													response={response}
													location={"Chapter Card"}
													locationId={referenceElement?.id}
													shouldFocus={
														false
													}
												/>
											</TimelineItem>
										)
									})}
								</Timeline>
							</div>
						</BeatsContainer>
					)}
				</SortableContext>
			</>
		)
	},
)

const makeDroppable = Component => {
	return props => {
		const [referenceElement, setReferenceElement] = useState({})
		
		const beatCount = {
			'In Chapter': 0,
			Unused: 0,
			Total: props.beatConnections.length,
		}
		const { deepModeElement } = useContext(beatsDataStateContext)

		const graphData = useContext(graphDataStateContext)

		useEffect(() => {
			if (graphData?.nodes?.[props?.id]) {
				setReferenceElement(graphData?.nodes?.[props?.id])
			}
		}, [graphData?.nodes?.[props?.id]])

		const emptyDroppable = useDroppable({
			id: `rel-empty-beat-connections-${props?.id}`,
			disabled: !!referenceElement?.beatConnections?.length,
			data: {
				type: 'beat',
				referenceElement: {id:props?.id},
				id: `rel-empty-beat-connections-${props?.id}`,
			},
		})

		const beatsContainer = useDroppable({
			id: `container-${props?.id}`,
			//disabled: !!referenceElement?.beatConnections?.length,
			data: {
				type: 'beat',
				referenceElement: {id:props?.id},
				id: `container-${props?.id}`,
			},
		})

		// useEffect(() => {
		// 	const childCount = {
		// 		'In Chapter': 0,
		// 		Unused: 0,
		// 		Total: 0,
		// 	}

		// 	if (referenceElement?.beatConnections?.length) {
		// 		referenceElement?.beatConnections?.forEach(beat1 => {
		// 			const beatId = beat1?.sourceNode?.id?.startsWith('bea')
		// 				? beat1?.sourceNode?.id
		// 				: beat1?.destNode?.id
		// 			const beat = graphData.nodes[beatId]
		// 			const nodeId =
		// 				props.type === 'Chapter'
		// 					? beat?.parentId
		// 					: beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
		// 					? beat?.beatConnections?.[0]?.sourceNode?.id
		// 					: beat?.beatConnections?.[0]?.destNode?.id
		// 			const node = graphData.nodes[nodeId]
		// 			if (node) {
		// 				childCount['In Chapter'] += 1
		// 			} else {
		// 				childCount['Unused'] += 1
		// 			}
		// 			childCount['Total'] += 1
		// 		})
		// 	}
		// 	if (!isEqual(childCount, beatCount)) {
		// 		setBeatCount(childCount)
		// 	}
		// }, [referenceElement])

		const beatConnections =
			props.beatConnections.length > 0 &&
			props.beatConnections.map(beatConnection => {
				// const beat = beatConnection?.destNode?.id.startsWith('bea')
				// 	? graphData.nodes[beatConnection?.destNode?.id]
				// 	: graphData.nodes[beatConnection?.sourceNode?.id]

				// const isParent = beat?.parentId === deepModeElement?.id
				// const isDriver = beat?.driverConnections?.some(
				// 	conn =>
				// 		conn.destNode?.id === deepModeElement?.id ||
				// 		conn.sourceNode?.id === deepModeElement?.id,
				// )
				const isActive =
					deepModeElement?.nodeType !== 'Chapter'
						? true
						: true

				//const nodeId =null
					// props.type === 'Chapter'
					// 	? beat?.parentId
					// 	: beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
					// 	? beat?.beatConnections?.[0]?.sourceNode?.id
					// 	: beat?.beatConnections?.[0]?.destNode?.id
				//const node = graphData.nodes[nodeId]
				return { isActive, beatConnection }
			})

		return (
			<Component
				{...props}
				beatConnections={useMemo(() => beatConnections || [], [props?.beatConnections])}
				droppable={
					props?.beatConnections?.length ? beatsContainer.setNodeRef : emptyDroppable.setNodeRef
				}
				referenceElement={referenceElement}
				beatCount={beatCount}
				response={props.response}
			/>
		)
	}
}

export default memo(makeDroppable(ChapterBeatsContainer))
