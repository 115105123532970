/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from 'react'
import { useFullScreenHandle } from 'react-full-screen'
import CircularProgress from '@mui/material/CircularProgress'
import { withStyles } from '@mui/styles'
import { useApolloClient } from '@apollo/client'
import hash from 'object-hash'
import cloneDeep from 'lodash.clonedeep'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { useDeleteConnectionMutation } from '@lynit/shared/src/hooks'
import { deleteCacheConnections } from '@lynit/shared/src/utils/apollo'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import { trimName, getNodeIcon } from '@lynit/shared/src/utils/utils'
import { progressCircle } from '@lynit/shared/src/utils/commonStyles'

import NoArcsInDeepIcon from '../../images/noArcsInDeep.svg'
import ChapterArcSmall from '../../images/nochapter_icon_tEmbed.svg'
import ChapterIcon from '../../images/chapterIcon.svg'
import {
	StyledFullscreen,
	VizOuterContainer,
	ArcVizContainer,
	ArcVizInnerContainer,
	Timeline,
	NodeContainer,
	ArcNode,
	ArcName,
	ChapterNode,
	WhiteDot,
	ChapterName,
	DottedLine,
} from './styles'
import { setChapterViewExpanded, setVisualizationExpanded } from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state'

const muiStyles = {
	progressCircle,
}

const equivalentGraph = (prevProps, nextProps) => {
	// hash the data to compare it in order to compare the data change.
	const prevGraphNodesLength = Object.values(prevProps.graphData.nodes).map(
		node => node.name,
	).length
	const nextGraphNodesLength = Object.values(nextProps.graphData.nodes).map(
		node => node.name,
	).length
	const prevGraphNodesNameHash = hash(
		new Set(Object.values(prevProps.graphData.nodes).map(node => node.name)),
	)
	const nextGraphNodesNameHash = hash(
		new Set(Object.values(nextProps.graphData.nodes).map(node => node.name)),
	)
	const prevGraphBeatConnectionsHash = hash(
		Object.values(prevProps.graphData.nodesByType['Chapter']).map(node => node.beatConnections),
	)
	const nextGraphBeatConnectionsHash = hash(
		Object.values(nextProps.graphData.nodesByType['Chapter']).map(node => node.beatConnections),
	)
	const prevGraphDriverConnectionsHash = hash(
		new Set(
			Object.values(prevProps.graphData.nodesByType['Beat'])
				.map(node => node.driverConnections)
				.flat(),
		),
	)
	const nextGraphDriverConnectionsHash = hash(
		new Set(
			Object.values(nextProps.graphData.nodesByType['Beat'])
				.map(node => node.driverConnections)
				.flat(),
		),
	)

	return (
		prevGraphNodesNameHash === nextGraphNodesNameHash &&
		prevGraphBeatConnectionsHash === nextGraphBeatConnectionsHash &&
		prevGraphDriverConnectionsHash === nextGraphDriverConnectionsHash &&
		prevGraphNodesLength === nextGraphNodesLength
	)
}

const VizDataWrapper = props => {
	const {
		graphData: { loading, errors },
	} = props

	if (loading || errors) {
		// TODO handle errors
		return <CircularProgress className={props.classes.progressCircle} color="primary" />
	}
	return <ArcViz {...props} />
}

const ArcViz = React.memo(
	({
		graphData: { nodes, nodesByType },
		createLog,
		story,
		isDeepMode,
		driverConnToDelete,
		setDriverConnToDelete,
		isDriver,
		setIsDriver,
	}) => {
		const client = useApolloClient()
		const handle = useFullScreenHandle()
		const { deepModeElement } = useContext(beatsDataStateContext)
		const { user, refetchUser } = useContext(userStateContext)

		const {
			dispatch: dispatchAction,
		} = useContext(sharedContext)
		
		const [isFullscreen, setIsFullscreen] = useState(false)
		const [chaptersLength, setChaptersLength] = useState(0)
		const [arcTimelines, setArcTimelines] = useState(null)
		const [isEdit, setIsEdit] = useState(false)
		const [createdConn, setCreatedConn] = useState(null)
		const [isDelete, setDelete] = useState(false)


		const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
		const [selectedTimeline, setSelectedTimeline] = useState(null)

		//sets state for empty chapters when there are chapters but no connections

		const resetViews = () => {
			const visualization = document.getElementById('visualization')
			const chapterViewContainer = document.getElementById('chapter-view-container')
			chapterViewContainer.style.height = 'calc(60% - 5px)'
			visualization.style.height = 'calc(40% - 5px)'
			dispatchAction(setVisualizationExpanded(false))
			dispatchAction(setChapterViewExpanded(false))
		}
		

		useEffect(() => {
			//autoselects timeline with newly created data after edit or delete

			if (arcTimelines?.length > 0) {
				if (selectedTimeline && (!isEdit || !isDelete) && !createdConn) {
					arcTimelines?.map(tl => {
						if (tl[0]?.id === selectedTimeline[0]?.id) {
							setSelectedTimeline(tl)
						}
					})
				}
			}
		}, [arcTimelines])

		//autoselects timeline with newly created data after create
		useEffect(() => {
			if (createdConn) {
				arcTimelines.map(tl => {
					if (tl[0]?.id === createdConn?.arc?.id) {
						setSelectedTimeline(tl)
					}
				})
				setIsEdit(false)
				setCreatedConn(null)
			}
		}, [createdConn, arcTimelines])

		useEffect(() => {
			document?.addEventListener('fullscreenchange', escFunction)
			document?.addEventListener('webkitfullscreenchange', escFunction)
			document?.addEventListener('mozfullscreenchange', escFunction)
			document?.addEventListener('MSFullscreenChange', escFunction)
			return () => {
				document.removeEventListener('fullscreenchange', escFunction)
				document.removeEventListener('webkitfullscreenchange', escFunction)
				document.removeEventListener('mozfullscreenchange', escFunction)
				document.removeEventListener('MSFullscreenChange', escFunction)
			}
		}, [])

		//sets isFullscreen to false when clicking "Escape" on FullScreen mode
		const escFunction = () => {
			if (isFullscreen) {
				setIsFullscreen(false)
			}
		}

		useEffect(() => {
			getArcTimelines()
		}, [nodesByType])

		//manages deepMode
		useEffect(() => {
			//change from deepModeElement to another one
			if (deepModeElement) {
				getArcTimelines().then(() => {
					if (arcTimelines?.length > 0) {
						setSelectedTimeline(arcTimelines?.[0])
					} else {
						setSelectedTimeline(null)
					}
				})
			}
			if (!deepModeElement) {
				getArcTimelines().then(() => {
					setSelectedTimeline(null)
				})
			}
		}, [isDeepMode, deepModeElement])

		//autoselects timeline with newly created data after create
		useEffect(() => {
			if (createdConn) {
				setIsEdit(false)
				setCreatedConn(null)
			}
		}, [createdConn, arcTimelines])

		//autocloses connectionsList on story change
		useEffect(() => {
			setSelectedTimeline(null)
		}, [story])

		const getArcTimelines = async () => {
			let arcsArr = [...cloneDeep(nodesByType?.Arc), ...cloneDeep(nodesByType?.Character), ...cloneDeep(nodesByType?.Event), ...cloneDeep(nodesByType?.Theme)]
			const chaptersArr = [...cloneDeep(nodesByType?.Chapter)]
			chaptersArr.sort((a, b) => parseFloat(a?.number, 10) - parseFloat(b?.number, 10))
			setChaptersLength(chaptersArr?.length)
			
			arcsArr = arcsArr.filter(arc=> arc?.beatConnections?.length > 0)

			const newTimelinelist = arcsArr?.map(arc => {
				
				return [arc].concat(
					chaptersArr
						?.filter(chapter =>
							deepModeElement?.nodeType === 'Chapter' ? chapter?.id === deepModeElement?.id : true,
						)
						.map(chapter => {
							const beatConnections = chapter?.beatConnections?.filter(connection => {
								const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id

								return nodes[beatId]?.driverConnections.find(conn => [conn.sourceNode.id,conn.destNode.id].includes(arc?.id) ) 
							})
							let driverIds = [
								...(beatConnections.map(conn => {
									const beatId = conn?.sourceNode?.id?.startsWith('bea')
										? conn?.sourceNode?.id
										: conn?.destNode?.id

									const allDriverIds = nodes[beatId]?.driverConnections.map(d_conn => {
										const driverId = !d_conn?.sourceNode?.id?.startsWith('bea')
											? d_conn?.sourceNode?.id
											: d_conn?.destNode?.id
										return driverId
									})

									return [...allDriverIds]
								}) || []),
							]

							driverIds = driverIds.flat(1)

							return {
								...chapter,
								beatConnections,
								driverIds,
							}
						}),
				) },
			)

			newTimelinelist.map(tl => {
				const numberOfConns = 0
				tl.map(node => {
					if (
						(!node?.id?.startsWith('arc') || !node?.id?.startsWith('chr') || !node?.id?.startsWith('evt') || !node?.id?.startsWith('thm')) &&
						node?.beatConnections?.length > 0
					) {
						tl[0].numberOfConnections = tl[0].numberOfConnections
							? tl[0].numberOfConnections + 1
							: numberOfConns + 1
					}
					if (
						(!node?.id?.startsWith('arc') || !node?.id?.startsWith('chr') || !node?.id?.startsWith('evt') || !node?.id?.startsWith('thm')) &&
						!tl[0].numberOfConnections
					) {
						tl[0].numberOfConnections = 0
					}
				})
			})

			newTimelinelist?.sort((a, b) => b[0]?.numberOfConnections - a[0]?.numberOfConnections)
			
			if (deepModeElement) {
				newTimelinelist.map((arcrow, i) => {
					newTimelinelist[i].order =
						deepModeElement?.id === arcrow[0]?.id
							? 99999999
							: arcrow.slice(1).filter(chapter => chapter?.driverIds?.includes(deepModeElement?.id))
									?.length
				})
				newTimelinelist?.sort((a, b) => b.order - a.order)

				setArcTimelines(
					newTimelinelist.filter(
						arcrow =>
							arcrow
								.slice(1)
								.map(chapter => chapter.driverIds)
								.flat(1)
								.includes(deepModeElement?.id) ||
							arcrow.slice(0, 1).pop().id === deepModeElement?.id ||
							deepModeElement.nodeType === 'Chapter',
					),
				)
			} else {
				setArcTimelines(newTimelinelist)
			}

			return newTimelinelist
		}

		return (
			<StyledFullscreen handle={handle} isFullscreen={handle.active}>
				<VizOuterContainer id="arc-viz-outer-container" isFullscreen={handle.active}>
					<ArcVizContainer>
						{arcTimelines?.length === 0 && deepModeElement ? (
							<ArcVizInnerContainer isEmptyState={true} isDeepMode={isDeepMode}>
								<img src={NoArcsInDeepIcon} alt="No drivers exist image" />
								<p>
									This element doesn't drive any Arcs. To add it as an Arc Driver, go to an Arc and
									add it to a chapter.
								</p>
							</ArcVizInnerContainer>
						) : (
							<ArcVizInnerContainer
								chaptersLength={chaptersLength}
								className="tour-step-2"
								isFullscreen={handle.active}
								isDeepMode={isDeepMode}
							>
								{arcTimelines &&
									arcTimelines?.map(arcRow => {
										const arcTl = arcRow[0]
										const chapters = arcRow?.slice(1)
										return (
											<Timeline
												isSelected={selectedTimeline && selectedTimeline[0]?.id === arcTl?.id}
												key={arcRow[0]?.id}
												onClick={async () => {
													setSelectedTimeline(arcRow)
													createLog(
														'Arc Timeline Selected',
														`{"elementType":"${arcTl?.__typename}"}`,
														'ArcViz',
														null,
													)
													resetViews()
													
												}
											
											}

											>
												<>
													{
														<div style={{ heigth: '100%', width: 'fit-content' }}>
															<ToolTip
																data-public data-dd-privacy="allow"
																title={arcTl?.name}
																arrow
																placement="bottom"
																disableHoverListener={arcTl?.name?.length < 11}
															>
																<NodeContainer isArcNode={true} key={arcTl?.id}>
																	<ArcNode nodeType={arcTl?.__typename}>
																		<img src={getNodeIcon(arcTl?.__typename)} alt="Arc icon" />
																	</ArcNode>{' '}
																	<DottedLine /> <DottedLine /> <DottedLine /> <DottedLine />
																	{chaptersLength === 0 && (
																		<img src={ChapterArcSmall} alt="Small Add chapter icon" />
																	)}
																</NodeContainer>
															</ToolTip>
															<ArcName data-private="lipsum" data-dd-privacy="mask">{trimName(10, arcTl?.name)}</ArcName>
														</div>
													}
													{chapters?.map((chapter, i) => {
														//deepMode
														// eslint-disable-next-line no-return-assign

														return deepModeElement &&
															chapter?.driverIds?.includes(deepModeElement?.id) ? (
															<div key={chapter?.id}>
																<NodeContainer isWhiteDot={false} isChapterNode={true}>
																	<ChapterNode
																		nodeType={deepModeElement?.nodeType}
																		isInTimeline={true}
																	>
																		<img
																			alt="Chapter or driver icon"
																			src={
																				deepModeElement?.__nodeType === 'Chapter'
																					? ChapterIcon
																					: getNodeIcon(deepModeElement?.nodeType)
																			}
																		/>
																	</ChapterNode>
																	{chapters?.length === i + 1 ? (
																		''
																	) : (
																		<>
																			<DottedLine />
																			<DottedLine />
																		</>
																	)}
																</NodeContainer>
																<ChapterName isLastChapter={chapters?.length === i + 1}>
																	{chapter.number}
																</ChapterName>
															</div>
														) : chapter?.beatConnections?.length > 0 ? (
															//connected chapters
															<div key={chapter?.id}>
																<NodeContainer isWhiteDot={false} isChapterNode={true}>
																	<ChapterNode nodeType="Chapter" isInTimeline={true}>
																		<img alt="Small chapter icon" src={ChapterIcon} />
																	</ChapterNode>
																	{chapters?.length === i + 1 ? (
																		''
																	) : (
																		<>
																			<DottedLine />
																			<DottedLine />
																		</>
																	)}
																</NodeContainer>
																<ChapterName isLastChapter={chapters?.length === i + 1}>
																	{chapter.number}
																</ChapterName>
															</div>
														) : (
															//unconnected chapters
															<div key={chapter?.id}>
																<NodeContainer
																	isWhiteDot={true}
																	isLastChapter={chapters?.length === i + 1}
																>
																	{!chapter?.beatConnections &&
																		chapter?.beatConnections?.length > 0 &&
																		chapter?.beatConnections[0] && <DottedLine />}
																	<WhiteDot />
																	{chapters?.length === i + 1 ? (
																		''
																	) : (
																		<>
																			<DottedLine />
																			<DottedLine />
																			<DottedLine />
																		</>
																	)}
																</NodeContainer>
																<ChapterName
																	isLastChapter={chapters?.length === i + 1}
																	isWhiteDot={true}
																>
																	{chapter.number}
																</ChapterName>
															</div>
														)
													})}
												</>
											</Timeline>
										)
									})}
							</ArcVizInnerContainer>
						)}
					</ArcVizContainer>
				</VizOuterContainer>
				<DeleteModal
					isShowed={isDelete}
					elementType={isDriver ? 'arc driver' : 'from the arc'}
					isArc={true}
					details={'will be removed too'}
					isConnection={isDriver && driverConnToDelete}
					nodeName={
						driverConnToDelete?.arc?.__typename === 'Arc'
							? 'The description and drivers'
							: 'The description'
					}
					acceptHandler={async () => {
						createLog(
							'Connection Deletion Confirmed',
							`{"workflowStep":${2},"connectionType":"Beat"}`,
							'ArcViz',
							'Connection Deletion',
						)
						
						await performDeleteConnectionMutation({
							id: driverConnToDelete?.id,
							client,
							elementToDelete: driverConnToDelete,
						}).then(async () => {
							setSelectedTimeline(selectedTimeline)
							setDelete(false)
							setDriverConnToDelete(null)
							setIsDriver(false)
						})
						setDriverConnToDelete(null)
					}}
					closeHandler={async () => {
						createLog(
							'Connection Deletion Cancelled',
							`{"workflowStep":${2},"connectionType":"Beat"}`,
							'ArcViz',
							'Connection Deletion',
						)
						setDriverConnToDelete(null)
						setIsDriver(false)
						setDelete(false)
					}}
				/>
			</StyledFullscreen>
		)
	},
	equivalentGraph,
)

export default withStyles(muiStyles)(VizDataWrapper)
