import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Popover } from '@mui/material'
import { useApolloClient } from '@apollo/client'

import { CREATE_CONNECTION } from '../../data'
import { createNodeId, nodeTypeForId, sortNodesByNumber } from '../../utils/utils'
import { sharedContext } from '../../state'
import { setDestNodeId, setNewConnId } from '../../state/actions'
import { createCacheConnection, deleteCacheConnections, getConnectionCount } from '../../utils/apollo'
import { setConnectionCount } from '@lynit/shared/src/state/actions'
import MenuItem from './MenuItem'
import { MenuContainer } from './styles'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { userStateContext } from '../../state/userProvider'
import { useCreateConnectionMutation, useUpdateRelationshipMutation } from '../../hooks'
import { systemStateContext } from '../../state/systemProvider'
import { useCreateConnectionAndElementMutation } from '../../hooks/createConnectionAndElement'
import LinkNoteModal from '@lynit/layout/src/ui/NoteCard/LinkNoteModal'

const filterItemsByType = (arr, type) => {
	return arr
		.filter(item => item.__typename === type)
		.map(item => ({
			title: item.name,
			items: [],
			type,
			node: item,
		}))
}

const ConnectionModal = ({
	mainElementId,
	mainElementType,
	isOpen,
	handleClose = () => {},
	currentDriverType,
	connectionModalType,
	setConnectionModalType,
	isOpenStructureTagModal,
	setConnectionModal,
	setStructureTagConnnectionModal,
	setIsNewStructureTag,
	type = '',
	isAutoSelect,
	setIsAutoSelect
}) => {
	const client = useApolloClient()

	const graphData = useContext(graphDataStateContext)
	const {toastHandler} = useContext(systemStateContext)
	
	const isSupporting = connectionModalType.type === "Supporting"

	const [objectArray, setObjectArray] = useState(graphData?.allNodes?.nodes || [])

	const [performCreateConnectionMutation] = useCreateConnectionMutation()

	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation()

	const [performCreateConnectionAndElement] = useCreateConnectionAndElementMutation()
	const systemRelatedData = useContext(systemStateContext)

	const {
		dispatch,
		state: { refetchUser }  ,
	} = useContext(sharedContext)
	
	const user = useContext(userStateContext)

	const recommendedDriver = graphData.nodes[connectionModalType.recommendedDriverId]
	const nodeSet = new Set()
	const mainElementStaticConnections = graphData?.nodes?.[mainElementId]?.staticConnections || []
	nodeSet.add(mainElementId)
	mainElementStaticConnections.forEach(connection => {
		nodeSet.add(connection.sourceNode.id)
		nodeSet.add(connection.destNode.id)
	})

	const nodes = Object.values(graphData?.nodes || {})

	const nodeArray = Object.entries(nodes).map(e => e[1])

	const filterItemsByRelationType = (arr, type) => {
		return arr.filter(item => item.__typename === type)
	}

	const getSortedNodesByConnections = nodes => {
		return nodes.sort((a, b) =>
			sortNodesByNumber(
				{ number: a.staticConnections.length },
				{ number: b.staticConnections.length },
			),
		)
	}

	// remove connected nodes
	useEffect(() => {
		const nodes = new Set()
		const staticConnections = graphData?.nodes?.[mainElementId]?.staticConnections || []

		nodes.add(mainElementId)
		staticConnections.forEach(connection => {
			nodes.add(connection.sourceNode.id)
			nodes.add(connection.destNode.id)
		})
    
		const nodesData = Object.values(graphData?.nodes)
			?.sort((a, b) => a?.name?.localeCompare(b?.name))
			?.filter(node => !nodes.has(node.id))

		setObjectArray(nodesData)
	}, [graphData?.nodes?.[mainElementId]?.staticConnections?.length,graphData?.allNodes?.nodes?.length])

	
	const supportingOptions = useMemo(
		() => [
			{
				title:
					mainElementType === 'Character'
						? 'Ally'
						: mainElementType === 'Arc'
						? 'Protagonist'
						: 'Character',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Character'),
				tooltip:
					'An Ally captures that these characters help each other overcome their weaknesses. (A Supporting Character expresses the meaning of the connected TSM or Event.)',
				type: 'Character',
			},
			{
				title: 'Event',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Event'),
				tooltip:
					'A Supporting Event captures the strengths or essence of the connected element. Best used with Characters or TSMs.',
				type: 'Event',
			},
			{
				title: 'TSM',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Theme'),
				tooltip:
					'A Supporting TSM captures what guides the connected element or reveals how the two elements work together harmoniously.',
				type: 'Theme',
			},
			{
				title: mainElementType === 'Character' ? 'Protagonist' : 'Arc',
				isSupporting: true,
				items: filterItemsByType(objectArray, 'Arc'),
				tooltip:
					'A Protagonist is the main character driving an Arc. (A Supporting Arc has a common goal as the connected element.)',
				type: 'Arc',
			},
		],
		[objectArray],
	)

	const opposingOptions = useMemo(
		() => [
			{
				title:
					mainElementType === 'Character'
						? 'Opponent'
						: mainElementType === 'Arc'
						? 'Antagonist'
						: 'Character',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Character'),
				tooltip:
					'An Opponent means that these characters attack each others weaknesses. (An Opposing Character is expresses the weaknesses of the connected TSM or Event.)',
				type: 'Character',
			},
			{
				title: 'Event',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Event'),
				tooltip:
					'An Opposing Event reveals the weaknesses of or key contrasts to the connected element. Best used with Characters or TSMs.',
				type: 'Event',
			},
			{
				title: 'TSM',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Theme'),
				tooltip:
					'An Opposing TSM captures conflict at the thematic level or reveals the tensions between the two elements.',
				type: 'Theme',
			},
			{
				title: mainElementType === 'Character' ? 'Antagonist' : 'Arc',
				isSupporting: false,
				items: filterItemsByType(objectArray, 'Arc'),
				tooltip:
					'An Antagonist is directly competing against the Protagonist for the same goal. (An Opposing Arc has a conflicting goal as the connected element.)',
				type: 'Arc',
			},
		],
		[objectArray],
	)

	const driverOptions = useMemo(
		() => { 
			const output = { 
				Character: {
					title: `Select Relationship Type`,
					items:[{
						title: mainElementType === 'Character'
							? 'Ally'
							: mainElementType === 'Arc'
							? 'Protagonist'
							: 'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'An Ally captures that these characters help each other overcome their weaknesses. (A Supporting Character expresses the meaning of the connected TSM or Event.)',
						type: 'Character',
						structureTagIcon: "Supporting"
					},
					{
						title: mainElementType === 'Character'
							? 'Opponent'
							: mainElementType === 'Arc'
							? 'Antagonist'
							: 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Opponent means that these characters attack each others weaknesses. (An Opposing Character is expresses the weaknesses of the connected TSM or Event.)',
						type: 'Character',
						structureTagIcon: "Opposing",
						
					},],
					isTitle:true
					

				},
				Theme: {
					title: `Select Relationship Type`,
					items:[{
						title:'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'A Supporting TSM captures what guides the connected element or reveals how the two elements work together harmoniously.',
						type: 'Theme',
						structureTagIcon: "Supporting",
						
					},
					{
						title: 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Opposing TSM captures conflict at the thematic level or reveals the tensions between the two elements.',
						type: 'Theme',
						structureTagIcon: "Opposing",
						
					},],
					isTitle:true

				},
				Arc: {
					title: `Select Relationship Type`,
					items:[{
						title: mainElementType === 'Character' ? 'Protagonist' : 'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'A Protagonist is the main character driving an Arc. (A Supporting Arc has a common goal as the connected element.)',
						type: 'Arc',
						structureTagIcon: "Supporting"
					},
					{
						title: mainElementType === 'Character' ? 'Antagonist' : 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Antagonist is directly competing against the Protagonist for the same goal. (An Opposing Arc has a conflicting goal as the connected element.)',
						type: 'Arc',
						structureTagIcon: "Opposing"
					},],
					isTitle:true

				},
				Event: {
					title: `Select Relationship Type`,
					items:[{
						title:'Supporting',
						isSupporting: true,
						items: [recommendedDriver],
						tooltip:
							'A Supporting Event captures the strengths or essence of the connected element. Best used with Characters or TSMs.',
						type: 'Event',
						structureTagIcon: "Supporting"
					},
					{
						title: 'Opposing',
						isSupporting: false,
						items:  [recommendedDriver],
						tooltip:
							'An Opposing Event reveals the weaknesses of or key contrasts to the connected element. Best used with Characters or TSMs.',
						type: 'Event',
						structureTagIcon: "Opposing"
					},],
					isTitle:true

				},
				Supporting: {
					title: 'Supporting',
					items: supportingOptions,
					isTitle: true,
				},
				Opposing: {
					title: 'Opposing',
					items: opposingOptions,
					isTitle: true,
				}

			}
			
			return output
	}, [supportingOptions, opposingOptions, recommendedDriver, isOpenStructureTagModal])
	const menuOptions = useMemo(
		() => isSupporting
				? [
						{
							title: 'Supporting',
							items: supportingOptions,
							isTitle: true,
						},
						{
							title: 'Opposing',
							items: opposingOptions,
							isTitle: true,
						},
				  ]
				: [
						{
							title: 'Opposing',
							items: opposingOptions,
							isTitle: true,
						},
				  ],
		[supportingOptions, opposingOptions],
	)

	const createStaticConnection = async (newConnection) => {

		await performCreateConnectionMutation({
			client,
			newConnection,
			user
		}
		)
		user?.refetchUser()
	}

	const handleStructureTagChange = async (id, structureTag) => {
		let currentStructureTag
		if (structureTag) {
			currentStructureTag = structureTag
		} else if (!structureTag) {
			currentStructureTag = structureTagMatcher(referenceElement.__typename, type, true)
		} else {
			currentStructureTag = structureTag
		}
		setStructureTagConnnectionModal(false)
		await performUpdateRelationshipMutation({
			id,
			input: {
				structureTag: currentStructureTag,
			},
			client,
			__typename: 'Relationship',
			newMapValue: { structureTag: currentStructureTag },
			broadcast: true,
		})
		handleClose({ id })
		setIsNewStructureTag(true)
	}

	const createStaticConnectionAndElement = async (
		newConnection,
		newElement,
		optimisticResponse,
	) => {
		await performCreateConnectionAndElement({
			client,
			newConnection,
			newElement,
			user,
			optimisticResponse,
			hasOrder: true,
			broadcast: false,
		})
		handleClose({id:newConnection?.id})
	}

	const handleClick = async (destId, structureTag = null, newConnection, mode) => {
	
		if (destId) {
			const sharedBeats = graphData.nodes[mainElementId].driverConnections.map(conn => { 
				const beatId = conn.sourceNode.__typename === "Beat" ? conn.sourceNode.id : conn.destNode.id
				const beat = graphData.nodes[beatId]
				return beat
				} ).filter((beat)=> {
				
				const sharedBeat = beat.driverConnections.find((driver) => {

					return [driver.sourceNode.id,driver.destNode.id].includes(destId) 
					
				})
				return sharedBeat
			}).map(beat => beat )

			
			if(!newConnection){
				const tempId = createNodeId("Relationship")
				newConnection = {
					id: tempId,
					description: '',
					arcStage: null,
					relName: 'RELATED',
					order: null,
					connectionType: 'Static',
					structureTag,
					arc: null,
					sourceNode: {
						id: mainElementId,
						__typename: graphData?.nodes[mainElementId]?.__typename,
					},
					destNode: {
						id: destId,
						__typename: graphData?.nodes[destId]?.__typename,
					},
					__typename: 'Relationship',
					beatsDriven: String(sharedBeats?.length || 0),
					sharedBeats,
					firstBeat: '',
					createdAt: new Date().toISOString()
					
				}
				dispatch(setNewConnId(tempId))
			}

			// const connectionCount = getConnectionCount(client)
			// dispatch(
			// 	setConnectionCount({
			// 		supportingCount: connectionCount.Supporting,
			// 		opposingCount: connectionCount.Opposing,
			// 		undefinedCount: connectionCount.Undefined,
			// 		total: connectionCount.Total
			// 	}),
			// )
			
			if (mode ==='autoSelect'){
				setIsAutoSelect(true)
			}
			setTimeout(() => {
				!type && setStructureTagConnnectionModal(isOpen)
				setConnectionModal && setConnectionModal(null)
				!type && setConnectionModalType({
					type: newConnection?.destNode?.__typename,
					recommendedDriverId: newConnection.destNode.id,
					recommendedDriver: { id: newConnection.id },
					isRecommendedDriver: false,
				})
			}, 500);
		
			await createStaticConnection(newConnection)
			type && handleClose({id: newConnection?.id})
			

			
		}
	} 
	
	
	return (
		<>
			{isOpenStructureTagModal && <Popover
				anchorEl={isOpenStructureTagModal}
				open={!!isOpenStructureTagModal}
				onClose={handleClose}
				anchorOrigin={{
					vertical: isAutoSelect  ? 75 : 40,
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<MenuContainer>
					<div className="container">
						<MenuItem
							item={driverOptions[connectionModalType.type]}
							mainElementId={mainElementId}
							onClick={handleClick}
							onClose={handleClose}
							connectionModalType={connectionModalType}
							handleCreateConnectionAndElement={createStaticConnectionAndElement}
							handleUpdateConnectionAndElement={handleStructureTagChange}
						/>
					</div>
				</MenuContainer>
			</Popover>}
			<div>
				{isOpen && (
					<LinkNoteModal
						isNote={false}
						isActive={isOpen}
						isDriver={true}
						currentId={''}
						objectList={getSortedNodesByConnections(
							filterItemsByRelationType(nodeArray, currentDriverType).filter(
								node => !nodeSet.has(node.id),
							),
						)}
						setConnectionModal={handleClose}
						createLog={systemRelatedData?.createLog}
						createConnection={(destId, structureTag = null, newConnection)=>{
							handleClick(destId, structureTag = null, newConnection,'autoSelect');
						}
						}
						mainElementId={mainElementId}
						suggetionHandleClick={newConnection => {
							setStructureTagConnnectionModal(isOpen)
							setConnectionModal(null)
							setConnectionModalType({
								type: newConnection?.destNode?.__typename,
								recommendedDriverId: newConnection.destNode.id,
								recommendedDriver: { id: newConnection.id },
								isRecommendedDriver: false,
							})
							setIsAutoSelect(true)
						}}
						connectionModalType={connectionModalType}
						type={currentDriverType}
					/>
				)}
			</div>
		</>
	)
}

export default ConnectionModal
