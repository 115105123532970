import React, { useState } from 'react'
import removeIcon from '../../images/close-wtdn-modal.svg'
import OutsideClickHandler from '../../utils/OutsideClickHandler'
import { throttle } from '../../utils/utils'
import ToolTip from '../ToolTip'
import { ElementIconContainer, NodeIconContainer } from './styles'

const LinkElement = ({ node, removeConnection,connection, id, title }) => {
	
	const [elementExpanded, setElementExpanded] = useState(false)

	

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				if (elementExpanded) {
					const elem = document.getElementById(id)
					elem.style.width = '14px'
					setElementExpanded(false)
				}
			}}
			key={node?.id}
		>
			<ToolTip title={node?.name} placement="top" arrow>
				<ElementIconContainer
					onClick={e => {
						if (!elementExpanded) {
							e.currentTarget.style.width = title === 'Delink' ? '60px' : '95px'
							setElementExpanded(true)
						} else {
							throttle(async () => {
								await removeConnection(connection)
							})
						}
					}}
					id={id}
				>
					<NodeIconContainer type={node?.__typename} isDriver={true}>
						<span>
							{node?.name
								.match(/\b(\w)/g)
								?.join('')
								.slice(0, 2)
								.toUpperCase()}
						</span>
					</NodeIconContainer>
					<span className="remove-driver-name">{title}</span>
					<img src={removeIcon} alt="remove driver icon" className="remove-driver" />
				</ElementIconContainer>
			</ToolTip>
		</OutsideClickHandler>
	)
}

export default LinkElement
