import styled from 'styled-components'
import { colorStyles } from '../../utils/commonStyles'

export const SavingNotification = styled.div`
	width: auto;
	display: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
	height: auto;
	text-align: center;
	border: none;
	border-radius: 1rem;
	background-color: ${props => (props.isError ? 'black' : colorStyles.lightGrey)};
	color: ${props => (props.isError ? 'white' : colorStyles.darkGrey)};
	padding: 0.3rem 1rem;
	margin-left: 1rem;
	@media (max-width: 880px) {
		display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
		position: absolute;
		transform: translate(3rem, -2rem);
	}
`
