import styled from 'styled-components'

const Container = styled.div`
	box-sizing: border-box;
	gap: 5px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`

const BaseContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 0 5px;
	height: calc(100vh - 55px);
	display: grid;
	grid-template-columns: ${props => props.chapterGridCol};
	grid-template-rows: calc(50% - 5px) calc(50% - 5px);
	grid-column-gap: 5px;
	@media (max-width: 600px) {
		grid-gap: 5px;
		padding: 0;
		height: calc(100vh - 80px);
		grid-template-columns: 66px calc(100% - 142px) 66px;
	}
	@media (min-width: 601px) and (max-width: 768px) {
		position: relative;
		.wtdn-container {
			position: absolute !important;
			top: ${props => props.isDeepMode ? '40px' : '0'};
			right: 0;
			width: 300px;
			height: ${props => props.isDeepMode ? 'calc(100% - 40px)' : '95%'};
			z-index: 999;
			box-shadow: -4px 6px 12px rgb(0 0 0 / 10%);
			padding: 10px;
		}
	}
	@media (min-width: 769px) and (max-width: 1024px) {
		grid-template-columns: ${({ leftToggleTabs, rightToggleTabs }) => leftToggleTabs ?
		rightToggleTabs ? '35% 30% 34%' : '42% calc(57% - 67px) 70px' :
		rightToggleTabs ? '70px calc(64% - 70px) 35%' : '70px calc(99% - 140px) 70px'}
	}

	.gutter {
		width: 100%;
		position: relative;
		cursor: row-resize;
		::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			border-top: 1px dashed #575757;
			width: 100%;
			transform: translateY(-50%);
		}
		.splitter {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			z-index: 1;
			width: 50px;
			background: #FFFFFF;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
			border-radius: 15px;
			height: 10px;
			img {
				height: 8px;
				display: block;
				margin: 2px auto;
			}
		}
	}
`

const Card = styled.div`
	width: auto;
	height: auto;
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};
	@media (max-width: 600px) {
		width: ${props => (props.isTheme ? '103%' : '100%')};
		grid-column: ${({ gridMobColumn }) => gridMobColumn};
		grid-row: ${({ gridMobRow }) => gridMobRow};
	}
	&.addDriverContainer{
		.addDriver{
			.connectionModal12{
				top: 10px !important;
			}
		}
	}
`



const DeepModeContainer = styled.div`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
`

const MenuRightStick = styled.div`
	background: #ffd67a;
	border-radius: 5px 0 0 5px;
	padding: 10px;
	box-shadow: 0px 6px 4px -2px #bbb4b4;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: relative;

	span {
		cursor: pointer;
	}

	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
			button {
				margin: 0;
				padding: 0;
				border: none;
				background: transparent;
				display: block;
				text-align: center;
				img {
					background: #fff;
					width: 26px;
					height: 26px;
				}
			}
		}
	}
`

const RecommendationIndicator = styled.div`
	display: ${props => props.isVisible ? 'block' : 'none'};
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background: red;
	position: absolute;
	top: ${props => props.top ? props.top : '0'};
	left: ${props => props.left ? props.left : '0'};
	transform: translateX(-50%) translateY(-50%);
	z-index: 1;
`

const RightSidePanel = styled.div`
	width: 100%;
	box-sizing: border-box;
	border-radius: 7px;
	overflow: hidden;
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};
	background-color: ${({ isDeepMode }) => isDeepMode ? '#E0E0E0' : '#FFFFFF'};
	@media (max-width: 600px) {
		z-index: 1;
		grid-column: ${({ gridMobColumn }) => gridMobColumn};
		grid-row: ${({ gridMobRow }) => gridMobRow};
	}
	height: ${({ isDeepMode }) => isDeepMode ? 'calc(100% - 40px)' : ''};
    margin-top: ${({ isDeepMode }) => isDeepMode ? 'auto' : ''};
`
export {
    Container,
    BaseContainer,
    Card,
    DeepModeContainer,
    MenuRightStick,
    RecommendationIndicator,
    RightSidePanel
}