import styled from 'styled-components'
import { nodeColors } from '../../utils/commonStyles'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const QuillContainer = styled.div`
	width: calc(100% - 17px);
	height: auto;
	border: none !important;

	.ql-editor {
		padding: 0;
		font-family: 'Source Sans Pro';
		font-size: 12px;
		line-height: 15px;
		font-weight: 400;

		${({ isCollapsed }) =>
			isCollapsed &&
			`
			text-overflow: "ellipsis";
			word-wrap: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		`}

		p {
			cursor: inherit;
		}
	}
`

const Beat = styled.div`
	box-sizing: border-box;
	border-radius: 5px;
	background: ${({ isEdit }) => (isEdit ? '#FAFFFB' : '#F2FFF6')};
	border: ${({ isEdit }) => (isEdit ? '1.5px solid #0D5D56' : 'none')};
	box-shadow: 0px 0.888587px 0.888587px rgba(9, 30, 66, 0.1);
	min-height: 45px;
	height: auto;
	padding: 3px 6px;
	cursor: ${({ cursor }) => cursor};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	:hover {
		background: ${({ isEdit }) => (isEdit ? '#FAFFFB' : '#96BEA4')};
		box-shadow: 1px 2px 5px rgba(13, 93, 86, 0.2);
	}

	.quill-container {
		display: flex;
	}
`

const ShadowBeatCard = styled.div`
	min-height: 45px;
	height: auto;
	padding: 3px 6px;
	cursor: ${({ cursor }) => cursor};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.quill-container {
		display: flex;
	}
`

const MenuButton = styled.button`
	background-color: transparent;
	border: none;
	width: 15px;
	height: 12px;
	padding: 0px;
	margin: 0.5px 0 0 0;
	border-radius: 2px;
	display: content;
	cursor: pointer;
	img {
		height: 12px;
		width: 12px;
	}
	:hover {
		background-color: #fff6d3;
	}
`

const DriversContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	.drivers-container {
		display: flex;
		flex-direction: row-reverse;
		max-width: 95px;
		gap: 5px;
		overflow: auto;
		padding: 4px;
		::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
`
const PlusSign = styled.div`
	height: 20px;
	width: 20px;
	color: #bfbfbf;
	cursor: pointer;
	border-radius: 5px;
	background-color: ${({ isCreateDriver }) => (isCreateDriver ? '#ffc300' : 'transparent')};
	img {
		padding: 0 5px;
	}
	:hover {
		background-color: #fff6d3;
	}
`

const AddDriver = styled.div`
	display: ${({ isEditMode, isHovered }) => (isEditMode || isHovered ? 'flex' : 'none')};
	align-items: center;
	justify-content: flex-end;
	.add-driver {
		display: flex;
		align-items: center;
		padding: 2px 5px;
		border-radius: 4px;
		width: fit-content;
		background-color: #ffc300;
		gap: 4px;
		cursor: pointer;
	}
	p {
		margin: 0;
		color: #000;
		text-align: center;
		font-family: Source Sans Pro;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`

const ShadowBeatContent = styled.div`
	border: none !important;
	width: calc(100% - 20px);
	height: auto;
	.ql-editor {
		padding: 0;
		font-family: 'Source Sans Pro';
		font-size: 12px;
		line-height: 15px;
		font-weight: 400;

		${({ isCollapsed }) =>
			isCollapsed &&
			`
			text-overflow: "ellipsis";
			word-wrap: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		`}

		p {
			cursor: ${({ isCollapsed }) => (isCollapsed ? 'pointer' : 'grab')};
		}
	}
`

const ElementIconContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 7px;
	background-color: #d9d9d9;
	width: 14px;
	height: 14px;
	overflow: hidden;
	cursor: pointer;
	.remove-driver-name {
		white-space: nowrap;
		font-size: 10px;
		padding-left: 2px;
	}
	transition: width 0.5s;
	.remove-driver {
		width: 10px;
		padding: 0 2px;
	}
`

const NodeIconContainer = styled.div`
	min-width: 14px;
	width: 14px;
	height: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: '50%';
	background-color: ${props => getColor(props.type)};
	span {
		font-size: 8px;
	}
`
const AdditionalItemContainer = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
	cursor: pointer;
	width: 16px;
	height: 14px;
	span {
		color: #000;
		font-family: Source Sans Pro;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`

const OptionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

export {
	QuillContainer,
	Beat,
	MenuButton,
	DriversContainer,
	ShadowBeatContent,
	ShadowBeatCard,
	AddDriver,
	PlusSign,
	ElementIconContainer,
	NodeIconContainer,
	AdditionalItemContainer,
	OptionsWrapper,
}
