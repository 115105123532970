/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useApolloClient } from '@apollo/client'

import {
	deleteCacheElement,
	updateCacheNameElement,
	updateCacheNumberElement,
} from '../../utils/apollo'
import { toastHandler } from '../../utils/backendHandler'

import { sharedContext } from '../../state/sharedProvider'
import { setNoElementError, setServerError } from '../../state/actions'
import { useUpdateNodeMutation } from '../../hooks'
import Toast from '../CustomToast'
import ToolTip from '../ToolTip'
import { InputContainer, Text } from './styles'
import { userStateContext } from '../../state/userProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../../state/beatsProvider'
import { systemStateContext } from '../../state/systemProvider'

const TextField = ({
	id,
	type,
	content,
	size,
	placeholder,
	isNumber,
	setExpandedElementId,
	expandedElementId,
	setContent,
	isDeepMode,
	isRightPanel,
	updatedAt =()=> new Date().toISOString(),
}) => {
	const client = useApolloClient()
	const textRef = useRef()
	const numberInput = useRef(null)
	const serverErrorInterval = useRef()
	const timeOut = useRef()

	const {
		state: { rightToggleTabs },
		dispatch,
	} = useContext(sharedContext)

	const beatsRelatedData = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemRelatedData = useContext(systemStateContext)
	const user = useContext(userStateContext)

	const [updateElement] = useUpdateNodeMutation(type)

	const [changedContent, setChangedContent] = useState(content)
	const [isFocused, setFocused] = useState(false)
	const [isError, setError] = useState(false)
	const [numberSelected, setNumberSelected] = useState(false)
	const [isEdit, setEdit] = useState(false)
	const [inputWidth, setInputWidth] = useState(0)

	// const textContent = useRef()
	// textContent.current = changedContent

	// const unmountFunction = () => {
	// 		if ( client?.cache?.data?.data[`${type}:${id}`] &&
	// 			((isNumber &&
	// 				client?.cache?.data?.data[`${type}:${id}`]?.number !== textContent.current) ||
	// 			(!isNumber && client?.cache?.data?.data[`${type}:${id}`]?.name !== textContent.current)
	// 			)) {
	// 				update(textContent.current)
	// 			}
	// 			//setContent(changedContent)
	// 			//setFocused(false)
	// 			//setEdit(false)
			
	// }
	// useEffect( () => unmountFunction , [] )

	useEffect(() => {
		if (isEdit) {
			if (numberSelected) {
				numberInput.current.focus()
			}
		}
	}, [isEdit, numberSelected])

	useEffect(() => {
		if (expandedElementId !== id) {
			setNumberSelected(false)
		}
	}, [expandedElementId])

	useEffect(() => {
		if (beatsRelatedData?.serverError) {
			if ( client?.cache?.data?.data[`${type}:${id}`] &&
				((isNumber &&
					client?.cache?.data?.data[`${type}:${id}`]?.number !== changedContent) ||
				(!isNumber && client?.cache?.data?.data[`${type}:${id}`]?.name !== changedContent)
				)) {
					serverErrorInterval.current = setInterval(() => update(changedContent), 500)

				}
		}
		if (!beatsRelatedData?.serverError) {
			clearInterval(serverErrorInterval.current)
		}
	}, [beatsRelatedData?.serverError])

	useEffect(() => {
		if (isError) {
			timeOut.current = setTimeout(() => {
				toastHandler(
					'dark',
					'',
					<Toast
						onRetry={() => {
							update(changedContent)
							setError(false)
						}}
						isRetry
						content={'Content not update. Try again later.'}
					/>,
				)
			}, 1000)
		}
	}, [isError])

	const update = async changedContent => {
		if (isNumber) {
			updateCacheNumberElement(client, { id, __typename: type }, changedContent)

			updateElement({
				variables: {
					id,
					input: {
						number: changedContent,
						updatedAt: updatedAt(),
					},
				},
			})
				.then(res => {
					if (res && beatsRelatedData?.serverError) {
						beatsDataDispatch(setServerError(false))
						clearInterval(serverErrorInterval.current)
					}
					setContent(changedContent)
					setError(false)
				})
				.catch(async err => {
					if (err.message.includes('Cannot updated')) {
						beatsDataDispatch(setNoElementError(true))
						setTimeout(
							() =>
								deleteCacheElement(client, { id, type }).then(() => {
									beatsDataDispatch(setNoElementError(false))
								}),
							3000,
						)
					}
					if (err.message.includes('NetworkError')) {
						beatsDataDispatch(setServerError(true))
					}
					if (err.message.includes('Response not successful:')) {
						setError(true)
					}
				})
		} else {
			updateCacheNameElement(client, { id, __typename: type }, changedContent)
			updateElement({
				variables: {
					id,
					input: {
						name: changedContent,
						updatedAt: updatedAt(),
					},
				},
			})
				.then(res => {
					
					if (res && beatsRelatedData?.serverError) {
						beatsDataDispatch(setServerError(false))
						clearInterval(serverErrorInterval.current)
					}
					setError(false)
					setContent(changedContent)
					
				})
				.catch(async err => {
					if (err.message.includes('Cannot updated')) {
						beatsDataDispatch(setNoElementError(true))
						setTimeout(
							() =>
								deleteCacheElement(client, { id, type }).then(() => {
									beatsDataDispatch(setNoElementError(false))
								}),
							3000,
						)
					}
					if (err.message.includes('NetworkError')) {
						beatsDataDispatch(setServerError(true))
					}
					if (err.message.includes('Response not successful:')) {
						setError(true)
					}
				})
		}
	}

	return (
		<>
			{isEdit ? (
				<InputContainer
					isNumber={isNumber}
					size={!isNumber && isDeepMode ? `${inputWidth - 10}px` : size}
					type={type}
					isFocused={isFocused}
					onClick={() => {
						if (expandedElementId === id) {
							setEdit(true)
						}
					}}
				>
					<input
						data-private="lipsum"
						data-dd-privacy="mask"
						ref={numberInput}
						onFocus={e => {
							setFocused(true)
							// const val = e.target.value
							// e.target.value = ''
							// e.target.value = val
						}}
						type="text"
						defaultValue={
							isNumber
								? client?.cache?.data?.data[`${type}:${id}`]?.number
								: client?.cache?.data?.data[`${type}:${id}`]?.name
						}
						onChange={e => {
							setChangedContent(String(e.target.value?.trim()) || (isNumber ? '0' : `New ${type}`))
						}}
						placeholder={placeholder}
						onBlur={() => {
							if (changedContent && (client?.cache?.data?.data[`${type}:${id}`] &&
								(isNumber &&
									client?.cache?.data?.data[`${type}:${id}`]?.number !== changedContent) ||
								(!isNumber && client?.cache?.data?.data[`${type}:${id}`]?.name !== changedContent))
							) {
								update(changedContent)
								setContent(changedContent)
							}
							
							setFocused(false)
							setEdit(false)
						}}
					/>
				</InputContainer>
			) : (
				<ToolTip
					data-private="lipsum"
					data-dd-privacy="mask"
					title={
						client?.cache?.data?.data[`${type}:${id}`]?.name
							? client?.cache?.data?.data[`${type}:${id}`]?.name
							: ''
					}
					arrow
					placement="bottom"
					disableHoverListener={
						isDeepMode ||
						isNumber ||
						client?.cache?.data?.data[`${type}:${id}`]?.name?.length < 30 ||
						!client?.cache?.data?.data[`${type}:${id}`]?.name
					}
				>
					<Text
						ref={textRef}
						data-private="lipsum"
						data-dd-privacy="mask"
						type={type}
						isNumber={isNumber}
						isCollapsed={expandedElementId !== id}
						isSelected={id === beatsRelatedData?.deepModeElement?.id}
						rightToggleTabs={rightToggleTabs}
						isDeepMode={isDeepMode}
						isRightPanel={isRightPanel}
						onClick={() => {
							setInputWidth(prev =>
								textRef.current.clientWidth ? textRef.current.clientWidth : prev,
							)
								if (setExpandedElementId && expandedElementId !== id) {
									setExpandedElementId(id)
								}
								systemRelatedData?.createLog(
									'Element Details Expanded',
									`{"elementType":"${type}"}`,
									'ListElement',
									null,
								)

							if (!isEdit) {
								setNumberSelected(true)
								if (expandedElementId === id) {
									if (
										user?.user?.subscriptionStatus?.editNumberfield ||
										user?.user?.subscriptionStatus?.editTextfield
									) {
										setEdit(true)
									}
									systemRelatedData?.createLog(
										'Element Update Attempted',
										`{"workflowStep":${1},"elementType":"${type}", "isCollapsed":${
											expandedElementId !== id
										}}`,
										'ListElement',
										'Element Update',
									)
								} else if (type === "Chapter" && !isRightPanel) {
									if (
										user?.user?.subscriptionStatus?.editNumberfield ||
										user?.user?.subscriptionStatus?.editTextfield
									) {
										setEdit(true)
									}
								}
							}
						}}
					>
						{isNumber
							? client?.cache?.data?.data[`${type}:${id}`]?.number || 0
							: `${client?.cache?.data?.data[`${type}:${id}`]?.name || ''}`}
					</Text>
				</ToolTip>
			)}
		</>
	)
}

export default TextField
