import {
	DELETE_STORY,
	EDITOR_TEXT_CHANGE,
	EXPAND_TUTORIAL_SET,
	IS_EXPORTING,
	IS_SAVING,
	LOGIN,
	LOGOUT,
	MOVE_BEAT,
	ON_SAVE_MODAL,
	OPEN_RECOMMENDATION,
	OPEN_TUTORIAL,
	RECOMMENDATION_OPENED,
	REORDER_ELEMENT,
	RESET_ACTIVE_ELEMENT,
	SET_ACTIVE_CONNECTIONS,
	SET_ACTIVE_ELEMENT,
	SET_BEATS_IN_CHAPTERS,
	SET_CACHE_CONN_DESC,
	SET_CACHE_CONN_SELECTION,
	SET_CLICKED_VALUE_PROP,
	SET_CONNECTION_COUNT,
	SET_CONNECTION_DATA,
	SET_CURRENT_ELEMENT,
	SET_CURRENT_FLOW,
	SET_CURRENT_RECOMMENDATION,
	SET_CURRENT_STORY,
	SET_CURRENT_STORY_ID,
	SET_DEEP_MODE_ELEMENT,
	SET_DIFFERENT_VERSION,
	SET_DIVING_DEEP,
	SET_GRAPH_DATA,
	SET_IS_MOBILE,
	SET_IS_NEW_USER,
	SET_IS_ONLINE,
	SET_IS_TOUR_OPEN,
	SET_LEFT_TOGGLE_TABS,
	SET_NEW_CONN_ID,
	SET_NO_ELEMENT_ERROR,
	SET_OPEN_TOOL_TIP,
	SET_RECOMMENDATION_SHOWN,
	SET_RIGHT_TOGGLE_TABS,
	SET_SERVER_ERROR,
	SET_STORY,
	SET_SUMMARY_VIEW,
	SET_UNSEEN_RECOMMENDATION_COUNT,
	SET_USER,
	SET_USER_SUBSCRIBED,
	SET_WAS_OFFLINE,
	TUTORIAL_OPENED,
	TUTORIAL_SET_EXPANDED,
	SET_SCROLL_POSITION,
	SET_VISULIZATION_EXPANDED, 
	SET_CHAPTER_VIEW_EXPANDED,
	SET_CONTEXT_DEEP_MODE_CONNS,
	SET_SUMMARY_CARD_VISIBLE,
	SET_FIELD_ERROR
} from './actionTypes'

export const setGraphData = payload => {
	return {
		type: SET_GRAPH_DATA,
		payload
	}
}

export const setSummaryView = payload => {
	return {
		type: SET_SUMMARY_VIEW,
		payload
	}
}

export const setSummaryCardVisible = payload => {
	return {
		type: SET_SUMMARY_CARD_VISIBLE,
		payload
	}
}

export const setStory = payload => {
	return {
		type: SET_STORY,
		payload
	}
}

export const setUnseenRecommendationCount = payload => ({
	type: SET_UNSEEN_RECOMMENDATION_COUNT,
	payload
})

export const setSavingData = payload => {
	return {
		type: IS_SAVING,
		payload
	}
}

export const setDeepModeElement = payload => {
	return {
		type: SET_DEEP_MODE_ELEMENT,
		payload
	}
}

export const openRecommendation = payload => {
	return {
		type: OPEN_RECOMMENDATION,
		payload
	}
}

export const recommendationOpened = () => {
	return {
		type: RECOMMENDATION_OPENED
	}
}

export const setServerError = payload => {
	return {
		type: SET_SERVER_ERROR,
		payload
	}
}

export const setFieldError = payload => {
	return {
		type: SET_FIELD_ERROR,
		payload
	}
}

export const setNoElementError = payload => {
	return {
		type: SET_NO_ELEMENT_ERROR,
		payload
	}
}

export const setWasOffline = payload => {
	return {
		type: SET_WAS_OFFLINE,
		payload
	}
}

export const setIsOnline = payload => {
	return {
		type: SET_IS_ONLINE,
		payload
	}
}

export const setCurrentRecommendation = payload => {
	return {
		type: SET_CURRENT_RECOMMENDATION,
		payload
	}
}

export const setRecommendationShown = payload => {
	return {
		type: SET_RECOMMENDATION_SHOWN,
		payload
	}
}

export const setIsDifferentVersion = payload => {
	return {
		type: SET_DIFFERENT_VERSION,
		payload
	}
}

export const setCurrentStory = payload => {
	return {
		type: SET_CURRENT_STORY,
		payload
	}
}

export const deleteCurrentStory = payload => {
	return {
		type: DELETE_STORY,
		payload
	}
}

export const setCurrentStoryId = payload => {
	return {
		type: SET_CURRENT_STORY_ID,
		payload
	}
}

export const setCurrentElement = payload => {
	return {
		type: SET_CURRENT_ELEMENT,
		payload: payload ? payload : ''
	}
}

export const setIsTourOpen = payload => {
	return {
		type: SET_IS_TOUR_OPEN,
		payload
	}
}

export const setIsExporting = payload => {
	return {
		type: IS_EXPORTING,
		payload
	}
}

export const setBeatsInChapters = payload => {
	return {
		type: SET_BEATS_IN_CHAPTERS,
		payload
	}
}

export const setConnectionData = payload => {
	return {
		type: SET_CONNECTION_DATA,
		payload
	}
}

export const setUser = payload => {
	return {
		type: SET_USER,
		payload
	}
}

export const login = () => ({
	type: LOGIN
})

export const setCurrentFlow = payload => {
	return {
		type: SET_CURRENT_FLOW,
		payload
	}
}

export const logout = () => ({
	type: LOGOUT
})

export const openTutorial = payload => {
	return {
		type: OPEN_TUTORIAL,
		payload
	}
}

export const expandTutorialSet = payload => {
	return {
		type: EXPAND_TUTORIAL_SET,
		payload
	}
}

export const tutorialSetExpanded = () => {
	return {
		type: TUTORIAL_SET_EXPANDED,
	}
}

export const tutorialOpened = () => {
	return {
		type: TUTORIAL_OPENED,
	}
}

export const setClickedValueProp = payload => {
	return {
		type: SET_CLICKED_VALUE_PROP,
		payload
	}
}

export const setLeftToggleTabs = payload => {
	return {
		type: SET_LEFT_TOGGLE_TABS,
		payload
	}
}

export const setRightToggleTabs = payload => {
	return {
		type: SET_RIGHT_TOGGLE_TABS,
		payload
	}
}

export const setOpenToolTip = payload => {
	return {
		type: SET_OPEN_TOOL_TIP,
		payload
	}
}

export const setIsNewUser = payload => {
	return {
		type: SET_IS_NEW_USER,
		payload
	}
}

export const setCacheConnDesc = payload => {
	return {
		type: SET_CACHE_CONN_DESC,
		payload
	}
}

export const setCacheConnSelection = payload => {
	return {
		type: SET_CACHE_CONN_SELECTION,
		payload
	}
}

export const setNewConnId = payload => {
	return {
		type: SET_NEW_CONN_ID,
		payload
	}
}

export const setDivingDeep = payload => {
	return {
		type: SET_DIVING_DEEP,
		payload,
	}
}

export const setWordCount = payload => {
	return {
		type: EDITOR_TEXT_CHANGE,
		payload
	}
}

export const moveBeatConnection = payload => {
	return {
		type: MOVE_BEAT,
		payload,
	}
}

export const beatMoved = () => {
	return {
		type: MOVE_BEAT,
		payload: null,
	}
}

export const elementReordered = () => {
	return {
		type: REORDER_ELEMENT,
		payload: null,
	}
}

export const reorderElement = payload => {
	return {
		type: REORDER_ELEMENT,
		payload,
	}
}

export const setUserSubscribed = payload => {
	return {
		type: SET_USER_SUBSCRIBED,
		payload,
	}
}

export const setIsMobile = payload => {
	return {
		type: SET_IS_MOBILE,
		payload,
	}
}

export const setActiveElement = (id, type) => ({
	type: SET_ACTIVE_ELEMENT,
	payload: {
		id,
		type,
	},
})

export const resetActiveElement = () => ({
	type: RESET_ACTIVE_ELEMENT,
})

export const onSaveModal = payload => {
	return {
		type: ON_SAVE_MODAL,
		payload
	}
}
export const setConnectionCount = payload => {
	return {
		type: SET_CONNECTION_COUNT,
		payload,
	}
}

export const setActiveConnections = payload => {
	return {
		type: SET_ACTIVE_CONNECTIONS,
		payload,
	}
}

export const setScrollPosition = (container, position) => {
	return {
		type: SET_SCROLL_POSITION,
		payload: {
			container,
			position
		}
	}
}

export const setVisualizationExpanded = payload => {
	return {
		type: SET_VISULIZATION_EXPANDED,
		payload
	}
}
export const setChapterViewExpanded = payload => {
	return {
		type: SET_CHAPTER_VIEW_EXPANDED,
		payload
	}
}

export const setContextDeepModeConns = payload => {
	return {
		type: SET_CONTEXT_DEEP_MODE_CONNS,
		payload,
	}
}
