import LogRocket from 'logrocket'
import { datadogRum } from '@datadog/browser-rum';
import {jsonParseWithTest} from './utils'



export const initializeLogRocket = () => {
	if (process.env.REACT_APP_LOGROCKET === 'true') {

		const logRocketSettings = {
			dom: {
				textSanitizer: true,
				inputSanitizer: true,
			},
			network: {
				responseSanitizer: response => {
					if (response.headers['x-secret']) {
						// removes all response data
						return null
					}
	
					// scrubs response body
					response.body = null
					return response
				},
				requestSanitizer: request => {
					// if the url contains 'delete'
					if (request.url.toLowerCase().indexOf('delete') !== -1) {
						// scrub out the body
						request.body = null
					}
	
					// if the url contains 'ignore'
	
					// scrub header value from request
					if (request.headers['x-auth-token']) {
						request.headers['x-auth-token'] = ''
					}
					if (request.headers['authorization']) {
						request.headers['authorization'] = ''
					}
					// scrub variables from queries
					if (request.body) {
						let requestBody = jsonParseWithTest(request.body)
						requestBody['variables'] = {}
						requestBody = JSON.stringify(requestBody)
						request.body = requestBody
					}
	
					// make sure you return the modified request
					return request
				},
			},
		}
		
		try {
			LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT, logRocketSettings)
		}
		catch {
			//LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT, logRocketSettings)
		}

		const dataDogSettings = {
			applicationId: '74bb1a2f-af8f-4cf0-9316-e473a1069ae5',
			clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
			site: 'datadoghq.com',
			service:'lynit',
			env:process.env.REACT_APP_ENVIRONMENT,
			version: "2.10.1", //localStorage.version,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel:'mask',
				beforeSend: (event) => {
					if(event.action?.target) {
						event.action.target.name=  ""
					}
		
			}
		}

		try {

			datadogRum.init(dataDogSettings);
	
			datadogRum.startSessionReplayRecording();
		}
		catch{

			datadogRum.init(dataDogSettings);
	
			datadogRum.startSessionReplayRecording();

		}
	
	}
}
