import { sortNodesByCreatedAt } from "./utils"

const getConnections = (deepModeElement, relationships, nodes) => {
	
	if (deepModeElement && nodes[deepModeElement]) {
		
		//eslint-disable-next-line
		const currentElement = nodes[deepModeElement]
		const premiseConnections = []
		const otherConnection = []
		const currentRelations = currentElement?.staticConnections?.map(e => {

			if (e?.sourceNode?.id?.includes(deepModeElement)) {
				let connObj
				
				if (nodes[e?.destNode?.id] && nodes[e?.destNode?.id].__typename !== 'Note') {
					connObj = {
						id: e?.id,
						name: nodes[e?.destNode?.id].name || '',
						description: e?.description,
						structureTag: e?.structureTag,
						__typename: e?.destNode.__typename,
						sourceNode: e?.sourceNode,
						destNode: e?.destNode,
						beatsDriven: e?.beatsDriven,
						sharedBeats: e?.sharedBeats,
						firstBeat: e?.firstBeat,
						createdAt: e?.createdAt
					}
				}
				if(nodes[e?.destNode?.id].coreElement === "true"){
					premiseConnections.push(connObj)
				} else{
					otherConnection.push(connObj)
				}
				return connObj
			}
			if (e?.destNode?.id?.includes(deepModeElement)) {
				let connObj
				
				if (nodes[e?.sourceNode?.id] && nodes[e?.sourceNode?.id].__typename !== 'Note') {
					connObj = {
						id: e?.id,
						name: nodes[e?.sourceNode?.id].name,
						description: e?.description,
						structureTag: e?.structureTag,
						__typename: e?.sourceNode.__typename,
						sourceNode: e?.destNode,
						destNode: e?.sourceNode,
						beatsDriven: e?.beatsDriven,
						sharedBeats: e?.sharedBeats,
						firstBeat: e?.firstBeat,
						createdAt: e?.createdAt
					}
				}
				if(nodes[e?.sourceNode?.id].coreElement === "true"){
					premiseConnections.push(connObj)
				} else{
					otherConnection.push(connObj)
				}

				return connObj
			}
		})
		return {all: currentRelations?.filter(rel => rel).sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(b, a) : -1)), premise: premiseConnections?.filter(rel => rel).sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(b, a) : -1)), other: otherConnection?.filter(rel => rel).sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(b, a) : -1))}
	}
}

export default getConnections
