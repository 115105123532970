/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import { useApolloClient } from '@apollo/client'
import { useMediaQuery } from '@mui/material'

import BreadCrumb from '../BreadCrumb'
import TaskBar from '../TaskBar'
import DeleteModal from '../../shared-ui/DeleteModal'
import UpgradeToPro from '../../shared-ui/UpgradeToPro'
import AutoSave from '../AutoSave'

import { deleteCurrentStory, logout, setCurrentStoryId, setUser } from '../../state/actions'
import { sharedContext } from '../../state/sharedProvider'
import { autoSaveStateContext } from '../../state/autoSaveProvider'
import { useDeleteStoryMutation, useGetCustomerPortalQuery, useLazyGetUserQuery } from '../../hooks'

import { toastHandler } from '../../utils/backendHandler'
import { userNameLengthFormat } from '../../utils/utils'
import OutsideClickHandler from '../../utils/OutsideClickHandler'
import { colorStyles } from '../../utils/commonStyles'
import { deleteStoryCache, updateCacheField } from '../../utils/apollo'

import lynit from '../../images/lynit.svg'
import LogoutIcon from '../../images/logout.svg'
import storyIcon from '../../images/storyIcon.svg'
import SupportIcon from '../../images/support.svg'
import dropdownIcon from '../../images/downArrow.svg'
import SettingsIcon from '../../images/engine_icon.svg'
import trashIcon from '../../images/IconDelete.svg'


import {
	Box,
	Container,
	LynitLogo,
	NameButton,
	HelperDiv,
	MenuContent,
	LogoutButton,
	MenuContainer,
	ProfileOptions,
	ProfileContainer,
	HeaderLeftContainer
} from './styles'
import { userDispatchContext, userStateContext } from '../../state/userProvider'
import { systemStateContext, systemDispatchContext } from '../../state/systemProvider'
import { beatsDataDispatchContext } from '../../state/beatsProvider'
import { useDeleteUserMutation } from '../../hooks/deleteUser'

const styles = {
	root: {
		flexGrow: 1
	},
	barStyles: {
		boxShadow: 'none',
		backgroundColor: '#FFFFFF',
		zIndex: 200
	},
	leftComponents: {
		flexGrow: 1
	},
	link: {
		textDecoration: 'none',
		color: colorStyles.black
	},
	name: {
		borderRadius: '30px',
		minWidth: '10rem',
		fontSize: 'smaller'
	},
	menu: {
		color: '#000000',
		minWidth: '7rem'
	},
	bottomBar: {
		alignSelf: 'flex-end',
		margin: '0 0 7px 0',
		minHeight: 'fit-content'
	},
	notesButton: {
		fontWeight: 'bold',
		fontSize: '.75rem',
		border: 'none'
	},
	icon: {
		margin: '0 5px'
	},
	back: {
		color: '#555555',
		fontSize: 'smaller',
		textDecoration: 'none',
		transform: 'translateX(-1rem)',
		paddingTop: '1rem'
	},
	notesDiv: {
		width: '100px',
		borderRadius: '10px 10px 0 0'
	}
}

const Navbar = React.memo(({ isDashboard, isLayout }) => {
	const isMobile = useMediaQuery('(max-width: 600px)')
	const client = useApolloClient()
	const history = useHistory()

	const {
		state: { currentStory, isExporting, deleteStory: isDeleteOpen },
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const {isSaving} = useContext(autoSaveStateContext)
	const user = useContext(userStateContext)
	const userDataDispatch = useContext(userDispatchContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemDispatch = useContext(systemDispatchContext)
	
	const {isLoggedIn, createLog} = useContext(systemStateContext)

	const [isShow, setActive] = useState(false)
	const [isDeleteUser,setIsDeleteUser] = useState(false)

	const [getUser] = useLazyGetUserQuery({
		notifyOnNetworkStatusChange: true,
	})
	const { data: urlCustomerPortal, refetch: refetchCustomerPortal } = useGetCustomerPortalQuery()
	const [deleteStory] = useDeleteStoryMutation()
	const [deleteUser] = useDeleteUserMutation()

	const storyDelete = async () => {
		try {
			await deleteStory({
				variables: {
					id: currentStory?.id
				}
			})
			const storyCount = user?.user.userSummary.storyCount - 1
			const userSummary = {...user?.user.userSummary,storyCount}
			
			updateCacheField(client, {id:user?.user.id, __typename:"User"},{userSummary})
			
			deleteStoryCache(client, currentStory?.id)
			toastHandler('success', 'Story deleted successfully.')
			history.push('/dashboard')
			dispatchAction(deleteCurrentStory(false))
			beatsDataDispatch(setCurrentStoryId(null))
		} catch (error) {
			if (error?.message?.includes('subscription')) {
				toastHandler('error', error.message)
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${error.message}"}`,
					'Navbar',
					'Story Deletion'
				)
				if (
					user?.user?.subscriptionStatus?.status === 'trialing' ||
					user?.user?.subscriptionStatus?.status === 'active'
				) {
					window.location.reload()
				}
			} else {
				toastHandler('error', error?.message)
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${error.message}"}`,
					'Navbar',
					'Story Deletion'
				)
			}
		}
	}

	const activeButton = () => setActive(!isShow)

	const splitString = string => {
		let newStringArr = ''
		if (string[string?.length - 1] === ' ') {
			newStringArr = string
				.slice(0, -1)
				.split(' ')
				.map(word => word[0]?.toUpperCase())
				?.join('')
		} else {
			newStringArr = string
				.split(' ')
				.map(word => word[0]?.toUpperCase())
				?.join('')
		}
		return newStringArr
	}

	return (
		<>
			<Container data-public data-dd-privacy="allow" className="mystorycontainer" id="navBar">
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<HeaderLeftContainer>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							className="logo-container"
						>
							<LynitLogo
								src={lynit}
								alt="LynitIcon"
								width={72}
								height={30}
								onClick={() => history.push('/dashboard')}
							/>
						</Box>
						<BreadCrumb />
						{isLoggedIn && isDashboard && (
							<Box>
								{(isSaving || isExporting) && window.innerWidth < 500 && (
									<AutoSave isMobile={window.innerWidth < 500} />
								)}
							</Box>
						)}
					</HeaderLeftContainer>
					{isLoggedIn && !isDashboard && (
						<>
							<HelperDiv>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										{(isSaving || isExporting) && <AutoSave />}
									</div>
								</div>
							</HelperDiv>
						</>
					)}
				</div>
				{isLoggedIn && (
					<MenuContainer>
						{!isMobile && isLayout && <TaskBar />}
						<OutsideClickHandler onOutsideClick={() => setActive(false)}>
							<MenuContent>
								<ProfileContainer
									data-public
									data-dd-privacy="allow"
									onClick={() => {
										createLog(`Account Options Clicked`, `{}`, 'Navbar', null)
										activeButton()
										refetchCustomerPortal()
										if (!user?.user) {
											getUser()
										}
									}}
								>
									<NameButton>{splitString(user?.user?.name ?? '') || '--'}</NameButton>
									<p>{userNameLengthFormat(user?.user?.name)}</p>
									<img src={dropdownIcon} alt="User dropdown icon" width={12} height={8} />
								</ProfileContainer>

								{isShow && (
									<ProfileOptions>
										{!isDashboard && (
											<LogoutButton
												onClick={() => {
													history.push('/dashboard')
												}}
											>
												<div
													style={{
														width: '100%',
														display: 'flex',
														flexDirection: 'row',
														wordBreak: 'normal'
													}}
												>
													<img src={storyIcon} alt="Story icon" />
													<p>Back to My Stories</p>
												</div>
											</LogoutButton>
										)}
										{user?.user?.subscriptionStatus?.status === 'trialing' &&
											!user?.user?.subscriptionStatus?.subscriptionSchedule && (
												<UpgradeToPro
													message={
														<>
															Your trial period will expire <br />
															in <b>{user?.user?.subscriptionStatus?.daysLeft}.</b>
														</>
													}
												/>
											)}

										<LogoutButton
											onClick={() => {
												if (
													(user?.user?.subscriptionStatus?.status === 'trialing' &&
														!user?.user?.subscriptionStatus?.subscriptionSchedule) ||
													user?.user?.subscriptionStatus?.status === 'canceled' ||
													user?.user?.subscriptionStatus?.status === 'past_due'
												) {
													createLog('Go to plan picker', `{}`, 'Navbar', null)
													history.push('/plan-picker')
												} else {
													createLog('Open Customer Portal', `{}`, 'Navbar', null)
													window.location = urlCustomerPortal?.createCustomerPortalSession
												}
											}}
										>
											<div
												style={{
													width: '100%',
													display: 'flex',
													flexDirection: 'row',
													wordBreak: 'normal'
												}}
											>
												<img src={SettingsIcon} alt="Support button icon" />
												<p>Manage subscription</p>
											</div>
										</LogoutButton>
										<LogoutButton
											onClick={() => {
												createLog('Contact Support Clicked', `{}`, 'Navbar', null)
												window.Beacon('toggle')
												setActive(false)
											}}
										>
											<img src={SupportIcon} alt="Support button icon" />
											<p>Contact support</p>
										</LogoutButton>
										<LogoutButton
											onClick={async () => {
												createLog('User Logout', `{}`, 'Navbar', null)
												systemDispatch(logout())
												localStorage.clear()
												client.cache.reset()
												history.push('/login')
												userDataDispatch(setUser())
												document.body.setAttribute('style', 'background: white;')
											}}
										>
											<img src={LogoutIcon} alt="Logout icon" />
											<p>Logout</p>
										</LogoutButton>
										<LogoutButton
											onClick={() => {
												createLog(
													'User Deletion Attempted',
													`{"workflowStep":${1}}`,
													'Navbar',
													'User Deletion'
												)
												setIsDeleteUser(true)
											
											}}
											style={{ color: '#ff8080', }}
										>
											<img src={trashIcon} alt="Trash button icon" />
											<p>Delete Account</p>
										</LogoutButton>
									</ProfileOptions>
								)}
							</MenuContent>
						</OutsideClickHandler>
					</MenuContainer>
				)}
				{isLoggedIn && isDeleteOpen && (
					<DeleteModal
						elementType="story"
						nodeName={`${currentStory?.name} and its elements`}
						details="will be removed too"
						acceptHandler={async () => {
							createLog(
								'Story Deletion Confirmed',
								`{"workflowStep":${2}}`,
								'Navbar',
								'Story Deletion'
							)
							await storyDelete()
						}}
						closeHandler={() => {
							createLog(
								'Story Deletion Cancelled',
								`{"workflowStep":${2}}`,
								'Navbar',
								'Story Deletion'
							)
							dispatchAction(deleteCurrentStory(false))
						}}
						isShowed={isDeleteOpen}
					/>
				)}
				{isLoggedIn && isDeleteUser && (
					<DeleteModal
						elementType="user"
						nodeName={`All of your Account Data will be removed. `}
						details="This action is irreversible"
						acceptHandler={async () => {
							
							createLog(
								'User Deletion Confirmed',
								`{"workflowStep":${2}}`,
								'Navbar',
								'User Deletion'
							)
							setIsDeleteUser(false)
							await deleteUser()
							systemDispatch(logout())
							localStorage.clear()
							client.cache.reset()
							userDataDispatch(setUser())
							history.push('/signup')
							document.body.setAttribute('style', 'background: white;')
						}}
						closeHandler={() => {
							createLog(
								'User Deletion Cancelled',
								`{"workflowStep":${2}}`,
								'Navbar',
								'User Deletion'
							)
							setIsDeleteUser(false)
							
						}}
						isShowed={isDeleteUser}
					/>
				)}
			</Container>
			{isLoggedIn && isLayout && isMobile && <TaskBar />}
		</>
	)
})

export default withStyles(styles)(Navbar)
