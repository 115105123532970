/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { colorStyles } from '@lynit/shared/src/utils/commonStyles'
import diveDeepHover from '@lynit/shared/src/images/diveDeepHover.svg'

const ElementListContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	border-radius: 4px;
	overflow: hidden;
	visibility: ${({ isVisualizationExpanded, isExpandingView }) =>
		!isVisualizationExpanded || isExpandingView ? 'visible' : 'hidden'};
	@media (max-width: 600px) {
		visibility: ${({ isVisualizationExpanded, isExpandingView, isVisible }) =>
			(isVisible && !isVisualizationExpanded) || isExpandingView ? 'visible' : 'hidden'};
	}
`

const ElementContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #f3f3f6;
`

const CreateButton = styled.button`
	width: auto;
	height: auto;
	background-color: ${colorStyles.lynitYellow};
	border: none;
	border-radius: 5px;
	color: black;
	font-family: 'Source Sans Pro', sans-serif;
	align-self: center;
	box-shadow: 0 4px 4px -2px #c4c4c4;
	cursor: pointer;
	font-size: 9px;
	padding: 4px 6px;
	white-space: nowrap;
	img {
		margin-right: 4px;
	}
	&:hover {
		text-decoration: none;
		transform: translateY(-2px);
	}
	&:active {
		transform: translateY(0);
		transition: background 400ms;
	}
`

const Header = styled.div`
	height: 26px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #f8f8f8;
	align-items: center;
	padding-right: 10px;
	box-sizing: border-box;
	.chapter-view-title {
		height: 100%;
		font-size: 10px;
		font-weight: 600;
		color: #555555;
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.chapters-title {
		margin: 0;
		font-size: 12px;
		font-weight: bold;
		color: #000000;
	}
	.chapter-icon-container {
		background-color: #ffc300;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 30px;
		img {
			margin-top: 1px;
		}
	}
`

const BeatConnectionLimit = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: '5px',
	fontSize: '12px',
})

const Card = styled.div`
	box-sizing: border-box;
	height: 100%;
	position: relative;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: ${props => (props.isRightPanel ? '#EFEEF0' : '#E7E6E9')};
	border-radius: 4px;
	padding: 5px 0.7rem;
	user-select: none;
	width: ${props => (props.isRightPanel ? 'calc(100% - 20px)' : '210px')};
	margin: 0 auto;
	.beats-heading {
		font-size: 12px;
		font-weight: bold;
		margin: 5px 0;
	}
	@media (min-width: 1025px) {
		width: ${props => (props.isRightPanel ? 'calc(100% - 20px)' : '297px')};
	}

	// :hover {
	// 	border: ${props => (!props.isRightPanel ? '1px solid #595959' : '')};
	// 	box-shadow: 6px 1px 6px rgba(0, 0, 0, 0.07);

	// 	// .chapter-name > div {
	// 	// 	color: #224c96;
	// 	// }
	// }
`

const TextFieldsContainer = styled.div`
	display: flex;
	font-weight: bold;
	align-items: center;
	height: 30px;
	flex: 1;
	p {
		font-size: 12px;
	}
	.chapter-name-container {
		max-width: calc(100% - 20px);
		cursor: text;
	}
	.chapter-number-container {
		cursor:text;
	}
	:hover {
		color: ${props => props.hoverStyle && '#224c96'};
		cursor: ${props => props.hoverStyle && `url(${diveDeepHover}), auto`};
		text-decoration: ${props => props.hoverStyle && 'underline'};
	}
`

const Element = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 5px;
	align-items: center;
	overflow: hidden;
	min-height: 30px;
	margin-bottom: 5px;
	position: relative;
	.document-img {
		cursor: pointer;
		right: 2px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.chapter-name {
		max-width: calc(100% - 30px);
		@media screen and (max-width: 600px) {
			max-width: calc(100% - 15px);
		}
	}
`

const ChaptersContainer = styled.div`
	display: flex;
	gap: 10px;
	padding-top: 0.7rem;
	padding-right: 0.7rem;
	padding-bottom: 0rem;
	padding-left: 0.7rem;
	box-sizing: border-box;
	width: fit-content;
	height: 100%;
`

const ScrollingContainer = styled.div`
	height: 100%;
	width: 100%;
	overflow: auto;

	::-webkit-scrollbar {
		display: flex;
		height: 0.8em;
	}
	::-ms-overflow-style {
		scrollbar-width: 2px;
		scrollbar-height: 3px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid white; /* should match background, can't be transparent */
		background-color: rgba(0, 0, 0, 0.5);
	}
`
const ChapterDescription = styled.div`
	display: flex;
	flex-direction:column;
	justify-content: space-between;
	background-color:#f5f5f5;
	border-radius:4px;
	height: auto;
	.delete-icon {
		display:flex;
		justify-content:flex-end;
		opacity:0.7;
		img {
			padding:5px;
			cursor: pointer;
		}
	}
`

export {
	ElementContainer,
	ElementListContainer,
	CreateButton,
	Header,
	ChaptersContainer,
	TextFieldsContainer,
	Element,
	Card,
	BeatConnectionLimit,
	ScrollingContainer,
	ChapterDescription,
}
